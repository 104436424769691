<template>
  <div class="BarraBusqueda">
    <section class="seccion-superior">
      <b-form-input id="input-no-pedido" type="number" v-model="codigo" placeholder="Buscar No Pedido" />
      <BaseSelectCliente ref="BaseSelectCliente" v-if="soyAdmin" @select-cliente="value => cliente = value" />
      <span class="label-fechas"> Rango de fechas: </span>
      <b-form-input id="input-fecha-inicial" type="date" v-model="fechaInicial" />
      <b-form-input id="input-fecha-final" type="date" v-model="fechaFinal" />
      <BaseButton title="Excel" />
      <BaseButton title="Buscar" @onCta="consultarPedidos(form)" />
    </section>
    <section class="seccion-inferior">
      <multiselect style="width: 100%" v-model="proveedoresSelected" deselect-label="Este elemento no se puede eliminar"
        track-by="name" label="name" placeholder="Seleccione un proveedor." :multiple="true"
        :options="soyAdmin ? listaProveedoresSelectMulti : proveedores" :searchable="true" :allow-empty="true">
        <template slot="singleLabel" slot-scope="{ option }">
          <strong>{{ option.name }}</strong>
        </template>
      </multiselect>
      <!-- TODO filtrados por rango de fecha -->
      <BaseButton title="Hoy" @onCta="consultarPedidos({ ...form, rango: 'dia' })" />
      <BaseButton title="Semana" @onCta="consultarPedidos({ ...form, rango: 'semana' })" />
      <BaseButton title="Mes" @onCta="consultarPedidos({ ...form, rango: 'mes' })" />
      <BaseButton title="Ver Todos" @onCta="consultarTodos" />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import moment from "moment";
import { axiosInstance } from '../../../utils/axiosInstance';

export default {
  name: "BarraBusqueda",
  components: {
    Multiselect,
  },
  data() {
    return {
      proveedores: [],
      proveedoresSelected: [],
      codigo: '',
      cliente: '',
      fechaInicial: '',
      fechaFinal: '',
    };
  },
  mounted() {
    this.fechaInicial = moment().format('YYYY-MM-DD');
    this.fechaFinal = moment().format('YYYY-MM-DD');
    this.consultarPedidos(this.form);

    if (this.soyAdmin) {
      this.obtenerProveedores();
    }
    else {
      this.getProveedoresCliente();
    }
  },
  methods: {
    ...mapActions({ obtenerProveedores: "ObtenerProveedores" }),
    ...mapActions("pedidos", ["consultarPedidos", 'consultarTodosCliente']),
    async getProveedoresCliente() {
      try {
        const proveedores = await axiosInstance.get('proveedores/cliente', {
          params: {
            usuario_id: sessionStorage.getItem('usuario_id')
          },

        });

        this.proveedores = proveedores.data.map(p => ({ value: p.proveedor_id, name: p.proveedor.nombre }));
      } catch (error) {
        this.makeToast('Error', 'Hubo un error al leer los proveedores disponibles.', 'danger', 3000);
      }
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    async consultarTodos() {
      if (this.soyAdmin && !this.cliente) {
        this.makeToast('Consultar Todos', 'Debe seleccionar un cliente', 'warning', 3000)
        return
      }

      try {
        await this.consultarTodosCliente({ cliente: this.soyAdmin ? this.cliente : sessionStorage.getItem('usuario_id'), backorder: false })
      } catch (error) {
        this.makeToast('Consultar Todos', error.message, 'danger', 3000)
      }
    },
  },
  computed: {
    ...mapGetters(["listaProveedoresSelectMulti"]),
    ...mapGetters("pedidos", ["cargando"]),
    form() {
      return {
        cliente: this.soyAdmin ? this.cliente : sessionStorage.getItem('usuario_id'),
        codigo: this.codigo,
        proveedoresSeleccionados: this.proveedoresSelected,
        fechaInicial: this.fechaInicial,
        fechaFinal: this.fechaFinal,
        backorder: false,
      }
    },
    soyAdmin() {
      return sessionStorage.getItem('admin') === 'true'
    }
  },
};
</script>