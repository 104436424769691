<template>
  <div id="DetallesPedido">
    <template>
      <header>
        <div>
          <span>Numero de pedido:</span>
          <b-badge>{{ pedido.codigo }}</b-badge>
        </div>
        <div>
          <span>{{ pedido.creacion }}</span>
        </div>
      </header>
      <section>
        <b-overlay :show="loading">
          <b-table-simple small hover>
            <b-thead head-variant="light">
              <b-tr>
                <b-th class="text-center">Partida</b-th>
                <b-th class="text-center">Código</b-th>
                <b-th class="text-center">Descripción</b-th>
                <b-th class="text-center">Cantidad</b-th>
                <b-th class="text-center">Precio</b-th>
                <b-th class="text-center">Subtotal</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(producto, i) of pedido.pedido_productos" :key="i">
                <b-td class="text-center">{{ producto.partida }}</b-td>
                <b-td class="text-center">{{ producto.producto.codigo }}</b-td>
                <b-td>{{ producto.producto.descripcion }}</b-td>
                <b-td class="text-center">{{ producto.cantidad }}</b-td>
                <b-td>
                  <div class="d-flex justify-content-between">
                    <span>$</span>
                    {{ parseFloat(producto.precio).toFixed(2) }}
                  </div>
                </b-td>
                <b-td>
                  <div class="d-flex justify-content-between">
                    <span>$</span>
                    {{ parseFloat(producto.subtotal).toFixed(2) }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
        <div class="totales">
          <div class="total-row">
            <p>Subtotal:</p>
            <p>
              <span>$</span>
              {{ pedido.subtotal | currency }}
            </p>
          </div>
          <div class="total-row">
            <p>I.V.A.:</p>
            <p>
              <span>$</span>
              {{ pedido.iva | currency }}
            </p>
          </div>
          <div class="total-row">
            <p>I.E.P.S.:</p>
            <p>
              <span>$</span>
              {{ pedido.ieps | currency }}
            </p>
          </div>
          <div class="total-row">
            <p>Total:</p>
            <p>
              <span>$</span>
              {{ pedido.total | currency }}
            </p>
          </div>
        </div>
      </section>
      <footer>
        <div>
          <BaseButton @onCta="generarPdf" title="PDF" />
          <BaseButton @onCta="generarExcel" title="Excel" />
          <b-button style="background-color: #355070; margin-left: 2px;" @click="cancelarPedido" size="sm"
            :disabled="loading" v-if="!pedido.FacturaID && !pedido.cancelado">
            <b-spinner small v-if="loading"></b-spinner>
            Solicitar Cancelación
          </b-button>
          <BaseButton title="Aceptar" @onCta="$emit('cerrar')" />
        </div>
      </footer>
    </template>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import moment from "moment";
import writeXlsxFile from 'write-excel-file';
import Swal from 'sweetalert2';
import { axiosInstance } from '../../../utils/axiosInstance';

export default {
  name: "DetallesPedido",
  props: ['pedido'],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    generarPdf() {
      let doc = new jsPDF({ unit: "px", hotfixes: ["px_scaling"] });
      doc.setFontSize(10);
      let pdfPedidosSrc = document.getElementById("pdf-pedidos");
      pdfPedidosSrc.style.display = "block";
      const codigo = this.pedido.codigo;
      doc.html(document.getElementById("pdf-pedidos"), {
        callback: function (doc) {
          doc.save(`Pedido-${codigo}-${moment().format("DD/MM/YYYY")}.pdf`);
          pdfPedidosSrc.style.display = "none";
        },
        margin: [35,0,100,0],
        width: 1024,
        autoPaging: 'text',
      });
    },
    parseProductosLista() {
      return this.listaProductos.map((producto) => {
        return {
          codigo: producto.codigo,
          nombre: producto.descripcion,
          cantidad: producto.cantidad,
          precio: producto.precio_unitario,
          "precio con iva": producto.iva_total,
        };
      });
    },
    async generarExcel() {
      const data = [
        [
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: 'INFORMACIÓN DEL CLIENTE',
            fontWeight: 'bold',
            align: 'center'
          },
        ],
        [
          {
            value: 'NUMERO DE PEDIDO:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.codigo,
            type: Number,
            align: 'left'
          },
          {
            value: ''
          },
          {
            value: 'CONDICIONES DE VENTA:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.cliente_usuario.perfil_cliente_perfil_cliente.condiciones_venta,
            type: String
          },
        ],
        [
          {
            value: 'CLIENTE:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.cliente_usuario.razon_social,
            type: String
          },
          {
            value: ''
          },
          {
            value: 'PROVEEDOR:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.proveedor_proveedore.nombre,
            type: String
          },
        ],
        [
          {
            value: 'DOMICILIO:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.cliente_usuario.domicilio,
            type: String
          },
          {
            value: ''
          },
          {
            value: 'FECHA:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.creacion,
            type: String
          },
        ],
        [
          {
            value: 'CIUDAD:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.cliente_usuario.perfil_cliente_perfil_cliente.ciudad,
            type: String
          },
          {
            value: ''
          },
          {
            value: 'VIGENCIA:',
            fontWeight: 'bold'
          },
          {
            value: '8 días',
            type: String
          },
        ],
        [
          {
            value: 'RFC:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.cliente_usuario.rfc,
            type: String
          },
          {
            value: ''
          },
          {
            value: 'TRANSPORTE:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.transporte.transporte_transporte.nombre,
            type: String
          },
        ],
        [
          {
            value: 'TELEFONO:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.cliente_usuario.tel_1 || this.pedido.cliente_usuario.tel_2 || '',
            type: String
          },
          {
            value: ''
          },
          {
            value: 'CONVENIO:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.transporte.convenio,
            type: String
          },
        ],
        [
          {
            value: 'CÓDIGO POSTAL:',
            fontWeight: 'bold'
          },
          {
            value: this.pedido.cliente_usuario.perfil_cliente_perfil_cliente.codigo_postal,
            type: String
          },
        ],
        [],
        [
          {},
          {},
          {
            value: 'PRODUCTOS PEDIDOS',
            fontWeight: 'bold',
            align: 'center'
          }
        ],
        [
          {
            value: 'Partida',
            fontWeight: 'bold',
            align: 'center'
          },
          {
            value: 'Código',
            fontWeight: 'bold',
            align: 'center'
          },
          {
            value: 'Descripción',
            fontWeight: 'bold',
            align: 'center'
          },
          {
            value: 'Cantidad',
            fontWeight: 'bold',
            align: 'center'
          },
          {
            value: 'Precio',
            fontWeight: 'bold',
            align: 'center'
          },
          {
            value: 'Subtotal',
            fontWeight: 'bold',
            align: 'center'
          }
        ]
      ]

      for (const producto of this.pedido.pedido_productos) {
        data.push([
          {
            value: producto.partida,
            type: Number,
            align: 'center'
          },
          {
            value: producto.producto.codigo,
            type: String,
            align: 'center'
          },
          {
            value: producto.producto.descripcion,
            type: String
          },
          {
            value: producto.cantidad,
            type: Number,
            align: 'center'
          },
          {
            value: producto.precio,
            type: Number,
            align: 'right'
          },
          {
            value: producto.subtotal,
            type: Number,
            align: 'right'
          },
        ])
      }

      data.push([])
      data.push([
        {},
        {},
        {},
        {
          value: 'Venta Grabada:',
          type: String,
          fontWeight: 'bold'
        },
        {
          value: this.pedido.total,
          type: Number
        },
      ])
      data.push([
        {},
        {},
        {},
        {
          value: 'Venta Exenta:',
          type: String,
          fontWeight: 'bold'
        },
        {
          value: this.pedido.ieps,
          type: Number
        },
      ])
      data.push([
        {},
        {},
        {},
        {
          value: 'Subtotal:',
          type: String,
          fontWeight: 'bold'
        },
        {
          value: this.pedido.subtotal,
          type: Number
        },
      ])
      data.push([
        {},
        {},
        {},
        {
          value: 'I.V.A.:',
          type: String,
          fontWeight: 'bold'
        },
        {
          value: this.pedido.iva,
          type: Number
        },
      ])
      data.push([
        {},
        {},
        {},
        {
          value: 'I.E.P.S.:',
          type: String,
          fontWeight: 'bold'
        },
        {
          value: this.pedido.ieps,
          type: Number
        },
      ])
      data.push([
        {},
        {},
        {},
        {
          value: 'Total:',
          type: String,
          fontWeight: 'bold'
        },
        {
          value: this.pedido.total,
          type: Number
        },
      ])
      data.push([
        {
          value: 'Observaciones:',
          fontWeight: 'bold'
        },
        {
          value: this.pedido.comentarios,
          type: String
        },
      ])

      try {
        await writeXlsxFile(data, { fileName: `Pedido-${this.pedido.codigo}-${moment().format("DD/MM/YYYY")}.xlsx` })
      } catch (error) {
        Swal.fire({ titleText: 'Error', text: 'Hubo un error al exportar a Excel', icon: 'error' });
      }
    },
    async cancelarPedido() {
      const result = await Swal.fire({
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showConfirmButton: true,
        showCancelButton: true,
        showCloseButton: false,
        showDenyButton: false,
        confirmButtonColor: '#355070',
        text: '¿Está seguro de que desea solicitar la cancelación del pedido?',
      });

      if (!result.isConfirmed) {
        return;
      }

      const { value: Comentarios } = await Swal.fire({
        title: "Comente su razón para la cancelación, para tener más oportunidad de ser aceptado.",
        input: "textarea",
        inputPlaceholder: 'Comentarios...',
        inputAutoFocus: true,
        showCancelButton: false,
        confirmButtonColor: '#355070'
      });

      this.loading = true;
      try {
        await axiosInstance.post(`pedidos/cancelacion/${this.pedido.id}`,
          {
            Comentarios
          },
          {
            headers: {
              authorization: localStorage.getItem('token')
            }
          }
        );

        this.makeToast('Cancelación de pedido', 'Cancelación solicitada', 'success', 3000);
      } catch (error) {
        this.makeToast('Cancelación de pedido', error.response.data.msg || 'Hubo un error en el servidor', 'danger', 3000);
      } finally {
        this.loading = false;
      }
    },
  },
  filters: {
    currency(value) {
      return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  emits: ['cerrar']
};
</script>