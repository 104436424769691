<template>
  <div id="BaseSelect">
    <multiselect
      style="width: 100%"
      v-model="selected"
      deselect-label="Can't remove this value"
      track-by="name"
      label="name"
      :placeholder="placeholder"
      :multiple="multiple"
      :options="items"
      :searchable="searchable"
      :allow-empty="allowEmpty"
    >
      <template slot="singleLabel" slot-scope="{ option }">
        <strong>{{ option.name }}</strong>
      </template>
      <span slot="noResult">{{ sinResultados }}</span>
    </multiselect>
  </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "BaseSelect",
  props: {
    defaultProp: {
      type: String,
      default: "Default Prop",
    },
    items: {
      type: Array,
      default: [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "SELECT COMPONENT",
    },
    sinResultados: {
      type: String,
      default: "Sin resultados...",
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      selected: [],
    };
  },
  mounted() {},
  destroyed() {},
  methods: {},
  computed: {},
  watch: {
    selected() {
      this.$emit("selected", this.selected);
    },
  },
};
</script>
<style lang="scss">
</style>