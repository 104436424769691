<template>
  <div>
    <b-navbar id="navbar-container">
      <b-navbar-brand>
        <!-- <b-icon id="menu-button" @click="clickMenu" icon="justify"></b-icon> -->
        <img
          src="https://jprepresentaciones.com.mx/logo.png"
          alt="logo"
          id="logo"
        />
      </b-navbar-brand>
    </b-navbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["menuAbierto"]),
  },
  methods: {
    ...mapActions({
      CambiarEstadoMenu: "CambiarEstadoMenu",
    }),
    clickMenu() {
      this.CambiarEstadoMenu();
    },
  },
};
</script>
<style>
#navbar-container {
  background-color: #355070;
}
#logo {
  margin-right: 10px;
  max-height: 30px;
}
#menu-button {
  margin-right: 30px;
  margin-left: 10px;
  font-size: 30px;
  color: white;
}
</style>
