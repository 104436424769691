<template>
  <!-- CABECERA PARA REGERESAR Y TITULO -->
  <div>
    <b-modal hide-footer hide-header v-model="mostrarModalAggRol">
      <div>
        <h3>Agregar Rol</h3>
        <b-form>
          <b-form-group id="input-group-rol" label="Nombre del rol">
            <b-form-input
              id="input-rol"
              type="text"
              v-model="nuevoRol"
            ></b-form-input>
          </b-form-group>
          <div class="footer-botones-modal">
            <b-button
              id="btn-agregar"
              :disabled="nuevoRol.length === 0"
              @click="guardarRol"
              >AGREGAR</b-button
            >
            <b-button id="btn-cancelar" @click="cerrarModalAggRol"
              >CANCELAR</b-button
            >
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal hide-footer hide-header v-model="mostrarModalAsgRol">
      <div>
        <h4>{{ this.usuario.nombre }}</h4>
        <b-form-group
          id="input-group-3"
          label="Agregar Rol:"
          label-for="input-3"
        >
          <b-form-select
            id="input-3"
            v-model="rol"
            :options="dropdownRoles"
          ></b-form-select>
        </b-form-group>
        <b-button
          :disabled="rol.length === 0 || usuario.usuario_id.length === 0"
          id="btn-agregar"
          block
          @click="asignarRol"
          >ASIGNAR</b-button
        >
        <h5 style="margin-top: 10px; margin-bottom: 10px">Roles asignados:</h5>
        <b-table
          table-class="tabla-roles-personal"
          thead-class="head"
          :items="rolesPorPersonal"
          :show-empty="rolesPorPersonal.length == 0"
          empty-text="SIN ROLES ASIGNADOS"
          bordered
        ></b-table>
      </div>
    </b-modal>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Roles</h2>
        </div></b-col
      >
    </b-row>
    <b-row>
      <b-col>
        <div style="padding-left: 10px">
          <b-button id="btn-agregar" @click="abrirModalAggRol"
            >AGREGAR</b-button
          >
        </div>
      </b-col>
      <b-col style="padding-left: 40px"
        ><p style="font-size: 20px">Asignar Roles</p></b-col
      >
    </b-row>
    <b-row>
      <b-col>
        <div class="roles-container">
          <b-table
            table-class="tabla-roles"
            :items="listaRoles"
            :fields="cabeceraTablaRoles"
            bordered
            head-variant="light"
          ></b-table>
        </div>
      </b-col>
      <b-col>
        <div class="roles-container">
          <b-table
            table-class="tabla-roles"
            :items="listaPersonal"
            :fields="cabaceraTablaPersonal"
            bordered
            selectable
            select-mode="single"
            @row-selected="usuarioSeleccionado"
            head-variant="light"
          ></b-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "../../utils/api";

export default {
  data() {
    return {
      listaRoles: [],
      mostrarModalAggRol: false,
      mostrarModalAsgRol: false,
      usuario: "",
      rol: "",
      cabeceraTablaRoles: [
        {
          key: "nombre",
          sortable: true,
          label: "Roles",
        },
      ],
      cabaceraTablaPersonal: [
        {
          key: "nombre",
          sortable: true,
          label: "Personal",
        },
      ],
      nuevoRol: "",
      listaPersonal: [],
      dropdownRoles: [],
      rolesPorPersonal: [],
    };
  },
  mounted() {
    this.listarRoles();
    this.listarPersonal();
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarRoles() {
      api("GET", "roles/consultar-roles").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener los Roles",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          this.listaRoles = result.usuarios;
          this.dropdownRoles = this.listaRoles.map((rol) => {
            return { text: rol.nombre, value: rol.rol_id };
          });
        }
      });
    },
    listarPersonal() {
      api("GET", "usuarios/consultar-todos").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener los usuarios",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          this.listaPersonal = result.usuarios.map((personal) => {
            return {
              usuario_id: personal.usuario_id,
              nombre: `${personal.nombre} ${personal.apellido}`,
            };
          });
        }
      });
    },
    listarRolesPorPersonal(id) {
      api("GET", `rol-usuario/consultar-por-usuario/${id}`).then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al consultar roles",
            text: "Error de peticion",
            type: "error",
          });
        } else {
          this.rolesPorPersonal = result.roles;
        }
      });
    },
    usuarioSeleccionado(item) {
      this.usuario = item[0];
      this.listarRolesPorPersonal(this.usuario.usuario_id);
      this.abrirModalAsgRol();
    },
    abrirModalAggRol() {
      this.mostrarModalAggRol = true;
    },
    cerrarModalAggRol() {
      this.mostrarModalAggRol = false;
      this.nuevoRol = "";
    },
    abrirModalAsgRol() {
      this.mostrarModalAsgRol = true;
    },
    cerrarModalAsgRol() {
      this.mostrarModalAsgRol = false;
    },
    guardarRol() {
      if (this.nuevoRol !== 0) {
        api("POST", "roles/crear", { nombre: this.nuevoRol }).then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al guardar",
              text: "Error de peticion",
              type: "error",
            });
          } else {
            this.$notify({
              group: "foo",
              title: "Usuario guardado",
              text: "Alta exitosa",
              type: "success",
            });
            this.listarRoles();
            this.cerrarModalAggRol();
          }
        });
      } else {
        this.$notify({
          group: "foo",
          title: "Ingrese el nombre del ROL",
          text: "Error de datos",
          type: "error",
        });
      }
    },
    asignarRol() {
      let datos = { rol_id: this.rol, usuario_id: this.usuario.usuario_id };
      api("POST", "rol-usuario/asignar-rol", datos).then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al asignar el ropl",
            text: "Error de peticion",
            type: "error",
          });
        } else {
          this.$notify({
            group: "foo",
            title: "ROL ASIGNADO",
            text: "Alta exitosa",
            type: "success",
          });
          this.listarRolesPorPersonal(datos.usuario_id);
        }
      });
    },
  },
};
</script>
<style>
.roles-container {
  padding: 10px;
}
.tabla-roles {
  max-height: 100%;
}
#btn-agregar {
  background-color: #355070;
}
#btn-cancelar {
  background-color: #a5594e;
  border-color: #a5594e;
}
.footer-botones-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.head {
  display: none;
}
.tabla-roles-personal {
  margin-top: 10;
}
h5 {
  margin-top: 10px;
}
</style>
