<template>
  <div
    id="BackBar"
  >
    <b-icon
      class="back_icon"
      icon="chevron-left"
      @click="regresar"
    ></b-icon>
    <h2 style="margin-left: 10px">{{ titulo }}</h2>
  </div>
</template>

<script>
export default {
  name: "BackBar",
  props: ["titulo", "goTo"],
  components: {},
  data() {
    return {};
  },
  methods: {
    regresar() {
      this.$router.push(this.goTo);
    },
  },
};
</script>
<style lang="scss">
#BackBar {
  display: flex;
  flex-direction: row;
  align-items: center ;
  width: 100%;
  > .back_icon {
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>