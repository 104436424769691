<template>
  <div>
    <notifications group="foo" />
    <router-view></router-view>
  </div>
</template>

<script>
import {config} from 'dotenv'
export default {
  name: "App",
  mounted() {
    config()
  }
};
</script>