<template>
  <b-container>
    <h3>Asignar Transportes</h3>
    <h3>Proveedor: {{ this.proveedor.nombre }}</h3>
    <b-container>
      <b-row>
        <b-col>
          <div class="select-transporte">
            <b-form-select
              id="select-transportes"
              v-model="transporteSelected"
              :options="listaTransportes"
            />
            <b-button id="btn-agregar-proveedor" @click="asignarTransporte"
              >Asignar</b-button
            >
          </div>
          <div class="section-cobro">
            <h3>
              Transporte seleccionado:
              {{ this.transporteSelected.nombre || "Seleccione un transporte" }}
            </h3>
            <div class="inputs">
              <b-form-checkbox v-model="checked" name="check-button" switch>
                Cobro Envio
              </b-form-checkbox>
              <b-button id="btn-agregar-proveedor">Guardar</b-button>
            </div>
          </div>
          <b-table
            id="tabla-Transportes-asignados"
            empty-text="SIN Transportes ASIGNADOS"
            :items="listaTransportesAsignados"
            :busy="isBusyTransportesAsignados"
            :fields="camaposTransportesAsignados"
            outlined
            :show-empty="listaTransportesAsignados.length == 0"
            selectable
            select-mode="single"
            @row-selected="onTransporteRowSelected"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Cargando...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import moment from "moment";
import api from "../../utils/api";

export default {
  data() {
    return {
      proveedor_id: null,
      proveedor: {},
      listaTransportes: [],
      transporteSelected: {},
      listaTransportesAsignados: [],
      isBusyTransportesAsignados: true,
      camaposTransportesAsignados: [
        {
          key: "nombre",
          label: "Transportes Asignados",
          sortable: true,
        },
      ],
      checked: false,
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    async consultarDetallesProveedor() {
      await api(
        "GET",
        `proveedores/consultar-proveedor/${this.proveedor_id}`
      ).then((result) => {
        this.proveedor = { ...result };
      });
    },
    async asignarTransporte() {
      let params = {
        proveedor_id: this.proveedor_id,
        transporte_id: this.transporteSelected,
        cobro: false,
      };
      await api("POST", "proveedores/asignar-transporte", params)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al asignar el transporte",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$notify({
              group: "foo",
              title: "Transporte asignado",
              text: "Alta exitosa",
              type: "success",
            });
            this.listarTransportesAsignados();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async listarTransportesAsignados() {
      await api(
        "GET",
        `proveedores/consultar-transporte-proveedor/${this.proveedor_id}`
      )
        .then((result) => {
          this.listaTransportesAsignados = [...result];
          this.isBusyTransportesAsignados = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async listarTransportes() {
      await api("GET", `transporte/consultar-transportes`).then((result) => {
        this.listaTransportes = result.transportes.map((transporte) => {
          return { value: transporte.transporte_id, text: transporte.nombre };
        });
      });
    },
    async comprobarParametros() {
      this.proveedor_id = this.$route.params.proveedor_id;
      await this.consultarDetallesProveedor();
    },
    onTransporteRowSelected(item) {
      this.transporteSelected = item[0];
    },
  },
  computed: {},
  async mounted() {
    await this.comprobarParametros();
    await this.listarTransportes();
    await this.listarTransportesAsignados();
  },
};
</script>

<style>
.select-transporte {
  display: flex;
  flex-direction: row;
}
select {
  margin-right: 10px;
}
table {
  margin-top: 20px;
}
.section-cobro {
  margin-top: 10px;
}
.section-cobro h3 {
  margin-left: 0px;
}
.inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
