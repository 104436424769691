<template>
  <div>
    <b-modal
      id="modal-agregar"
      ref="modal-agregar"
      size="m"
      title="Agregar Metodo de Pago"
      scrollable
      hide-footer
    >
      <div class="agregar_cliente_container">
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="nombre">Metodo: </label>
              <b-form-input
                id="nombre"
                v-model="metodoPagoNuevo.metodo"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top: 20px">
          <b-col>
            <b-button
              style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              "
              block
              @click="guardarMetodoPago"
              >GUARDAR</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      id="modal-modificar"
      ref="modal-modificar"
      size="m"
      title="Modificar metodo de pago"
      scrollable
      hide-footer
    >
      <div class="agregar_cliente_container">
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="metodo">Metodo: </label>
              <b-form-input
                id="metodo"
                v-model="selected.metodo"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              block
              style="background-color: #355070; margin-top: 10px"
              @click="modificarMetodoPago"
              >Guardar</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-right"
      ref="sidebar-right"
      backdrop
      no-header
      title="Detalles"
      right
      bg-variant="white"
      shadow
    >
      <div class="detalles_header">
        <b-icon id="x-icon" v-b-toggle.sidebar-right icon="x"></b-icon>
        <h3>Detalles metodo de pago</h3>
      </div>
      <div class="detalles_body">
        <b-row>
          <b-col>
            <h4 class="fill">
              {{ this.selected.metodo }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="dato_item">
              <p class="txt_dato">ID:</p>
              <p class="txt_dato_resp">{{ this.selected.metodo_pago_id }}</p>
            </div>
            <div class="dato_item">
              <p class="txt_dato">Metodo:</p>
              <p class="txt_dato_resp">{{ this.selected.metodo }}</p>
            </div>
            <b-button
              block
              style="background-color: #355070; margin-top: 10px"
              v-b-toggle.sidebar-right
              v-b-modal.modal-modificar
              >MODIFICAR</b-button
            >
            <b-button
              block
              style="background-color: #a5594e; border-color: #a5594e"
              @click="suspenderTransportes"
              >SUSPENDER</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Metodos de Pago</h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-right: 10px;
            width: 100%;
          "
        >
          <b-input-group style="width: 450px; padding-left: 10px">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            ></b-form-input>
          </b-input-group>
          <div>
            <b-button
              style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              "
              @click="abrirModal"
            >
              <b-icon icon="plus" style="margin-right: 4px" />AGREGAR</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container class="table_container" fluid>
          <b-table
            :items="items"
            :fields="fields"
            striped
            bordered
            hover
            head-variant="light"
            :per-page="perPage"
            :busy="isBusy"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            v-b-toggle.sidebar-right
          >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            :current-page="currentPage"
          ></b-pagination>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import api from "../../utils/api";

export default {
  data() {
    return {
      fields: [
        {
          key: "metodo_pago_id",
          sortable: true,
          label: "ID",
        },
        {
          key: "metodo",
          sortable: true,
          label: "Metodo",
        },
      ],
      currentPage: 1,
      perPage: 10,
      items: [],
      itemsAux: [],
      isBusy: false,
      filter: null,
      filterOn: [],
      selected: {},
      itemsRoles: [],
      itemsSesiones: [],
      isBusyRoles: false,
      nuevoUsuario: {},
      metodoPagoNuevo: {},
      clienteModificado: {},
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarMetodosPago() {
      this.isBusy = !this.isBusy;
      api("GET", "metodo-pago/consultar-metodos").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener los metodos de pago",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          //   result.usuarios.map((usuario) => {
          //     usuario.created_at = moment(usuario.created_at).format("LLL");
          //   });
          this.items = result.metodosPago;
          this.itemsAux = result.metodosPago;
          this.isBusy = !this.isBusy;
        }
      });
    },
    onRowSelected(item) {
      this.selected = item[0];
    },
    parseDate(date) {
      return moment(date || "00").format("DD-MM-YYYY");
    },
    suspenderTransportes() {
      this.isBusy = !this.isBusy;
      api("DELETE", `transporte/suspender/${this.selected.transporte_id}`)
        .then(async (result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al suspender el transporte",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["sidebar-right"].hide();
            await this.listarMetodosPago();
            this.isBusy = !this.isBusy;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    abrirModal() {
      this.$refs["modal-agregar"].show();
    },
    cerrarModal() {
      this.$refs["modal-agregar"].hide();
    },
    abrirModalPreciosProveedores() {
      this.$refs["modal-precios-proveedores"].show();
    },
    cerrarModalPreciosProveedores() {
      this.$refs["modal-precios-proveedores"].hide();
    },
    guardarMetodoPago() {
      api("POST", "metodo-pago/agregar", this.metodoPagoNuevo)
        .then(async (result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al agregar el metodo de pago",
              text: "Error del servidor",
              type: "error",
            });
            this.listarMetodosPago();
          } else {
            this.listarMetodosPago();
            this.isBusy != this.isBusy;
            this.$refs["modal-agregar"].hide();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    modificarMetodoPago() {
      api(
        "PUT",
        `metodo-pago/modificar/${this.selected.metodo_pago_id}`,
        this.selected
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar el metodo de pago",
              text: "Error del servidor",
              type: "error",
            });
            this.listarMetodosPago();
          } else {
            this.$refs["modal-modificar"].hide();
            this.listarMetodosPago();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.listarMetodosPago();
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}
#sidebar-right {
  width: 450px;
}
.fill {
  color: #aaa;
}
.cabecera_roles {
  display: none;
}
.table_container {
  margin-top: 20px;
}
.table_container tr:hover {
  cursor: pointer;
}
#tabla_roles {
  margin-top: 10px;
}
.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}
.detalles_body {
  margin: 10px;
}
.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}
.txt_dato {
  font-weight: bold;
}
.txt_dato_resp {
  color: #424242;
}
.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
