import Vue from "vue";

export default function Notification(tipo, title, msj, duration = 3000) {
  Vue.notify({
    group: "foo",
    title: title,
    text: msj,
    type: tipo,
    duration: duration,
  });
}
