import Vue from "vue";
import Vuex from "vuex";
import api from "../utils/api";

import productosCliente from "./productos-cliente";
import clientes from "./clientes";
import pedidos from "./pedidos";
import cotizaciones from "./cotizaciones";
import facturas from "./facturas";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    productosCliente,
    clientes,
    pedidos,
    cotizaciones,
    facturas,
  },
  state: {
    currentProducto: null,
    menuAbierto: false,
    menuDetallesAbierto: false,
    listaProveedores: [],
    listaMarcas: [],
    listaProveedoresSelect: [],
    listaProveedoresSelectMulti: [],
    listaMarcasSelect: [],
    listaProveedoresPrecios: [],
    loading: false,
  },
  getters: {
    currentProducto: (state) => state.currentProducto,
    menuAbierto: (state) => state.menuAbierto,
    menuDetallesAbierto: (state) => state.menuDetallesAbierto,
    listaProveedores: (state) => state.listaProveedores,
    listaProveedoresSelect: (state) => state.listaProveedoresSelect,
    listaProveedoresSelectMulti: (state) => state.listaProveedoresSelectMulti,
    listaMarcas: (state) => state.listaMarcas,
    listaMarcasSelect: (state) => state.listaMarcasSelect,
    listaProveedoresPrecios: (state) => state.listaProveedoresPrecios,
    loading: (state) => state.loading,
  },
  mutations: {
    SET_CURRENT_PRODUCTO(state, newValue) {
      state.currentProducto = newValue;
    },
    SET_ABRIR_MENU(state, newValue) {
      state.menuAbierto = newValue;
    },
    SET_ABRIR_MENU_DETALLES(state, newValue) {
      state.menuDetallesAbierto = newValue;
    },
    SET_LISTAR_PROVEEDORES(state, newValue) {
      state.listaProveedores = newValue;
    },
    SET_LISTAR_PROVEEDORES_SELECT(state, newValue) {
      state.listaProveedoresSelect = newValue;
    },
    SET_LISTAR_PROVEEDORES_SELECT_MULTI(state, newValue) {
      state.listaProveedoresSelectMulti = newValue;
    },
    SET_LISTAR_MARCAS(state, newValue) {
      state.listaMarcas = newValue;
    },
    SET_LISTAR_MARCAS_SELECT(state, newValue) {
      state.listaMarcasSelect = newValue;
    },
    SET_LISTAR_PRECIOS_PROVEEDORES(state, newValue) {
      state.listaProveedoresPrecios = newValue;
    },
    SET_LOADING(state, newValue) {
      state.loading = newValue;
    }
  },
  actions: {
    SelectProducto({ commit, getters }) {
      commit("SET_CURRENT_PRODUCTO", getters.currentProducto);
    },
    CambiarEstadoMenu({ commit, getters }) {
      commit("SET_ABRIR_MENU", !getters.menuAbierto);
    },
    CambiarEstadoMenuDetalles({ commit, getters }) {
      commit("SET_ABRIR_MENU_DETALLES", !getters.menuDetallesAbierto);
    },
    AbrirEstadoMenuDetalles({ commit }) {
      commit("SET_ABRIR_MENU_DETALLES", true);
    },
    CerrarEstadoMenuDetalles({ commit }) {
      commit("SET_ABRIR_MENU_DETALLES", false);
    },
    ObtenerProveedores({ commit }, payload = "") {
      if (!payload) {
        commit('SET_LOADING', true);
        api("GET", "proveedores/obtener-proveedores")
          .then((result) => {
            if (result.proveedores) {
              commit("SET_LISTAR_PROVEEDORES", result.proveedores);
              commit("SET_LISTAR_PROVEEDORES_SELECT", [
                { value: null, text: "Seleccione un proveedor" },
                ...result.proveedores.map((res) => {
                  return {
                    value: res.proveedor_id || null,
                    text: res.nombre || "",
                  };
                }),
              ]);
              commit("SET_LISTAR_PROVEEDORES_SELECT_MULTI", [
                { value: 0, name: "Todos los proveedores" },
                ...result.proveedores.map((res) => {
                  return {
                    value: res.proveedor_id || null,
                    name: res.nombre || "",
                  };
                }),
              ]);
            }
          })
          .catch(() => {
            commit("SET_LISTAR_PROVEEDORES", []);
            commit("SET_LISTAR_PROVEEDORES_SELECT", []);
          })
          .finally(() => commit('SET_LOADING', false));
      }
    },
    ObtenerProveedoresPrecio({ commit }, payload) {
      commit('SET_LOADING', true);
      api("GET", `cliente-proveedor/consultar-precios/${payload}`)
        .then((result) => {
          if (result.preciosProveedores) {
            commit("SET_LISTAR_PROVEEDORES_SELECT_MULTI", [
              ...result.preciosProveedores.map((res) => {
                return {
                  value: res.proveedor_id || null,
                  name: res.nombre_proveedor || "",
                  precio: res.precio,
                };
              }),
            ]);
          }
        })
        .catch(() => {
          commit("SET_LISTAR_PROVEEDORES", []);
          commit("SET_LISTAR_PROVEEDORES_SELECT", [
            {
              value: null,
              name: "",
              precio: null,
            },
          ]);
        })
        .finally(() => commit('SET_LOADING', false));
    },
    ObtenerMarcas({ commit }) {
      commit('SET_LOADING', true);
      api("GET", "productos/obtener-marcas")
        .then((result) => {
          if (result.marcas) {
            commit("SET_LISTAR_MARCAS", result.marcas);
            commit("SET_LISTAR_MARCAS_SELECT", [
              { value: null, text: "Seleccione una marca" },
              ...result.marcas.map((res) => {
                return {
                  value: res.marca_id || null,
                  text: res.nombre || "",
                };
              }),
            ]);
          }
        })
        .catch(() => {
          commit("SET_LISTAR_MARCAS", []);
          commit("SET_LISTAR_MARCAS_SELECT", []);
        })
        .finally(() => commit('SET_LOADING', false));
    },
  },
});
