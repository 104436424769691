require('dotenv').config();
const axios = require('axios');

const baseURL = process.env.VUE_APP_JPR_API;
//const baseURL = 'http://localhost:8000/api/';

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(request => {
  request.headers.authorization = localStorage.getItem('token')
  return request
})

export { axiosInstance }