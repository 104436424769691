import moment from "moment";
import api from "../utils/api";
import { axiosInstance } from "../utils/axiosInstance";

const cotizaciones = {
  namespaced: true,
  state: {
    cargando: true,
    cargandoDetalles: true,
    bloqueado: false,
    listaCotizaciones: [],
    cotizacion: {},
    queryCotizaciones: {},
  },
  getters: {
    cargando: (state) => state.cargando,
    cargandoDetalles: (state) => state.cargandoDetalles,
    bloqueado: (state) => state.bloqueado,
    listaCotizaciones: (state) => state.listaCotizaciones,
    cotizacion: (state) => state.cotizaciones,
    queryCotizaciones: (state) => state.queryCotizaciones,
  },
  mutations: {
    SET_CARGANDO(state, newValue) {
      state.cargando = newValue;
    },
    SET_CARGANDO_DETALLES(state, newValue) {
      state.cargandoDetalles = newValue;
    },
    SET_BLOQUEADO(state, newValue) {
      state.bloqueado = newValue;
    },
    SET_LISTA_COTIZACIONES(state, newValue) {
      state.listaCotizaciones = newValue;
    },
    SET_COTIZACION(state, newValue) {
      state.cotizacion = newValue;
    },
  },
  actions: {
    async consultarCotizaciones({ commit }, payload) {
      commit("SET_CARGANDO", true);
      if (payload.rango) {
        const rangosFecha = {
          dia: 1,
          semana: 6,
          mes: 30,
        };
        let d = new Date();
        d.setDate(d.getDate() - rangosFecha[payload.rango]);
        payload.fechaInicial = moment(d).format("YYYY-MM-DD");
        payload.fechaFinal = moment().format('YYYY-MM-DD')
      }
      if (payload.codigo === "") payload.codigo === null;
      payload = {
        ...payload,
        fechaInicial: moment(payload.fechaInicial).format("YYYY-MM-DD 00:00:00"),
        fechaFinal: moment(payload.fechaFinal).format("YYYY-MM-DD 23:59:59"),
        proveedores:
          payload.proveedoresSeleccionados.length === 0
            ? []
            : payload.proveedoresSeleccionados.map(({ value }) => value),
      };
      api("POST", "cotizaciones/consultar", payload)
        .then((result) => {
          if (result.errorCode) {
            commit("SET_LISTA_COTIZACIONES", []);
            commit("SET_CARGANDO", false);
            return;
          }

          commit(
            "SET_LISTA_COTIZACIONES",
            result.cotizaciones.map((pedido) => {
              return {
                ...pedido,
                fecha: moment(pedido.fecha.split('T')[0]).format("DD/MM/YYYY"),
              };
            })
          );
          commit("SET_CARGANDO", false);
        })
        .catch(() => {
          commit("SET_LISTA_COTIZACIONES", []);
          commit("SET_CARGANDO", false);
        });
    },
    async consultarCotizacion({ commit }, payload) {
      commit("SET_CARGANDO_DETALLES", true);
      try {
        const result = await api("GET", `cotizaciones/consultar-detalle/${payload.cotizacion_id}`);
        commit("SET_COTIZACION", {
          ...result.detallesCotizacion[0],
          creacion: moment(result.detallesCotizacion[0].fecha.split('T')[0]).format('DD/MM/YYYY'),
          productos: result.productos.map(producto => (
            {
              partida: producto.partida,
              codigo: producto.codigo,
              descripcion: producto.descripcion,
              cantidad: producto.cantidad,
              iva: producto.iva,
              precio: producto.precio_unitario,
              subtotal: producto.cantidad * producto.precio_unitario,
              total: producto.precio_total,
            }))
        });
      } catch (error) {
        commit("SET_COTIZACION", { ...payload, productos: [] });
        throw error;
      } finally {
        commit("SET_CARGANDO_DETALLES", false);
      }
    },
    async consultarTodosCliente({ commit }, cliente) {
      commit("SET_CARGANDO", true);

      if (!cliente) {
        throw new Error('Debe seleccionar un cliente')
      }

      try {
        const result = await axiosInstance.post('cotizaciones/consultar', { cliente, todos: true })

        commit(
          "SET_LISTA_COTIZACIONES",
          result.data.cotizaciones.map((pedido) => {
            return {
              ...pedido,
              fecha: moment(pedido.fecha.split('T')[0]).format("DD/MM/YYYY"),
            };
          })
        );
      } catch (error) {
        throw new Error(error.response.data.msg || 'Hubo un error al consultar las cotizaciones del cliente')
      } finally {
        commit('SET_CARGANDO', false);
      }
    }
  },
};

export default cotizaciones;
