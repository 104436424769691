<template>
  <div id="Totales">
    <div class="total-row" v-for="element in totales" :key="element.title">
      <b-badge>{{ element.title }}</b-badge>
      <b-badge>
        <span>$</span>
        <span>{{ element.value.toFixed(2) || "-" }}</span>
      </b-badge>
    </div>
    <template v-if="cantidadesTotales.length > 0">
      <div class="total-row" v-for="element in cantidadesTotales" :key="element.nombre">
        <b-badge class="badge-proveedor">{{ element.nombre }}</b-badge>
        <b-badge>
          <span>$</span>
          <span>{{ element.total.toFixed(2) }}</span>
        </b-badge>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Totales",
  props: {
    proveedoresTotales: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      totales: [
        { title: "Venta Grabada", value: 0 },
        { title: "Venta Exenta", value: 0 },
        { title: "Sub Total", value: 0 },
        { title: "I.V.A", value: 0 },
        { title: "I.E.P.S", value: 0 },
        { title: "Total", value: 0 },
      ],
    };
  },
  mounted() {
    this.calcularTotales();
  },
  methods: {
    calcularTotales() {
      this.listaCarrito;
      let subtotales, ivas, tIvas, tSubtotales, ventaExenta, iepsTotal, ventaGrabada;
      
      this.totales = this.totales.map((item) => {
        switch (item.title) {
          case "Sub Total":
            subtotales = 0;
            this.listaCarrito.forEach(({ precio, cantidad }) => {
              subtotales += precio * cantidad;
            });
            return { title: item.title, value: subtotales };
          case "I.V.A":
            ivas = 0;
            this.listaCarrito.forEach(({ precio, cantidad, iva }) => {
              ivas += precio * cantidad * iva;
            });
            return { title: item.title, value: ivas };
          case "Total":
            tIvas = 0;
            tSubtotales = 0;
            this.listaCarrito.forEach(({ precio, iva, cantidad }) => {
              tIvas += precio * cantidad * iva;
              tSubtotales += precio * cantidad;
            });
            return {
              title: item.title,
              value: tIvas + tSubtotales,
            };
          case "Venta Exenta": {
            ventaExenta = 0;
            this.listaCarrito.forEach(({ precio, cantidad, iva }) => {
              if (iva === 0) {
                ventaExenta = ventaExenta + precio * cantidad;
              }
            });
            return {
              title: item.title,
              value: ventaExenta,
            };
          }
          case "I.E.P.S": {
            iepsTotal = 0;
            this.listaCarrito.forEach(({ precio, cantidad, ieps }) => {
              if (ieps !== 0) {
                iepsTotal += precio * cantidad * ieps;
              }
            });
            return {
              title: item.title,
              value: iepsTotal
            }
          }
          case "Venta Grabada": {
            ventaGrabada = 0;
            this.listaCarrito.forEach(({ precio, cantidad, iva }) => {
              if (iva !== 0) {
                ventaGrabada = ventaGrabada + precio * cantidad;
              }
            });
            return {
              title: item.title,
              value: ventaGrabada,
            };
          }
          case "empty":
            return;
          default:
            return { title: item.title, value: 0 };
        }
      });
    },
  },
  computed: {
    ...mapState("productosCliente", [
      "cargando",
      "error",
      "listaProductos",
      "listaCarrito",
      "clienteSeleccionado",
      "proveedorSeleccionado",
      "queryCodigo",
      "queryInput",
    ]),
    cantidadesTotales: function () {
      this.calcularTotales();
      this.proveedoresTotales;
      return this.proveedoresTotales;
    },
  },
};
</script>
<style lang="scss"></style>