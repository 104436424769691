import moment from "moment";
import api from "../utils/api";
import { axiosInstance } from '../utils/axiosInstance';

const pedidos = {
  namespaced: true,
  state: {
    cargando: false,
    cargandoModal: true,
    bloqueado: false,
    error: false,
    listaPedidos: [],
    pedido: {},
    queryPedidos: {},
  },
  getters: {
    cargando: (state) => state.cargando,
    cargandoModal: (state) => state.cargandoModal,
    listaPedidos: (state) => state.listaPedidos,
    pedido: (state) => state.pedido,
  },
  mutations: {
    SET_CARGANDO(state, newValue) {
      state.cargando = newValue;
    },
    SET_CARGANDO_MODAL(state, newValue) {
      state.cargandoModal = newValue;
    },
    SET_LISTA_PEDIDOS(state, newValue) {
      state.listaPedidos = newValue;
    },
    SET_PEDIDO(state, newValue) {
      state.pedido = newValue;
    },
  },
  actions: {
    consultarPedidos({ commit }, payload) {
      commit("SET_CARGANDO", true);
      if (payload.rango) {
        const rangosFecha = {
          dia: 0,
          semana: 6,
          mes: 30,
        };
        let d = new Date();
        d.setDate(d.getDate() - rangosFecha[payload.rango]);
        payload.fechaInicial = moment(d).format("YYYY-MM-DD");
        payload.fechaFinal = moment().format('YYYY-MM-DD');
      }

      payload = {
        ...payload,
        fechaInicial: moment(payload.fechaInicial).format("YYYY-MM-DD 00:00:00"),
        fechaFinal: moment(payload.fechaFinal).format("YYYY-MM-DD 23:59:59"),
        proveedores:
          payload.proveedoresSeleccionados.length === 0
            ? []
            : payload.proveedoresSeleccionados.map(({ value }) => value),
      };

      axiosInstance.get('pedidos', {
        params: payload,
      })
        .then(result => {
          commit(
            "SET_LISTA_PEDIDOS",
            result.data.map((pedido) => {
              return {
                ...pedido,
                creacion: moment(pedido.creacion.split('T')[0]).format("DD/MM/YYYY"),
              };
            })
          );
        })
        .catch(() => {
          commit("SET_LISTA_PEDIDOS", []);
        })
        .finally(() => commit('SET_CARGANDO', false))
    },
    async consultarPedido({ commit }, payload) {
      commit("SET_CARGANDO_MODAL", true);
      api("GET", `pedidos/consultar/${payload.id}`)
        .then(({ productos }) => {
          commit("SET_PEDIDO", {
            ...payload,
            productos: productos.map((producto) => {
              const { precio, ieps, iva, cantidad } = producto;
              delete producto.iva;
              delete producto.ieps;
              delete producto.cantidad;
              return {
                ...producto,
                precioTotal: precio * (ieps || 1) * (iva || 1) + precio,
                cantidad: cantidad,
              };
            }),
          });
        })
        .catch(() => {
          commit("SET_PEDIDO", { ...payload, productos: [] });
        })
        .finally(() => commit("SET_CARGANDO_MODAL", false))
    },
    async consultarTodosCliente({ commit }, { cliente, backorder }) {
      commit('SET_CARGANDO', true)

      if (!cliente) {
        throw new Error('Debe seleccionar un cliente')
      }
      try {
        const result = await axiosInstance.get('pedidos', {
          params: {
            cliente,
            backorder
          }
        })

        commit(
          "SET_LISTA_PEDIDOS",
          result.data.map((pedido) => {
            return {
              ...pedido,
              creacion: moment(pedido.creacion.split('T')[0]).format("DD/MM/YYYY"),
            };
          })
        );
      } catch (error) {
        commit('SET_LISTA_PEDIDOS', [])
        throw new Error(error.response.data.msg || 'Hubo un error al leer los pedidos del cliente')
      } finally {
        commit('SET_CARGANDO', false)
      }
    }
  },
};

export default pedidos;
