"use strict";

require("dotenv").config();
const axios = require("axios");
const baseUrl = process.env.VUE_APP_JPR_API;
//const baseUrl = 'http://localhost:8000/api/';
module.exports = (metodo, url, datos, params = null) => {
  return axios(baseUrl + url, {
    method: metodo,
    headers: { authorization: localStorage.getItem('token') },
    data: datos || "",
    params: params
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
