<template>
  <div id="ProductosCliente">
    <section>
      <article>
        <b-modal id="modal-vista-previa" ref="modal-vista-previa" size="xl" title="Vista Previa" hide-footer>
          <VistaPrevia :orden_id="orden.orden_id || null" />
        </b-modal>
      </article>
      <article>
        <b-modal id="modal-condiciones-venta" size="xl" title="Condiciones de venta por proveedor" hide-footer
          scrollable>
          <b-card :header="proveedor.proveedor" v-for="(proveedor, index) of condiciones" :key="index"
            header-tag="header" footer-tag="footer">
            <article v-for="(condicion, index) of proveedor.condiciones" :key="index">
              <h5>{{ condicion.Condicion }}</h5>
              <ul>
                <li v-for="(cond, index) of condicion.condiciones" :key="index">
                  {{ cond.Condicion }}
                </li>
              </ul>
            </article>
          </b-card>
        </b-modal>
      </article>
    </section>
    <BarraAcciones @guardarCotizacion="guardarCotizacion" @hacerPedido="crearPedido" @borrarSeleccion="borrarOrden"
      @vistaPrevia="vistaPrevia" @condicionesVenta="condicionesVenta" />
    <Orden ref="orden" :productos="listaCarrito" :cargando="cargando" :cliente="cliente"
      @listar-productos="recargarProductos" />
    <BarraBusqueda @clienteSeleccionado="selectCliente" @buscarProductos="buscarProductos"
      @agregarProductos="agregarProductos" @filtrar="filtrar" />
    <ListaProductos ref="listaProductos" :orden="orden.orden_id || null" :cliente="cliente"
      @listar-productos="recargarProductos" />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Orden from "./Orden.vue";
import ListaProductos from "./ListaProductos.vue";
import BarraAcciones from "./BarraAcciones.vue";
import BarraBusqueda from "./BarraBusqueda.vue";
import VistaPrevia from "../../../components/VistaPrevia.vue";
import { axiosInstance } from "../../../utils/axiosInstance";
export default {
  components: {
    Orden,
    ListaProductos,
    BarraAcciones,
    BarraBusqueda,
    VistaPrevia,
  },
  data() {
    return {
      cliente: null,
      condiciones: [],
    };
  },
  mounted() {
    this.initApp();
  },
  computed: {
    ...mapState("productosCliente", [
      "cargando",
      "error",
      "listaProductos",
      "listaCarrito",
      "orden",
      "clienteSeleccionado",
      "proveedorSeleccionado",
      "queryCodigo",
      "queryInput",
    ]),
    soyAdmin() {
      return sessionStorage.getItem('admin') === 'true';
    }
  },
  methods: {
    ...mapActions("productosCliente", ["inicializarOrden", "listarCarrito", 'limpiar']),
    async initApp() {
      if (!this.cliente) {
        this.limpiar();
        return;
      }
      let creador_id = localStorage.getItem("usuario_id");
      const payload = { cliente_id: this.cliente, creador_id };
      await this.inicializarOrden(payload);
      await this.listarCarrito(this.orden.orden_id);
    },
    //crear funcion para inicializar la orden cada vez que se elija un usuario
    recargarProductos() {
      this.initApp();
    },
    selectCliente(cliente) {
      this.cliente = cliente;
      if (!this.cliente) {
        return null;
      }
      this.initApp();
    },
    buscarProductos() {
      const child = this.$refs.listaProductos;
      child.buscarProductos();
    },
    agregarProductos() {
      const child = this.$refs.listaProductos;
      child.agregarProductos();
    },
    guardarCotizacion() {
      const child = this.$refs.orden;
      child.guardarCotizacion();
    },
    crearPedido() {
      const child = this.$refs.orden;
      child.crearPedido();
    },
    borrarOrden() {
      const child = this.$refs.orden;
      child.borrarOrden();
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    vistaPrevia() {
      this.$refs["modal-vista-previa"].show();
    },
    condicionesVenta() {
      if (this.soyAdmin) {
        if (!this.cliente) {
          this.makeToast('Error', 'No ha seleccionado un cliente', 'warning', 3000);
          return;
        }

        axiosInstance.get(`proveedores/condiciones/cliente/${this.cliente}`,
          {
            headers: {
              authorization: localStorage.getItem('token')
            }
          }
        )
          .then(({ data }) => {
            this.condiciones = data;
            this.$bvModal.show('modal-condiciones-venta');
          })
          .catch(() => this.makeToast('Error', 'Ha ocurrido un error al consultar las condiciones.', 'danger', 3000));
      }
      else {
        axiosInstance.get(`proveedores/condiciones/cliente/${localStorage.getItem('usuario_id')}`,
          {
            headers: {
              authorization: localStorage.getItem('token')
            }
          }
        )
          .then(({ data }) => {
            this.condiciones = data;
            this.$bvModal.show('modal-condiciones-venta');
          })
          .catch(() => this.makeToast('Error', 'Ha ocurrido un error al consultar las condiciones.', 'danger', 3000));
      }
    },
    filtrar(filtro) {
      const child = this.$refs.listaProductos;
      child.buscarProductos(filtro);
    }
  },
};
</script>
<style lang="scss">
@import "./Productos.scss";
</style>