<template>
  <div id="main_client">
    <SideBar id="sidebar" />
    <b-container id="router-container" fluid>
      <Navbar />
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar.vue";
export default {
  name: "main_client",
  components: {
    Navbar,
    SideBar,
  },
};
</script>

<style>
#sidebar { 
box-shadow: 1px .5px rgba(0, 0, 0, 0.1);
}
#main_client {
  display: grid;
  grid-template-columns: 50px auto;
}
#router-container {
  grid-column: 2/3;
  padding: 0px;
}
</style>
