<template>
  <b-modal id="modal-visualizado-fotos" ref="modal-visualizador-fotos" :title="item.descripcion" hide-footer>
    <div class="carousel-container" v-if="rutasImagen.length > 0">
      <Carousel class="carrousell" :rutasImagen="rutasImagen" />
    </div>
    <h2 v-if="rutasImagen.length === 0">No hay fotografias.</h2>
  </b-modal>
</template>

<script>
import Carousel from "./_BaseCaroussell.vue";
import api from "../../utils/api";

export default {
  name: "BaseVisualizadorFotos",
  data() {
    return {
      rutasImagen: [],
      item: {},
    };
  },
  components: { Carousel },
  methods: {
    getRutasImagenes(item) {
      this.item = item;
      api("GET", `productos-imagen/obtener-imagenes/${item.producto_id}`)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Imagenes no encontradas",
              text: "Error del servidor",
              type: "error",
            });
            this.rutasImagen = [];
          } else {
            this.rutasImagen = [...result];
            this.$refs["modal-visualizador-fotos"].show();
          }
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            title: "Error al obtener las rutas de las imagenes",
            text: "Error del servidor",
            type: "error",
          });
        });
    },
  },
};
</script>
<style lang="scss"></style>