<template>
  <div id="Orden">
    <BaseBackBar :titulo="backorder ? 'Backorders' : 'Pedidos'" goTo="/" />
    <BarraBusqueda v-if="!$route.meta.backorder" />
    <BarraBusquedaBackorder v-else />
    <div class="px-2">
      <b-overlay :show="cargando">
        <b-table-simple hover small sticky-header="85vh">
          <b-thead>
            <b-tr>
              <b-th class="text-center">Fecha y hora</b-th>
              <b-th class="text-center">Pedido</b-th>
              <b-th>Nombre</b-th>
              <b-th>Total</b-th>
              <b-th>Proveedor</b-th>
              <b-th class="text-center">Creado por</b-th>
              <b-th class="text-center">Opciones</b-th>
              <b-th class="text-center">Cancelado</b-th>
              <b-th class="text-center" v-if="$route.meta.backorder">Consecutivo</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(pedido, index) of listaPedidos" :key="index"
              :style="`background-color: ${pedido.proveedor_proveedore.color};`">
              <b-td class="text-center">{{ pedido.creacion }}</b-td>
              <b-td class="text-center">{{ pedido.codigo }}</b-td>
              <b-td>{{ pedido.cliente_usuario.razon_social }}</b-td>
              <b-td class="text-right">${{ parseFloat(pedido.total).toFixed(2) }}</b-td>
              <b-td>{{ pedido.proveedor_proveedore.nombre }}</b-td>
              <b-td class="text-center">{{ pedido.vendedor_usuario.razon_social || pedido.vendedor_usuario.nombre
                }}</b-td>
              <b-td class="text-center">
                <b-button variant="info" @click="onRenderComentarios(pedido.comentarios)" v-if="pedido.comentarios">
                  <b-icon icon="chat-dots" variant="light"></b-icon>
                </b-button>
                <b-button variant="info" @click="onRowSelected(pedido)">
                  Ver
                </b-button>
              </b-td>
              <b-td class="text-center">{{ pedido.cancelado ? 'Sí' : 'No' }}</b-td>
              <b-td v-if="$route.meta.backorder" class="text-center">{{ pedido.consecutivo }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </div>
    <b-modal id="bv-modal-detalles-pedido" size="xl" hide-footer hide-header ignore-enforce-focus-selector=".swal2-modal">
      <Detalles @cerrar="$bvModal.hide('bv-modal-detalles-pedido')" :pedido="pedido" />
    </b-modal>
    <b-modal id="bv-modal-comentarios-pedido" size="sm" hide-footer hide-header>
      {{ comentarios }}
    </b-modal>
    <PdfPedidos :pedido="pedido" />
    <ExcelPedidos :pedido="pedido" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BarraBusqueda from "./BarraBusqueda.vue";
import BarraBusquedaBackorder from "./BarraBusquedaBackorder.vue";
import Detalles from "./Detalles.vue";
import PdfPedidos from "../../../components/pdf/pedidos.vue";
import ExcelPedidos from "../../../components/excel/pedidos.vue";

export default {
  name: "Pedidos",
  components: {
    BarraBusqueda,
    BarraBusquedaBackorder,
    Detalles,
    PdfPedidos,
    ExcelPedidos,
  },
  data() {
    return {
      comentarios: "",
      pedido: {
        "codigo": 154,
        "id": "f2bc4f7b-5923-46ac-8a4b-ce7d74b5536a",
        "cliente": "43b41ef6-7940-4f4e-9f1c-ace4243137b3",
        "vendedor": "27edcdf9-1a67-4a13-8b0b-bd1fd9ae5dc6",
        "proveedor": "714128d8-0895-4df2-a22f-ca8408bb5d73",
        "FacturaID": null,
        "total": 122.03,
        "subtotal": 105.2,
        "iva": 16.83,
        "ieps": 0,
        "confirmacion_enviada": 0,
        "fecha_confirmacion": "2024-02-26T15:36:57.000Z",
        "creacion": "2024-02-26T15:36:57.000Z",
        "comentarios": "pedido de prueba",
        "backorder": 0,
        "consecutivo": "",
        "pedido_productos": [],
        "proveedor_proveedore": {
          "proveedor_id": "714128d8-0895-4df2-a22f-ca8408bb5d73",
          "nombre": "Tlaquepaque Escolar S.A. de C.V.",
          "domicilio": "Alvaro Obregon # 130  C.P. 45500",
          "ciudad": "Tlaquepaque, Jalisco.",
          "estado": "",
          "pais": "Mexico",
          "tel_1": "3336570212",
          "tel_2": " 3338376704",
          "rfc": "TES-900403-QX0",
          "nombre_corto": "TLAQUEPAQUE",
          "color": "#ff6ae6",
          "email": "",
          "suspendido": false,
          "id_original": 6,
          "formula": ",,",
          "comisiones_id": "2ca49655-d611-4b2a-959f-98644f4f7e1f"
        },
        "cliente_usuario": {
          "razon_social": "BLANCA ESTHELA LEON PEÑA",
          "rfc": "LEPB-681023-MG7",
          "domicilio": "CALLE VIOLETA # 7341 FRACC. TERRANOVA CP. 80143",
          "ciudad": "CULIACAN, SINALOA",
          "tel": "6677614106",
          "perfil_cliente_perfil_cliente": {
            "id": "4f44280c-a0bb-440c-b904-820a58c18646",
            "nombre_comercial": null,
            "codigo_postal": "80029",
            "ciudad": "Culiacán",
            "estado": "SINALOA",
            "contacto_pdg": "514532eb-13dd-4e0b-9d81-5ad858f7b232",
            "contacto_comprador": "f34e68d4-dc44-4ed3-8b0d-d05b78e1622b",
            "contacto_pago": "9cdc9fc0-4ffc-4302-bf27-14819cdedf5d",
            "correo_facturas": null,
            "correo_comprador": null,
            "correo_pagos": null,
            "correo_confirmacion": null,
            "correo_ofertas": null,
            "correo_notificaciones": null,
            "intervalo_notificaciones": null,
            "condiciones_venta": "Pago por adelantado",
            "dias_prorroga": null,
            "metodo_pago": null,
            "forma_pago": null,
            "regimen_fiscal": null,
            "uso_cfdi": null
          }
        },
        "vendedor_usuario": {
          "razon_social": null,
          "nombre": "SUPERADMIN"
        },
        "transporte": {
          "nombre": "TRANSPORTES CASTORES"
        },
        "concepto": "TLAQUEPAQUE PEDIDO 154"
      },
    };
  },
  methods: {
    ...mapActions("pedidos", ["consultarPedido"]),
    onRowSelected(item) {
      this.pedido = item;
      this.$bvModal.show("bv-modal-detalles-pedido");
    },
    onRenderComentarios(comentarios) {
      this.comentarios = comentarios;
      this.$bvModal.show("bv-modal-comentarios-pedido");
    },
  },
  computed: {
    ...mapGetters("pedidos", [
      "cargando",
      "bloqueado",
      "error",
      "listaPedidos",
    ]),
    backorder() {
      return this.$route.meta.backorder;
    }
  },
};
</script>