import api from "../utils/api";
import { axiosInstance } from '../utils/axiosInstance'

const facturas = {
  namespaced: true,
  state: {
    isBusy: false,
    listaFacturas: [],
    listaClientes: [],
    listaProveedores: [],
    pedido: null
  },
  getters: {
    getFacturas: (state) => state.listaFacturas,
  },
  mutations: {
    SET_FACTURAS_LIST(state, listaFacturas) {
      state.listaFacturas = listaFacturas;
    },
    SET_CLIENTES_SELECT(state, listaClientes) {
      state.listaClientes = listaClientes;
    },
    SET_PROVEEDORES_SELECT(state, listaProveedores) {
      state.listaProveedores = listaProveedores;
    },
    SET_IS_BUSY(state, isBusy) {
      state.isBusy = isBusy;
    }
  },
  actions: {
    // consultar Facturas
    consultarFacturasAction: async function ({ commit }, payload) {
      commit("SET_IS_BUSY", true);
      api("GET", "facturas", payload.body, payload.params)
        .then((result) => {
          if (result.errorCode) {
            commit("SET_FACTURAS_LIST", []);
            commit("SET_IS_BUSY", true);
            return;
          }
          commit("SET_FACTURAS_LIST",
            result
          );
          commit("SET_IS_BUSY", false);
        })
        .catch(() => {
          commit("SET_FACTURAS_LIST", []);
          commit("SET_IS_BUSY", false);
        });
    },
    // consultar Clientes
    consultarClientes: async function ({ commit }, payload) {
      api("GET", "usuarios/consultar-usuarios/cliente", payload)
        .then((result) => {
          if (result.errorCode) {
            commit("SET_CLIENTES_SELECT", []);
            return;
          }
          commit("SET_CLIENTES_SELECT", [
            { value: null, text: "-- Todos los clientes --" },
            ...result.usuarios.map((res) => {
              return {
                value: res.usuario_id || null,
                text: res.razon_social || "",
              };
            })
          ]);
        })
        .catch(() => {
          commit("SET_CLIENTES_SELECT", []);
        });
    },
    // consultar Proveedores
    consultarProveedores: async function ({ commit }, payload) {
      api("GET", "proveedores/obtener-proveedores", payload)
        .then((result) => {
          if (result.errorCode) {
            commit("SET_PROVEEDORES_SELECT", []);
            return;
          }
          //commit("cargaProveedores", proveedores);
          commit("SET_PROVEEDORES_SELECT", [
            { value: null, text: "-- Todos los proveedores --" },
            ...result.proveedores.map((res) => {
              return {
                value: res.proveedor_id || null,
                text: res.nombre || "",
              };
            })
          ]);

        })
        .catch(() => {
          commit("SET_PROVEEDORES_SELECT", []);
        });
    },
    async getProveedoresCliente({ commit }, usuario_id) {
      axiosInstance.get('proveedores/cliente', {
        params: { usuario_id }
      })
        .then((result) => {
          commit("SET_PROVEEDORES_SELECT", [
            { value: null, text: "-- Todos los proveedores --" },
            ...result.data.map((res) => {
              return {
                value: res.proveedor_id || null,
                text: res.proveedor.nombre || "",
              };
            })
          ]);
        })
        .catch(() => {
          commit("SET_PROVEEDORES_SELECT", []);
        });
    },
    limpiarDatos({ commit }) {
      commit('SET_FACTURAS_LIST', []);
    },
  }
};

export default facturas;
