<template>
  <b-container fluid>
    <section class="d-flex justify-content-around align-items-center" id="busqueda">
      <article class="w-25">
        <label for="proveedores">Proveedores</label>
        <Multiselect multiple id="proveedores" :options="proveedores" v-model="proveedorSelected"
          placeholder="Seleccione un proveedor." searchable allow-empty track-by="value" label="text" />
      </article>

      <article class="w-50">
        <label for="marcas">Marcas</label>
        <b-form-select :options="marcas" id="marcas" v-model="marcaSelected"></b-form-select>
      </article>

      <article>
        <label for="codigo">Código</label>
        <b-form-input type="text" id="codigo" v-model="codigo" placeholder="Código de producto" />
      </article>
    </section>

    <section class="d-flex">
      <article class="mr-4">
        <b-button @click="get" variant="primary" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          Buscar ofertas
        </b-button>
      </article>

      <article class="mr-4">
        <b-button @click="eliminarOferta" variant="primary" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          Eliminar seleccionadas
        </b-button>
      </article>

      <article class="mr-4">
        <b-button @click="eliminarConFiltros" variant="primary" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          Eliminar por marca y proveedor
        </b-button>
      </article>

      <article>
        <b-button variant="primary" @click="limpiar" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          Limpiar filtros
        </b-button>
      </article>
    </section>

    <b-overlay :show="loading">
      <b-table-simple small hover style="font-size: 14px;" sticky-header="400px">
        <b-thead>
          <b-tr>
            <b-th class="text-center">
              <b-form-checkbox v-model="seleccionarTodas" name="checkbox-1" value="true" unchecked-value="" />
            </b-th>
            <b-th class="text-center">Código</b-th>
            <b-th class="text-center">Subcódigo</b-th>
            <b-th>Descripción</b-th>
            <b-th class="text-center">Proveedor</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 1</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 2</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 3</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 4</b-th>
            <b-th class="text-center" style="text-wrap: nowrap;">Precio 5</b-th>
            <b-th class="text-center">Porcentaje</b-th>
            <b-th class="text-center">Fecha Fin</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(oferta, index) of ofertas" :key="index">
            <b-td class="text-center">
              <b-form-checkbox v-model="oferta.seleccionada" name="checkbox-1" value="true" unchecked-value="" />
            </b-td>
            <b-td class="text-center" style="text-wrap: nowrap;">{{ oferta.producto.codigo }}</b-td>
            <b-td class="text-center" style="text-wrap: nowrap;">{{ oferta.producto.subcodigo }}</b-td>
            <b-td style="text-wrap: stable;">{{ oferta.producto.descripcion }}</b-td>
            <b-td class="text-center">{{ oferta.proveedor.nombre }}</b-td>
            <b-td class="text-right">${{ oferta.precio_1 }}</b-td>
            <b-td class="text-right">${{ oferta.precio_2 }}</b-td>
            <b-td class="text-right">${{ oferta.precio_3 }}</b-td>
            <b-td class="text-right">${{ oferta.precio_4 }}</b-td>
            <b-td class="text-right">${{ oferta.precio_5 }}</b-td>
            <b-td class="text-center">{{ oferta.porcentaje }}%</b-td>
            <b-td class="text-center">{{ oferta.fecha ? parseDate(oferta.fecha) : '' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-overlay>
    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
  </b-container>
</template>

<script>
import Multiselect from 'vue-multiselect';
import moment from 'moment-timezone';
import { axiosInstance } from '../../../utils/axiosInstance';
import Swal from 'sweetalert2';
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      ofertas: [],
      loading: false,
      seleccionarTodas: '',
      proveedores: [],
      proveedorSelected: [],
      marcas: [{ value: null, text: 'Seleccione una marca' }],
      marcaSelected: null,
      codigo: '',
      currentPage: 1,
      rows: 0,
      perPage: 500,
    }
  },
  mounted() {
    this.get();
    axiosInstance.get('proveedores', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
      .then(proveedores => {
        proveedores.data.forEach(proveedor => {
          this.proveedores.push({ value: proveedor.proveedor_id, text: proveedor.nombre })
        })
      })
      .catch(() => {
        this.makeToast('Proveedores', 'Hubo un error al obtener los proveedores', 'danger', 3000);
      });

    axiosInstance.get('marcas', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
      .then(marcas => {
        marcas.data.forEach(marca => {
          this.marcas.push({ value: marca.marca_id, text: marca.nombre })
        })
      })
      .catch(() => {
        this.makeToast('Marcas', 'Hubo un error al obtener las marcas', 'danger', 3000);
      })
  },
  methods: {
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    get() {
      this.loading = true;
      axiosInstance.get('ofertas', {
        headers: {
          authorization: localStorage.getItem('token')
        },
        params: {
          marca_id: this.marcaSelected,
          proveedores: this.proveedorSelected.map(proveedor => proveedor.value),
          codigo: this.codigo,
          page: this.currentPage,
          numPerPage: this.perPage
        }
      }).then(result => {
        this.ofertas = result.data.ofertas.map(oferta => ({ ...oferta, seleccionada: '' }));
        this.rows = result.data.count;
      }).catch(() => this.makeToast('Obtener ofertas', 'Hubo un error al obtener las ofertas', 'danger', 3000))
        .finally(() => this.loading = false);
    },
    eliminarOferta() {
      const eliminar = this.ofertas.filter(oferta => oferta.seleccionada);

      if (!eliminar.length) {
        this.makeToast('Eliminar ofertas', 'Seleccione al menos una oferta.', 'warning', 3000);
        return;
      }

      this.loading = true;

      axiosInstance.delete('ofertas', {
        data: { ofertas: eliminar, porFiltros: false },
        headers: {
          authorization: localStorage.getItem('token')
        }
      }).then(() => {
        this.makeToast('Eliminar ofertas', 'Ofertas eliminadas', 'success', 3000);
        this.get();
      }).catch((error) => this.makeToast('Eliminar ofertas', error.response.data.msg || 'Hubo un error al eliminar las ofertas.', 'danger', 3000))
        .finally(() => this.loading = false)
    },
    async eliminarConFiltros() {
      if (!this.marcaSelected && !this.proveedorSelected.length) {
        this.makeToast('Eliminar ofertas', 'Seleccione al menos un proveedor o marca', 'warning', 3000);
        return;
      }

      let marca = false;
      if (this.marcaSelected) {
        const respuesta = await Swal.fire({
          showDenyButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          denyButtonText: 'No',
          confirmButtonText: 'Sí',
          text: '¿Desea eliminar la oferta para toda la marca?',
          icon: 'question'
        });

        if (respuesta.isConfirmed) {
          marca = true;
        }
      }

      this.loading = true;
      axiosInstance.delete('ofertas', {
        data: {
          marca_id: this.marcaSelected,
          proveedores: this.proveedorSelected.map(proveedor => proveedor.value),
          porFiltros: true,
          marca,
        },
        headers: {
          authorization: localStorage.getItem('token')
        }
      })
        .then(() => {
          this.makeToast('Eliminar ofertas', 'Ofertas eliminadas', 'success', 3000);
          this.get();
        })
        .catch((error) => {
          this.makeToast('Eliminar ofertas', error.response.data.msg || 'Hubo un error al eliminar las ofertas.', 'danger', 3000);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    limpiar() {
      this.marcaSelected = null;
      this.proveedorSelected = [];
      this.codigo = '';
    },
    parseDate(date) {
      return moment(date.split('T')[0]).format('DD/MM/YYYY')
    }
  },
  watch: {
    seleccionarTodas(newValue) {
      if (newValue) {
        this.ofertas.forEach((oferta, index, array) => {
          array[index].seleccionada = 'true';
        })
      }
      else {
        this.ofertas.forEach((oferta, index, array) => {
          array[index].seleccionada = '';
        })
      }
    },
    currentPage() {
      this.get();
    }
  }
}
</script>