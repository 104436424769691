<template>
  <div>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Mensajes</h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container class="table_container" fluid>
          <b-table
            :items="listaPresentaciones"
            :fields="camposMarcas"
            striped
            bordered
            hover
            head-variant="light"
            :per-page="perPage"
            :busy="isBusy"
            :filter="filter"
            :filter-included-fields="filterOn"
          ></b-table>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "../../../utils/api";
export default {
  data() {
    return {
      listaPresentaciones: [],
      camposMarcas: [
        {
          key: "email",
          label: "Correo",
        },
        {
          key: "mensaje",
          label: "Mensaje",
        },
      ],

      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      currentId: "",
      presentacionNueva: {
        presentacion: "",
      },
      presentacionModificada: {
        presentacion: "",
      },
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarPresentaciones() {
      api("GET", "cms-mensajes/obtener-mensajes").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener las marcas",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          this.listaPresentaciones = result;
        }
      });
    },
    guardarPresentacion() {
      api("POST", "presentaciones/crear", this.presentacionNueva)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al crear la presentacion",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["modal-agregar"].hide();
            this.presentacionNueva.presentacion = "";
            this.listarPresentaciones();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    modificarPresentacion() {
      api(
        "PUT",
        `presentaciones/modificar/${this.presentacionModificada.presentacion_id}`,
        { presentacion: this.presentacionModificada.presentacion }
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar la presentacion",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["modal-modificar"].hide();
            this.listarPresentaciones();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    abrirModal() {
      this.$refs["modal-agregar"].show();
    },
  },
  mounted() {
    this.listarPresentaciones();
  },
  computed: {
    rows() {
      return this.listaPresentaciones.length;
    },
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}
#sidebar-right {
  width: 450px;
}

.fill {
  color: #aaa;
}
.cabecera_roles {
  display: none;
}
.table_container {
  margin-top: 20px;
}
.table_container tr:hover {
  cursor: pointer;
}
#tabla_roles {
  margin-top: 10px;
}
.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}
.detalles_body {
  margin: 10px;
}
.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}
.txt_dato {
  font-weight: bold;
}
.txt_dato_resp {
  color: #424242;
}
.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.modal_form_personal {
}
</style>
 