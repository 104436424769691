<template>
  <div>
    <b-modal
      id="modal-modificar"
      ref="modal-modificar"
      size="lg"
      title="Modificar Personal"
      scrollable
      hide-footer
    >
      <div class="modal_form_personal">
        <b-form
          ><b-form-group id="intput-group-id" label="ID:">
            <b-form-input
              id="input-id"
              required
              disabled
              v-model="selected.usuario_id"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-email" label="Email:">
            <b-form-input
              id="input-email"
              required
              type="email"
              placeholder="Email del personal"
              v-model="selected.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-nombre" label="Nombre:">
            <b-form-input
              id="input-nombre"
              required
              placeholder="Nombre del personal"
              v-model="selected.nombre"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-apellido" label="Apellido:">
            <b-form-input
              id="input-apellido"
              required
              placeholder="Apellido del personal"
              v-model="selected.apellido"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="intput-group-tel" label="Teléfono de trabajo:">
            <b-form-input
              id="input-tel"
              required
              placeholder="Teléfono de trabajo"
              type="number"
              v-model="selected.tel"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-cel" label="Teléfono personal:">
            <b-form-input
              id="input-celular"
              required
              placeholder="Celular personal"
              type="number"
              v-model="selected.celular"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-curp" label="CURP:">
            <b-form-input
              id="input-curp"
              required
              placeholder="CURP"
              v-model="selected.curp"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-no_seguro" label="Seguro Social:">
            <b-form-input
              id="input-no_seguro"
              required
              placeholder="Seguro Social"
              v-model="selected.no_seguro"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-nacimiento" label="Cumpleaños:">
            <b-form-input
              id="input-nacimiento"
              required
              placeholder="Nacimiento"
              type="date"
              v-model="selected.nacimiento"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="intput-group-email_personal"
            label="Correo personal:"
          >
            <b-form-input
              id="input-email_personal"
              required
              placeholder="Correo personal"
              v-model="selected.email_personal"
            ></b-form-input>
          </b-form-group>
          <div style="display: flex; justify-content: space-between">
            <b-button
              @click="cerrarModal"
              style="background-color: #a5594e; border-color: #a5594e"
            >
              DESCARTAR
            </b-button>
            <b-button
              @click="modificarPersonal"
              style="background-color: #355070"
            >
              GUARDAR
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal
      id="modal-agregar"
      ref="modal-agregar"
      size="lg"
      title="Agregar Personal"
      scrollable
      hide-footer
    >
      <div class="modal_form_personal">
        <b-form
          ><b-form-group id="intput-group-id" label="ID:">
            <b-form-input
              id="input-id"
              required
              disabled
              v-model="nuevoUsuario.usuario_id"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-email" label="Email:">
            <b-form-input
              id="input-email"
              required
              type="email"
              placeholder="Email del personal"
              v-model="nuevoUsuario.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-password" label="Contraseña:">
            <b-form-input
              id="input-password"
              required
              placeholder="Contraseña del personal"
              type="password"
              v-model="nuevoUsuario.password"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-nombre" label="Nombre:">
            <b-form-input
              id="input-nombre"
              required
              placeholder="Nombre del personal"
              v-model="nuevoUsuario.nombre"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-apellido" label="Apellido:">
            <b-form-input
              id="input-apellido"
              required
              placeholder="Apellido del personal"
              v-model="nuevoUsuario.apellido"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="intput-group-tel" label="Teléfono de trabajo:">
            <b-form-input
              id="input-tel"
              required
              placeholder="Teléfono de trabajo"
              type="number"
              v-model="nuevoUsuario.tel"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-cel" label="Teléfono personal:">
            <b-form-input
              id="input-celular"
              required
              placeholder="Celular personal"
              type="number"
              v-model="nuevoUsuario.celular"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-domicilio" label="Domicilio:">
            <b-form-input
              id="input-domicilio"
              required
              placeholder="Domicilio personal"
              type="address"
              v-model="nuevoUsuario.domicilio"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-curp" label="CURP:">
            <b-form-input
              id="input-curp"
              required
              placeholder="CURP"
              v-model="nuevoUsuario.curp"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-no_seguro" label="Seguro Social:">
            <b-form-input
              id="input-no_seguro"
              required
              placeholder="Seguro Social"
              v-model="nuevoUsuario.no_seguro"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-nacimiento" label="Cumpleaños:">
            <b-form-input
              id="input-nacimiento"
              required
              placeholder="Nacimiento"
              type="date"
              v-model="nuevoUsuario.nacimiento"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="intput-group-email_personal"
            label="Correo personal:"
          >
            <b-form-input
              id="input-email_personal"
              required
              placeholder="Correo personal"
              v-model="nuevoUsuario.email_personal"
            ></b-form-input>
          </b-form-group>
          <b-form-checkbox
            id="esCliente"
            v-model="nuevoUsuario.esCliente"
            name="esCliente"
            :value="true"
            :unchecked-value="false"
            style="margin-bottom: 10px"
          >
            Es Cliente?
          </b-form-checkbox>

          <template v-if="nuevoUsuario.esCliente">
            <b-form-group id="intput-group-cobro_email" label="Correo Cobro:">
              <b-form-input
                id="input-cobro_email"
                required
                placeholder="Email Cobro"
                v-model="nuevoUsuario.cobro_email"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="intput-group-cobro_dias" label="Dias Cobro:">
              <b-form-input
                id="input-cobro_dias"
                required
                type="numeric"
                placeholder="Dias Cobro"
                v-model="nuevoUsuario.cobro_dias"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="intput-group-cobro_email" label="Comprador:">
              <b-form-input
                id="input-cobro_email"
                required
                placeholder="Comprador: "
                v-model="nuevoUsuario.cobro_email"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="intput-group-cobro_dias" label="Condiciones:">
              <b-form-input
                id="input-cobro_dias"
                required
                type="numeric"
                placeholder="Condiciones"
                v-model="nuevoUsuario.cobro_dias"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="intput-group-cobro_dias" label="Clave:">
              <b-form-input
                id="input-cobro_dias"
                required
                type="numeric"
                placeholder="Clave"
                v-model="nuevoUsuario.cobro_dias"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="intput-group-cobro_dias"
              label="Cumpleanos Comprador:"
            >
              <b-form-input
                id="input-cobro_dias"
                required
                type="date"
                placeholder="Cumpleanos comprador"
                v-model="nuevoUsuario.cobro_dias"
              ></b-form-input>
            </b-form-group>
          </template>
          <div style="display: flex; justify-content: space-between">
            <b-button
              @click="cerrarModal"
              style="background-color: #a5594e; border-color: #a5594e"
            >
              DESCARTAR
            </b-button>
            <b-button
              @click="guardarPersonal"
              style="background-color: #355070"
            >
              GUARDAR
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-right"
      backdrop
      no-header
      title="Detalles"
      right
      bg-variant="white"
      shadow
    >
      <div class="detalles_header">
        <b-icon id="x-icon" v-b-toggle.sidebar-right icon="x"></b-icon>
        <h3>Detalles Usuario</h3>
      </div>
      <div class="detalles_body">
        <b-row>
          <b-col>
            <h4 class="fill">
              {{ this.selected.nombre }} {{ this.selected.apellido }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-tabs style="margin-top: 5px; height: 100%">
              <b-tab title="Datos">
                <div class="dato_item">
                  <p class="txt_dato">ID:</p>
                  <p class="txt_dato_resp">{{ this.selected.usuario_id }}</p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Nombre:</p>
                  <p class="txt_dato_resp">
                    {{ this.selected.nombre }} {{ this.selected.apellido }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Correo:</p>
                  <p class="txt_dato_resp">{{ this.selected.email }}</p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Teléfono:</p>
                  <p class="txt_dato_resp">{{ this.selected.tel || "-" }}</p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Creacion:</p>
                  <p class="txt_dato_resp">
                    {{ this.selected.created_at || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Domicilio:</p>
                  <p class="txt_dato_resp">
                    {{ this.selected.domicilio || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Celular:</p>
                  <p class="txt_dato_resp">
                    {{ this.selected.celular || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">CURP:</p>
                  <p class="txt_dato_resp">{{ this.selected.curp || "-" }}</p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Seguro Social:</p>
                  <p class="txt_dato_resp">
                    {{ this.selected.no_seguro || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Cumpleaños:</p>
                  <p class="txt_dato_resp">
                    {{ this.parseDate(this.selected.nacimiento) || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Email Personal:</p>
                  <p class="txt_dato_resp">
                    {{ this.selected.email_personal || "-" }}
                  </p>
                </div>
                <b-button
                  block
                  style="background-color: #355070; margin-top: 10px"
                  v-b-toggle.sidebar-right
                  v-b-modal.modal-modificar
                  >MODIFICAR</b-button
                >
              </b-tab>
              <b-tab title="Roles">
                <div class="roles_container">
                  <b-table
                    bordered
                    id="tabla_roles"
                    thead-class="cabecera_roles"
                    :items="itemsRoles"
                    :empty-text="'Sin roles asignados'"
                    :show-empty="itemsRoles.length === 0"
                  >
                  </b-table>
                  <b-button
                    block
                    style="background-color: #355070"
                    @click="goto"
                    >Administrar roles</b-button
                  >
                </div>
              </b-tab>
              <b-tab
                style="margin-top: 5px; max-height: 100%"
                id=""
                title="Sesiones"
              >
                <div class="roles_container">
                  <b-table
                    bordered
                    id="tabla_roles"
                    thead-class="cabecera_roles"
                    :items="itemsSesiones"
                    :empty-text="'Sin sesiones registradas'"
                    show-empty
                  >
                  </b-table>
                </div>
              </b-tab>
              <b-tab
                style="margin-top: 5px; max-height: 100%"
                id=""
                title="Proveedores"
                v-if="this.selected.esCliente"
              >
                <b-button
                  style="
                    width: 100%;
                    background-color: #355070;
                    border-color: #355070;
                  "
                  @click="gotoPreciosProveedores"
                  ><b-icon icon="plus" style="margin-right: 4px" />Administrar
                  proveedores</b-button
                >
                <b-table
                  bordered
                  id="tabla_proveedores"
                  :items="itemsProveedores"
                  :fields="proveedoresFields"
                  :empty-text="'Sin proveedores asignados'"
                  show-empty
                ></b-table>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px; margin-left: 20px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Usuarios</h2>
        </div></b-col
      >
    </b-row>
    <b-row>
      <b-col>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-right: 10px;
          "
        >
          <b-input-group style="width: 450px; padding-left: 10px">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Filtrar..."
            ></b-form-input>
          </b-input-group>
          <div style="display: flex; flex-direction: row">
            <p style="margin-right: 10px">Filtrar:</p>
            <b-form-select v-model="userType" :options="options" />
          </div>
          <div>
            <b-button
              style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              "
              @click="listarPersonal"
              ><b-icon
                icon="search"
                style="margin-right: 4px"
              />Buscar</b-button
            >
            <b-button
              style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              "
              @click="abrirModal"
              ><b-icon icon="plus" style="margin-right: 4px" />AGREGAR</b-button
            >
            <b-button
              style="background-color: #a5594e; border-color: #a5594e"
              disabled
              ><b-icon
                icon="trash"
                style="margin-right: 4px"
              />ELIMINAR</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container class="table_container" fluid>
          <b-table
            :items="items"
            :fields="fields"
            striped
            bordered
            hover
            head-variant="light"
            :per-page="perPage"
            :busy="isBusy"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            v-b-toggle.sidebar-right
          >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            :current-page="currentPage"
          ></b-pagination>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import template from "../../components/base/template.vue";
import api from "../../utils/api";

export default {
  components: {},
  data() {
    return {
      fields: [
        {
          key: "nombre",
          sortable: true,
          label: "Nombre",
        },
        {
          key: "apellido",
          sortable: true,
          label: "Apellido",
        },
        {
          key: "email",
          sortable: true,
          label: "Correo",
        },
        {
          key: "tel",
          sortable: true,
          label: "Telefono",
        },
        {
          key: "created_at",
          sortable: true,
          label: "Antiguedad",
        },
      ],
      userType: null,
      options: [
        { value: null, text: "Todos los usuarios" },
        { value: "cliente", text: "Clientes" },
        { value: "personal", text: "Personal" },
      ],
      currentPage: 1,
      perPage: 10,
      items: [],
      itemsAux: [],
      isBusy: false,
      filter: null,
      filterOn: [],
      selected: {},
      itemsRoles: [],
      itemsSesiones: [],
      itemsProveedores: [],
      proveedoresFields: [
        {
          key: "nombreProveedor",
          label: "Proveedores autorizados",
          sortable: true,
        },
      ],
      isBusyRoles: false,
      nuevoUsuario: {},
      buscarClientes: false,
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarPersonal() {
      this.isBusy = !this.isBusy;
      api(
        "GET",
        this.userType
          ? "usuarios/consultar-usuarios/" + this.userType
          : "usuarios/consultar-usuarios"
      ).then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener el personal",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          result.usuarios.map((usuario) => {
            usuario.created_at = usuario.created_at
              ? moment(usuario.created_at).format("LLL")
              : "-";
          });
          this.items = result.usuarios;
          this.itemsAux = result.usuarios;
          this.isBusy = !this.isBusy;
        }
      });
    },
    gotoPreciosProveedores() {
      this.$router.push(
        `/app/precios-clientes/${this.selected.usuario_id}/nombre/${this.selected.nombre}`
      );
    },
    onRowSelected(item) {
      if (item[0]) {
        this.getRoles(item[0].usuario_id);
        this.getSesiones(item[0].usuario_id);
        if (item[0].esCliente) this.getProveedores(item[0].usuario_id);
        this.selected = item[0];
      }
    },
    getRoles(id) {
      this.isBusyRoles = true;
      api("GET", `rol-usuario/consultar-por-usuario/${id}`)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al roles del personal",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.itemsRoles = result.roles;
            this.isBusyRoles = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProveedores(id) {
      this.isBusyRoles = true;
      api("GET", `cliente-proveedor/consultar/${id}`)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al consultar los proveedores del cliente",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.itemsProveedores = result.proveedoresAsignados;
            this.isBusyRoles = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    parseDate(date) {
      return moment(date || "00").format("DD-MM-YYYY");
    },
    abrirModal() {
      this.$refs["modal-agregar"].show();
    },
    getSesiones(id) {
      this.isBusyRoles = true;
      api("GET", `sesiones/obtener-sesiones-usuario/${id}`)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al roles del personal",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            let itemArray = [];
            result.sesiones.map((sesion) =>
              itemArray.push({
                fecha: moment(sesion.fecha).format("DD/MM/YYYY hh:mm:ss"),
              })
            );
            this.itemsSesiones = itemArray.reverse();
            this.isBusyRoles = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cerrarModal() {
      this.$refs["modal-agregar"].hide();
    },
    cerrarModalModificar() {
      this.$refs["modal-modificar"].hide();
    },
    guardarPersonal() {
      api("POST", "usuarios/crear-usuario", {
        ...this.nuevoUsuario,
        personal_id: sessionStorage.getItem("usuario_id"),
      })
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al crear el personal",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["modal-agregar"].hide();
            this.listarPersonal();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goto() {
      this.$router.push("/app/catalogos/roles");
    },
    async modificarPersonal() {
      await api("PUT", `usuarios/modificar/${this.selected.usuario_id}`, {
        ...this.selected,
        personal_id: sessionStorage.getItem("usuario_id"),
      })
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar el personal",
              text: "Error del servidor",
              type: "error",
            });
            this.listarPersonal();
          } else {
            this.$refs["modal-modificar"].hide();
            this.listarPersonal();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.listarPersonal();
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}
#sidebar-right {
  width: 450px;
}

.fill {
  color: #aaa;
}
.cabecera_roles {
  display: none;
}
.table_container {
  margin-top: 20px;
}
.table_container tr:hover {
  cursor: pointer;
}
#tabla_roles {
  margin-top: 10px;
}
.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}
.detalles_body {
  margin: 10px;
}
.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}
.txt_dato {
  font-weight: bold;
}
.txt_dato_resp {
  color: #424242;
}
.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.modal_form_personal {
}

</style>