<template>
  <div id="BarraAcciones" class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <BaseButton title="Guardar Cotizacion" @onCta="emitHandler('guardarCotizacion')" />
      <BaseButton title="Hacer Pedido" @onCta="emitHandler('hacerPedido')" />
      <BaseButton title="Borrar Lista" @onCta="emitHandler('borrarSeleccion')" />
      <BaseButton title="Vista Previa" @onCta="emitHandler('vistaPrevia')" />
      <BaseButton title="Condiciones de venta" @onCta="$emit('condicionesVenta')" />
    </div>
    <h6 style="color: white;" class="mr-5 mb-0">{{ user }}</h6>
  </div>
</template>

<script>
export default {
  name: "BarraAcciones",
  data() {
    return {
      user: sessionStorage.getItem('nombre'),
    }
  },
  methods: {
    emitHandler(opt) {
      this.$emit(opt);
    },
  },
};
</script>