<template>
  <div id="AgregarPreciosEspeciales">
    <section class="buscar-ofertas-form">
      <div>
        <p>Buscar por:</p>
        <b-form-select
          v-model="tipoBusquedaSelect"
          :options="tipoBusquedaOptions"
          @change="limpiarFiltros"
        />
      </div>
      <div>
        <p>Cliente:</p>
        <b-form-select v-model="clienteSelected" :options="listaUsuarios" />
      </div>
      <div>
        <p>Proveedor:</p>
        <b-form-select
          v-model="proveedorSelected"
          :options="listaProveedoresSelect"
        />
      </div>
      <div v-if="tipoBusquedaSelect === 'marca'">
        <p>Marca:</p>
        <b-form-select v-model="marcaSelected" :options="marcaOptions" />
      </div>
      <div v-if="tipoBusquedaSelect === 'codigo'">
        <p>Codigo:</p>
        <b-form-input v-model="codigo" placeholder="Codigo" />
      </div>
      <b-button @click="buscarPreciosEspeciales" class="boton-azul"
        >BUSCAR</b-button
      >
      <b-button class="boton-azul">Seleccionar Todas</b-button>
      <b-button class="boton-azul">Limpiar Seleccion</b-button>
      <b-button class="boton-rojo">ELIMINAR</b-button>
    </section>
    <section class="tipo-ofertas-form">
      <div>
        <p>Establecer oferta por:</p>
        <b-form-select
          class="tipo-oferta-select"
          v-model="tipoOfertaSelect"
          :options="tipoOfertaOptions"
        />
        <b-button class="boton-azul" @click="guardarPreciosEspeciales"
          >GUARDAR</b-button
        >
      </div>
      <div v-if="tipoOfertaSelect === 'porcentaje'">
        <p>Porcentaje de descuento:</p>
        <b-form-input v-model="porcentaje" placeholder="Porcentaje" />
      </div>
      <div v-if="tipoOfertaSelect === 'precio_especifico'">
        <p>Precio:</p>
        <div class="input-group-precios">
          <b-form-input v-model="precio_1" placeholder="Precio 1" />
          <b-form-input v-model="precio_2" placeholder="Precio 2" />
          <b-form-input v-model="precio_3" placeholder="Precio 3" />
          <b-form-input v-model="precio_4" placeholder="Precio 4" />
          <b-form-input v-model="precio_5" placeholder="Precio 5" />
        </div>
      </div>
    </section>
    <BaseTabla
      :campos="campos"
      :elementos="listaPreciosEspeciales"
      :cargando="cargando"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "../../../utils/api";
import BaseTabla from "../../../components/base/_BaseTabla.vue";
export default {
  name: "AgregarOfertas",
  props: [],
  components: { BaseTabla },
  data() {
    return {
      proveedorSelected: null,
      tipoBusuedaSelect: null,
      tipoBusuedaOptions: [],
      porcentaje: null,
      codigo: null,
      descripcion: null,
      tipoBusquedaSelect: null,
      tipoBusquedaOptions: [
        { value: null, text: "Seleccione el tipo de busqueda" },
        { value: "marca", text: "Marca" },
        { value: "codigo", text: "Codigo" },
      ],
      tipoOfertaSelect: null,
      tipoOfertaOptions: [
        { value: null, text: "Seleccione el tipo oferta" },
        { value: "porcentaje", text: "Porcentaje" },
        { value: "precio_especifico", text: "Precio especifico" },
      ],
      campos: [
        {
          key: "codigo",
          sortable: true,
          label: "Codigo",
        },
        {
          key: "subcodigo",
          sortable: true,
          label: "Subodigo",
        },
        {
          key: "descripcion",
          sortable: true,
          label: "Descripcion",
        },
        {
          key: "porcentaje",
          sortable: true,
          label: "Porcentaje",
        },
        {
          key: "precio_oferta_1",
          sortable: true,
          label: "Precio 1",
        },
        {
          key: "precio_oferta_2",
          sortable: true,
          label: "Precio 2",
        },
        {
          key: "precio_oferta_3",
          sortable: true,
          label: "Precio 3",
        },
        {
          key: "precio_oferta_4",
          sortable: true,
          label: "Precio 4",
        },
        {
          key: "precio_oferta_5",
          sortable: true,
          label: "Precio 5",
        },
      ],
      precio_1: null,
      precio_2: null,
      precio_3: null,
      precio_4: null,
      precio_5: null,
      marcaOptions: [],
      marcaSelected: null,
      listaPreciosEspeciales: [],
      listaUsuarios: [],
      clienteSelected: null,
      cargando: false,
    };
  },
  mounted() {
    this.obtenerProveedores();
    this.consultarMarcas();
    this.consultarUsuarios();
  },
  destroyed() {},
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
    }),
    async buscarPreciosEspeciales() {
      if (this.clienteSelected !== null) {
        const payload = {
          usuario_id: this.clienteSelected,
          codigo: this.codigo,
          marca_id: this.marcaSelected,
          proveedor_id: this.proveedorSelected,
        };
        await api("POST", "precios-especiales/consultar-precios", payload)
          .then((result) => {
            this.listaPreciosEspeciales = [...result[0]];
            this.cargando = false;
          })
          .catch((err) => {
            this.listaPreciosEspeciales = [];
            this.cargando = false;
          });
      }
    },
    async consultarMarcas() {
      let { marcas } = await api("GET", `productos/obtener-marcas`);
      this.marcaOptions = marcas.map((result) => {
        return { value: result.marca_id, text: result.nombre };
      });
    },
    async guardarPreciosEspeciales() {
      this.cargando = true;
      const payload = {
        descripcion: this.descripcion,
        codigo: this.codigo,
        marca_id: this.marcaSelected,
        proveedor_id: this.proveedorSelected,
        usuario_id: this.clienteSelected,
        tipo: this.tipoBusquedaSelect,
        oferta: this.tipoOfertaSelect,
        porcentaje: this.porcentaje,
        precio_1: this.precio_1,
        precio_2: this.precio_2,
        precio_3: this.precio_3,
        precio_4: this.precio_4,
        precio_5: this.precio_5,
      };
      await api("POST", "precios-especiales/crear-precios", payload).then(
        (result) => {
          this.buscarPreciosEspeciales();
          this.cargando = false;
        }
      );
    },
    async consultarUsuarios() {
      api("GET", "usuarios/consultar-usuarios").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener los usuarios",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          this.listaUsuarios = result.usuarios.map((personal) => {
            return {
              value: personal.usuario_id,
              text: `${personal.nombre} ${personal.apellido}`,
            };
          });
        }
      });
    },
    limpiarFiltros() {
      this.marcaSelected =
        this.proveedorSelected =
        this.descripcion =
        this.codigo =
          null;
    },
  },
  computed: {
    ...mapGetters(["listaProveedoresSelect", "listaProveedoresSelectMulti"]),
  },
};
</script>