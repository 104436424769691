<template>
  <div>
    <b-modal
      id="modal-agregar"
      ref="modal-agregar"
      title="Agregar Presentacion"
      scrollable
      hide-footer
    >
      <div>
        <b-row>
          <b-col>
            <label style="margin-right: 10px" for="presentacion"
              >Presentacion:
            </label>
            <b-input-group>
              <b-form-input
                v-model="presentacionNueva.presentacion"
                id="presentacion1"
                type="text"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>
                  <b-icon icon="x" />
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <b-button
        block
        style="
          margin-top: 15px;
          background-color: #355070;
          border-color: #355070;
        "
        @click="guardarPresentacion"
      >
        GUARDAR
      </b-button>
    </b-modal>
    <b-modal
      id="modal-modificar"
      ref="modal-modificar"
      title="Modificar Presentacion"
      scrollable
      hide-footer
    >
      <div>
        <b-row>
          <b-col>
            <label style="margin-right: 10px" for="presentacion"
              >Presentacion:
            </label>
            <b-form-input
              v-model="presentacionModificada.presentacion"
              id="presentacion2"
              type="text"
            ></b-form-input>
          </b-col>
        </b-row>
      </div>
      <b-button
        block
        style="
          margin-top: 15px;
          background-color: #355070;
          border-color: #355070;
        "
        @click="modificarPresentacion"
      >
        GUARDAR</b-button
      >
    </b-modal>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Presentaciones</h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-right: 10px;
          "
        >
          <b-input-group style="width: 450px; padding-left: 10px">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            ></b-form-input>
          </b-input-group>
          <div>
            <b-button
              style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              "
              @click="abrirModal"
              ><b-icon icon="plus" style="margin-right: 4px" />AGREGAR</b-button
            >
            <b-button
              style="background-color: #a5594e; border-color: #a5594e"
              disabled
              ><b-icon
                icon="trash"
                style="margin-right: 4px"
              />ELIMINAR</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container class="table_container" fluid>
          <b-table
            :items="listaPresentaciones"
            :fields="camposMarcas"
            striped
            bordered
            hover
            head-variant="light"
            :per-page="perPage"
            :busy="isBusy"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
          ></b-table>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "../../utils/api";
export default {
  data() {
    return {
      listaPresentaciones: [],
      camposMarcas: [
        {
          key: "presentacion_id",
          label: "ID",
        },
        {
          key: "presentacion",
          label: "Presentacion",
          sortable: true,
        },
      ],

      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      currentId: "",
      presentacionNueva: {
        presentacion: "",
      },
      presentacionModificada: {
        presentacion: "",
      },
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarPresentaciones() {
      api("GET", "presentaciones/consultar-todas").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener las marcas",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          this.listaPresentaciones = result.presentaciones;
        }
      });
    },
    onRowSelected(item) {
      this.presentacionModificada = item[0];
      this.$refs["modal-modificar"].show();
    },
    guardarPresentacion() {
      api("POST", "presentaciones/crear", this.presentacionNueva)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al crear la presentacion",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["modal-agregar"].hide();
            this.presentacionNueva.presentacion = "";
            this.listarPresentaciones();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    modificarPresentacion() {
      api(
        "PUT",
        `presentaciones/modificar/${this.presentacionModificada.presentacion_id}`,
        { presentacion: this.presentacionModificada.presentacion }
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar la presentacion",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["modal-modificar"].hide();
            this.listarPresentaciones();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    abrirModal() {
      this.$refs["modal-agregar"].show();
    },
  },
  mounted() {
    this.listarPresentaciones();
  },
  computed: {
    rows() {
      return this.listaPresentaciones.length;
    },
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}
#sidebar-right {
  width: 450px;
}

.fill {
  color: #aaa;
}
.cabecera_roles {
  display: none;
}
.table_container {
  margin-top: 20px;
}
.table_container tr:hover {
  cursor: pointer;
}
#tabla_roles {
  margin-top: 10px;
}
.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}
.detalles_body {
  margin: 10px;
}
.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}
.txt_dato {
  font-weight: bold;
}
.txt_dato_resp {
  color: #424242;
}
.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.modal_form_personal {
}
</style>
