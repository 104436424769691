<template>
  <div id="excel-pedidos">
    <table id="tabla-excel-pedidos">
      <tr>
        <th>INFORMACION DEL CLIENTE</th>
      </tr>
      <tr>
        <th>NUMERO DE PEDIDO: {{ pedido.codigo || "" }}</th>
        <th>CLIENTE: {{ pedido.cliente_usuario.razon_social || "" }}</th>
        <th>DOMICILIO: {{ pedido.cliente_usuario.domicilio || "" }}</th>
        <th>CIUDAD: {{ pedido.cliente_usuario.ciudad || "" }}</th>
        <th>RFC: {{ pedido.cliente_usuario.rfc || "" }}</th>
        <th>TELEFONO: {{ pedido.cliente_usuario.tel || "" }}</th>
      </tr>
      <tr>
        <th>FECHA: {{ pedido.creacion || "" }}</th>
        <th>PROVEEDOR: {{ pedido.proveedor_proveedore.nombre || "" }}</th>
        <th>{{ pedido.cliente_usuario.perfil_cliente_perfil_cliente.condiciones_venta || "" }}</th>
      </tr>
      <tr>
        <td>PRODUCTOS SOLICITADOS</td>
      </tr>
      <tr>
        <td>Codigo</td>
        <td>Descripcion</td>
        <td>Precio</td>
        <td>Cantidad</td>
        <td>Subtotal</td>
      </tr>
      <template>
        <tr v-for="(producto, index) in pedido.pedido_productos" v-bind:key="index">
          <td>{{ producto.producto.codigo }}</td>
          <td>{{ producto.producto.descripcion }}</td>
          <td>{{ `${parseFloat(producto.precio).toFixed(2)}` }}</td>
          <td>{{ producto.cantidad }}</td>
          <td>{{ `${parseFloat(producto.subtotal).toFixed(2)}` }}</td>
        </tr>
      </template>
      <tr>
        <td>Venta Grabada:</td>
        <td>{{ pedido.total | currency }}</td>
      </tr>
      <tr>
        <td>Venta Exenta:</td>
        <td>{{ pedido.ieps | currency }}</td>
      </tr>
      <tr>
        <td>Subtotal</td>
        <td>{{ pedido.subtotal | currency }}</td>
      </tr>
      <tr>
        <td>I.V.A.</td>
        <td>{{ pedido.iva | currency }}</td>
      </tr>
      <tr>
        <td>I.E.P.S.</td>
        <td>{{ pedido.ieps | currency }}</td>
      </tr>
      <tr>
        <td>Total</td>
        <td>{{ pedido.total | currency }}</td>
      </tr>
    </table>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  name: "ExcelPedidos",
  props: ['pedido'],
  computed: {
    ...mapGetters("pedidos", ["cargandoModal"]),
  },
  filters: {
    currency(value) {
      try {
        return value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch {
        return "0";
      }
    },
  },
};
</script>