import { render, staticRenderFns } from "./Presentaciones.vue?vue&type=template&id=f6a93a5a&"
import script from "./Presentaciones.vue?vue&type=script&lang=js&"
export * from "./Presentaciones.vue?vue&type=script&lang=js&"
import style0 from "./Presentaciones.vue?vue&type=style&index=0&id=f6a93a5a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports