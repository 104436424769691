<template>
  <div id="BaseTabla">
    <h2 :v-if="titulo">{{ titulo }}</h2>
    <b-table ref="tablaBase" id="base-tabla" :fields="campos" :items="elementos" :filter="filtro" :per-page="perPage"
      :busy="cargando" :current-page="currentPage" :select-mode="modo" bordered head-variant="light" selectable show-empty
      sticky-header caption-top :tbody-tr-attr="trAttribute" @row-selected="onRowSelected">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="spinner"></b-spinner>
          <strong>Cargando...</strong>
        </div>
      </template>
      <template #empty>
        <h4>Sin resultados...</h4>
      </template>
      <template #cell(precio)="data" class="totales">
        <div class="d-flex justify-content-between">
          <span>$</span>
          <span>
            {{ data.item.precio | currency }}
          </span>
        </div>
      </template>
      <template #cell(saldo)="data" class="totales">
        <div class="totales">
          <span>$</span>
          <span>
            {{ data.item.saldo | currency }}
          </span>
        </div>
      </template>
      <template #cell(status)="data" class="totales">
        <div style="display: flex; flex-direction: row; justify-content: center">
          <b-icon icon="file-earmark-pdf-fill"></b-icon>
          <b-icon icon="file-earmark-image-fill"></b-icon>
        </div>
      </template>
      <template #cell(cheque)="data" class="totales">
        <div class="totales">
          <b-icon icon="x-circle-fill"></b-icon>
        </div>
      </template>
      <template #cell(comentarios)="data">
        <b-button v-if="data.item.comentarios !== null && disableComentarios !== true" class="button-camera"
          @click="$emit('render-comentarios', data.item.comentarios)">
          <b-icon icon="chat-text"></b-icon>
        </b-button>
      </template>
      <template #cell(precioTotal)="data" class="totales">
        <div class="d-flex justify-content-between">
          <span>$</span>
          <span>
            {{ data.item.precioTotal | currency }}
          </span>
        </div>
      </template>
      <template #cell(total)="data" class="totales">
        <div class="d-flex justify-content-between">
          <span>$</span>
          <span>
            {{ data.item.total | currency }}
          </span>
        </div>
      </template>
    </b-table>
    <b-pagination v-if="this.paginacionDesactivada" v-model="currentPage" :total-rows="rows" :per-page="perPage"
      aria-controls="base-tabla" :current-page="currentPage"></b-pagination>
  </div>
</template>

<script>
export default {
  name: "BaseTabla",
  //TODO cobvertir props a objecto con default props\
  //agregar props: view only y pagination disabled.
  props: [
    "titulo",
    "campos",
    "elementos",
    "cargando",
    "filtro",
    "modo",
    "refTabla",
    "paginationDisabled",
    "disableColors",
    "disableComentarios",
  ],
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 20,
    };
  },
  methods: {
    onRowSelected(item) {
      this.$emit("row-selected", item);
    },
    seleccionarTodo() {
      this.$refs.tablaBase.selectAllRows();
    },
    limipiarSeleccion() {
      this.$refs.tablaBase.clearSelected();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
    },
    trAttribute(item, type) {
      if (item !== null && !this.disableColors)
        return {
          bgcolor: item.color,
        };
    },
  },
  filters: {
    currency(value) {
      try {
        return value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch {
        return "-";
      }
    },
  },
  computed: {
    rows() {
      return this.elementos.length;
    },
    paginacionDesactivada() {
      return !this.paginationDisabled;
    },
  },
};
</script>
<style lang="scss" scoped>
.b-table-sticky-header {
  max-height: 70vh !important;
  height: 100% !important;
  padding: 0px !important;

  #base-tabla {
    max-height: 70vh !important;
    height: 100% !important;
    text-align: center;

    tr>td:nth-of-type(3) {
      text-align: left;
    }

    tr {
      height: 20px;

      >td {
        padding: 0.3rem;
      }
    }

    >.container_tabla {
      margin: 0px;
    }
  }
}
</style>