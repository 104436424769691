<template>
  <div id="Usuarios">
    <DetallesUsuario
      :usuario_id="currentUsuarioId"
      :mostrarDetalles="mostrarDetalles"
      @detalles-cerrado="onDetallesCerrados"
    />
    <AgregarUsuario @listar-usuarios="listarUsuarios" />
    <b-container fluid>
      <BaseBackBar titulo="Usuarios" goTo="/app/catalogos" />
      <div class="barra-busqueda">
        <div class="filtros">
          <b-input-group class="input-busqueda">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Filtrar..."
            ></b-form-input>
          </b-input-group>
          <div class="filtro-usuario">
            <p>Tipo de usuario:</p>
            <b-form-select v-model="userType" :options="options" />
          </div>
        </div>
        <div class="acciones">
          <b-button class="boton-azul" @click="listarUsuarios">
            <b-icon icon="search" style="margin-right: 4px" />
            BUSCAR
          </b-button>
          <b-button class="boton-azul" v-b-modal.ModalAgregarUsuario>
            <b-icon icon="plus" style="margin-right: 4px" />
            AGREGAR
          </b-button>
        </div>
      </div>
      <BaseTabla
        :campos="campos"
        :elementos="elementos"
        :cargando="isBusy"
        :filtro="filter"
        @row-selected="onRowSelected"
      />
    </b-container>
  </div>
</template>

<script>
import BaseBackBar from "../../../../components/base/_BaseBackBar.vue";
import BaseTabla from "../../../../components/base/_BaseTabla.vue";
import DetallesUsuario from "./Detalles.vue";
import AgregarUsuario from "./Agregar.vue";
import api from "../../../../utils/api";
import moment from "moment";

export default {
  name: "usuarios",
  props: [],
  components: {
    BaseBackBar,
    BaseTabla,
    DetallesUsuario,
    AgregarUsuario,
  },
  data() {
    return {
      filter: null,
      userType: null,
      options: [
        { value: null, text: "Todos los usuarios" },
        { value: "cliente", text: "Clientes" },
        { value: "personal", text: "Personal" },
      ],
      campos: [
        {
          key: "nombre",
          sortable: true,
          label: "Nombre",
        },
        {
          key: "apellido",
          sortable: true,
          label: "Apellido",
        },
        {
          key: "email",
          sortable: true,
          label: "Correo",
        },
        {
          key: "tel",
          sortable: true,
          label: "Telefono",
        },
        {
          key: "created_at",
          sortable: true,
          label: "Antiguedad",
        },
      ],
      elementos: [],
      isBusy: true,
      currentPage: 1,
      perPage: 10,
      currentUsuarioId: null,
      mostrarDetalles: false,
    };
  },
  methods: {
    listarUsuarios() {
      this.isBusy = true;
      api(
        "GET",
        this.userType
          ? "usuarios/consultar-usuarios/" + this.userType
          : "usuarios/consultar-usuarios"
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al obtener el personal",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            result.usuarios.map((usuario) => {
              usuario.created_at = usuario.created_at
                ? moment(usuario.created_at).format("LLL")
                : "-";
            });
            this.elementos = result.usuarios;
            this.isBusy = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onRowSelected(item) {
      //abrir detalles
      //se abre pro referencia desde la tabla
      if (item.length > 0) {
        this.currentUsuarioId = item[0].usuario_id;
        this.mostrarDetalles = true;
      }
    },
    onDetallesCerrados() {
      this.mostrarDetalles = false;
      this.currentUsuarioId = null;
    },
  },
  computed: {
    rows() {
      return this.elementos.length;
    },
  },
  mounted() {
    this.listarUsuarios();
  },
  destroyed() {},
};
</script>
<style lang="scss" src="./usuarios.scss" />