<template>
  <div class="CatalogoProveedores">
    <b-modal id="modal-agregar" ref="modal-agregar" size="xl" title="Agregar Proveedor" scrollable hide-footer>
      <div class="agregar_cliente_container">
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="nombre">Nombre: </label>
              <b-form-input id="nombre" v-model="proveedorNuevo.nombre" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="nombre_corto">Nombre Corto:
              </label>
              <b-form-input id="nombre_corto" v-model="proveedorNuevo.nombre_corto" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="email">Correo: </label>
              <b-form-input id="email" v-model="proveedorNuevo.email" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="rfc">RFC: </label>
              <b-form-input id="rfc" v-model="proveedorNuevo.rfc" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="telefono_1">Telefono 1:
              </label>
              <b-form-input id="telefono_1" v-model="proveedorNuevo.tel_1" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="telefono_2">Telefono 2:
              </label>
              <b-form-input id="telefono_2" v-model="proveedorNuevo.tel_2" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="pais">Pais: </label>
              <b-form-input id="pais" v-model="proveedorNuevo.pais" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="estado">Estado: </label>
              <b-form-input id="estado" v-model="proveedorNuevo.estado" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="ciudad">Ciudad: </label>
              <b-form-input id="ciudad" v-model="proveedorNuevo.ciudad" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">Domicilio:
              </label>
              <b-form-input id="domicilio" v-model="proveedorNuevo.domicilio" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row class="comisiones-form">
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="comision">Descuento 1:
              </label>
              <b-form-input id="domicilio" v-model="comisiones.comision_1" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">Descuento 2:
              </label>
              <b-form-input id="domicilio" v-model="comisiones.comision_2" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">Descuento 3:
              </label>
              <b-form-input id="domicilio" v-model="comisiones.comision_3" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">Descuento 4:
              </label>
              <b-form-input id="domicilio" v-model="comisiones.comision_4" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">Descuento 5:
              </label>
              <b-form-input id="domicilio" v-model="comisiones.comision_5" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label style="margin-right: 10px" for="ciudad">Escoja un Color: </label>
            <div class="form__input">
              <vswatches v-model="color" show-fallback popover-y="top">
              </vswatches>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
                margin-top: 32px;
              " block @click="guardarProveedor">GUARDAR</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="modal-modificar" ref="modal-modificar" size="xl" title="Modificar Proveedor" scrollable hide-footer>
      <div class="agregar_cliente_container">
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="nombre">Nombre: </label>
              <b-form-input id="nombre" v-model="selected.nombre" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="nombre_corto">Nombre Corto:
              </label>
              <b-form-input id="rfc" v-model="selected.nombre_corto" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="email">Correo: </label>
              <b-form-input id="email" v-model="selected.email" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="rfc">RFC: </label>
              <b-form-input id="rfc" v-model="selected.rfc" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="telefono_1">Telefono 1:
              </label>
              <b-form-input id="telefono_1" v-model="selected.tel_1" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="telefono_2">Telefono 2:
              </label>
              <b-form-input id="telefono_2" v-model="selected.tel_2" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="pais">Pais: </label>
              <b-form-input id="pais" v-model="selected.pais" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="estado">Estado: </label>
              <b-form-input id="estado" v-model="selected.estado" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="ciudad">Ciudad: </label>
              <b-form-input id="ciudad" v-model="selected.ciudad" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">Domicilio:
              </label>
              <b-form-input id="comprador" v-model="selected.domicilio" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row class="comisiones-form">
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">
                Descuento 1:
              </label>
              <b-form-input id="domicilio" v-model="selected.comision_1" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">
                Descuento 2:
              </label>
              <b-form-input id="domicilio" v-model="selected.comision_2" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">
                Descuento 3:
              </label>
              <b-form-input id="domicilio" v-model="selected.comision_3" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">
                Descuento 4:
              </label>
              <b-form-input id="domicilio" v-model="selected.comision_4" type="text"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="domicilio">
                Descuento 5:
              </label>
              <b-form-input id="domicilio" v-model="selected.comision_5" type="text"></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label style="margin-right: 10px" for="ciudad">Escoja un Color: </label>
            <div class="form__input">
              <vswatches v-model="selected.color" show-fallback popover-y="top">
              </vswatches>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top: 20px">
          <b-col>
            <b-button style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              " block @click="modificarProveedor">GUARDAR</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="modal-condiciones" size="xl" :title="`Condiciones de venta - ${selected.nombre}`" scrollable
      hide-footer>
      <section>
        <article style="display: flex; justify-content: space-between;">
          <b-form-input v-model="nuevaCondicion.Condicion" placeholder="Título de la condición"
            class="w-75"></b-form-input>
          <article>
            <b-button variant="outline-primary" class="mr-2"
              @click="() => nuevaCondicion.condiciones.push({ Condicion: '' })">
              <b-icon icon="plus"></b-icon>
            </b-button>

            <b-button variant="primary" class="mr-2" @click="guardarCondicion" :disabled="loading">
              <b-spinner v-if="loading"></b-spinner>
              <template v-else>
                <b-icon icon="upload" /> Guardar
              </template>
            </b-button>

            <b-button v-if="!esNueva" variant="danger" @click="resetCondiciones">Cancelar</b-button>
          </article>
        </article>

        <article class="w-60 mt-3">
          <ul>
            <li v-for="(condicion, index) of nuevaCondicion.condiciones" :key="index" class="my-1">
              <article class="d-flex">
                <b-textarea v-model="condicion.Condicion"></b-textarea>
                <article style="align-self: center;">
                  <b-button class="ml-2" variant="outline-danger"
                    @click="esNueva ? nuevaCondicion.condiciones.splice(index, 1) : eliminarCondicion(condicion.CondicionID)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </article>
              </article>
            </li>
          </ul>
        </article>
      </section>

      <section>
        <article>
          <b-table-simple small hover bordered>
            <b-thead>
              <b-tr>
                <b-th>Condición</b-th>
                <b-th>Título</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="condicion of condiciones">
                <b-tr v-for="(atributo, index) of condicion.condiciones" :key="index">
                  <b-td>{{ atributo.Condicion }}</b-td>
                  <b-td>{{ condicion.Condicion }}</b-td>
                  <b-td style="display: flex; align-items: center;">
                    <b-icon class="mr-2" icon="trash" style="cursor: pointer;"
                      @click="eliminarCondicion(condicion.condiciones.length === 1 ? condicion.CondicionID : atributo.CondicionID);" />
                    <b-icon class="mr-2" icon="pencil" style="cursor: pointer;" @click="editarCondicion(condicion)" />
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </article>
      </section>
    </b-modal>

    <b-sidebar id="sidebar-right" ref="sidebar-right" backdrop no-header title="Detalles" right bg-variant="white"
      shadow>
      <div class="detalles_header">
        <b-icon id="x-icon" v-b-toggle.sidebar-right icon="x"></b-icon>
        <h3>Detalles Proveedor</h3>
      </div>
      <div class="detalles_body">
        <h4 class="fill">
          {{ this.selected.nombre }}
        </h4>

        <div class="dato_item">
          <p class="txt_dato">ID:</p>
          <p class="txt_dato_resp">
            {{ this.selected.proveedor_id }}
          </p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Nombre:</p>
          <p class="txt_dato_resp">
            {{ this.selected.nombre }}
          </p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">RFC:</p>
          <p class="txt_dato_resp">{{ this.selected.rfc }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Nombre Corto:</p>
          <p class="txt_dato_resp">
            {{ this.selected.nombre_corto }}
          </p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Correo:</p>
          <p class="txt_dato_resp">{{ this.selected.email }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Teléfono 1:</p>
          <p class="txt_dato_resp">{{ this.selected.tel_1 || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Teléfono 2:</p>
          <p class="txt_dato_resp">{{ this.selected.tel_2 || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Pais:</p>
          <p class="txt_dato_resp">{{ this.selected.pais || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Estado:</p>
          <p class="txt_dato_resp">{{ this.selected.estado || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Ciudad:</p>
          <p class="txt_dato_resp">{{ this.selected.ciudad || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Descuento 1:</p>
          <p class="txt_dato_resp">{{ this.selected.comision_1 || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Descuento 2:</p>
          <p class="txt_dato_resp">{{ this.selected.comision_2 || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Descuento 3:</p>
          <p class="txt_dato_resp">{{ this.selected.comision_3 || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Descuento 4:</p>
          <p class="txt_dato_resp">{{ this.selected.comision_4 || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Descuento 5:</p>
          <p class="txt_dato_resp">{{ this.selected.comision_5 || "-" }}</p>
        </div>

        <div class="dato_item">
          <p class="txt_dato">Color:</p>
          <vswatches v-model="this.selected.color" :swatches="swatches" popover-x="left" disabled="true">
          </vswatches>
        </div>

        <b-button block style="background-color: #355070; margin-top: 10px" v-b-toggle.sidebar-right
          v-b-modal.modal-modificar>
          MODIFICAR
        </b-button>
        <b-button block style="background-color: #355070; margin-top: 10px" v-b-toggle.sidebar-right
          @click="gotoTransporteProveedor">
          TRANSPORTES
        </b-button>
        <b-button block style="background-color: #355070; margin-top: 10px" v-b-toggle.sidebar-right
          @click="abrirCondiciones">
          CONDICIONES DE VENTA
        </b-button>
        <b-button block style="background-color: #a5594e; border-color: #a5594e" @click="suspenderCliente" disabled>
          SUSPENDER
        </b-button>
      </div>
    </b-sidebar>

    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon id="back_icon" icon="chevron-left" style="margin-bottom: 5px" @click="regresar"></b-icon>
          <h2 style="margin-left: 10px">Proveedores</h2>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-right: 10px;
            width: 100%;
          ">
          <b-input-group style="width: 450px; padding-left: 10px">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon></b-input-group-text>
            </template>
            <b-form-input v-model="filter" type="search" placeholder="buscar..."></b-form-input>
          </b-input-group>
          <div>
            <b-button style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              " @click="abrirModal">
              <b-icon icon="plus" style="margin-right: 4px" />AGREGAR</b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-container class="table_container" fluid>
          <b-table :items="items" :fields="fields" striped bordered hover head-variant="light" :per-page="perPage"
            :busy="isBusy" :filter="filter" :filter-included-fields="filterOn" :current-page="currentPage" selectable
            select-mode="single" @row-selected="onRowSelected" v-b-toggle.sidebar-right>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
            :current-page="currentPage"></b-pagination>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import api from "../../../utils/api";
import Notification from "../../../utils/notification";
import { axiosInstance } from '../../../utils/axiosInstance';

export default {
  data() {
    return {
      fields: [
        {
          key: "nombre",
          sortable: true,
          label: "Nombre",
        },
        {
          key: "nombre_corto",
          sortable: true,
          label: "Nombre Corto",
        },
        {
          key: "ciudad",
          sortable: true,
          label: "Ciudad",
        },
        {
          key: "tel_1",
          sortable: true,
          label: "Telefono",
        },
      ],
      color: '#A463BF',
      swatches: [
        "#1FBC9C",
        "#1CA085",
        "#2ECC70",
        "#27AF60",
        "#3398DB",
        "#2980B9",
        "#A463BF",
        "#8E43AD",
        "#3D556E",
        "#222F3D",
        "#FA8072"
      ],
      currentPage: 1,
      perPage: 10,
      items: [],
      itemsAux: [],
      isBusy: false,
      filter: null,
      filterOn: [],
      selected: {
        nombre: null,
        email: null,
        password: null,
        rfc: null,
        razon_social: null,
        telefono_1: null,
        telefono_2: null,
        telefono_3: null,
        telefono_avisos: null,
        pais: null,
        estado: null,
        ciudad: null,
        email_cobro: null,
        alerta_cobro: null,
        comprador: null,
        aniversario: null,
        cumpleanos: null,
        color: null
      },
      condiciones: [],
      nuevaCondicion: {
        Condicion: '',
        condiciones: [],
      },
      esNueva: true,
      comisiones: {},
      proveedorNuevo: {},
      proveedor_id: '',
      loading: false,
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarProveedores() {
      this.isBusy = !this.isBusy;
      api("GET", "proveedores/obtener-proveedores").then((result) => {
        if (result.errorCode) {
          Notification(
            "error",
            "Consulta Proveedores",
            "Error al consultar el catalogo de proveedores"
          );
        } else {
          this.items = result.proveedores;
          this.itemsAux = result.proveedores;
          this.isBusy = !this.isBusy;
        }
      });
    },
    async onRowSelected(item) {
      try {
        this.selected = await api(
          "GET",
          `proveedores/consultar-proveedor/${item[0].proveedor_id}`
        );
        this.proveedor_id = item[0].proveedor_id;
        this.transformarDescuentos(true);
      } catch (error) {
        //TODO handle error: probablemente se vaya en la modularizacion...
      }
    },
    parseDate(date) {
      return moment(date || "00").format("DD-MM-YYYY");
    },
    suspenderCliente() {
      this.isBusy = !this.isBusy;
      api("GET", `cliente/suspender/${this.selected.cliente_id}`)
        .then(async (result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al suspender al cliente",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["sidebar-right"].hide();
            await this.listarProveedores();
            this.isBusy = !this.isBusy;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    abrirModal() {
      this.$refs["modal-agregar"].show();
    },
    cerrarModal() {
      this.$refs["modal-agregar"].hide();
    },
    abrirModalPreciosProveedores() {
      this.$refs["modal-precios-proveedores"].show();
    },
    cerrarModalPreciosProveedores() {
      this.$refs["modal-precios-proveedores"].hide();
    },
    guardarProveedor() {
      this.proveedorNuevo.color = this.color;
      this.proveedorNuevo.comisiones = { ...this.comisiones };
      this.proveedorNuevo.comisiones.comision_1 = (1 - (parseFloat(this.proveedorNuevo.comisiones.comision_1) / 100)).toFixed(2);
      this.proveedorNuevo.comisiones.comision_2 = (1 - (parseFloat(this.proveedorNuevo.comisiones.comision_2) / 100)).toFixed(2);
      this.proveedorNuevo.comisiones.comision_3 = (1 - (parseFloat(this.proveedorNuevo.comisiones.comision_3) / 100)).toFixed(2);
      this.proveedorNuevo.comisiones.comision_4 = (1 - (parseFloat(this.proveedorNuevo.comisiones.comision_4) / 100)).toFixed(2);
      this.proveedorNuevo.comisiones.comision_5 = (1 - (parseFloat(this.proveedorNuevo.comisiones.comision_5) / 100)).toFixed(2);

      api("POST", "proveedores/guardar-proveedor", this.proveedorNuevo)
        .then(async (result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al crear el proveedor",
              text: "Error del servidor",
              type: "error",
            });
            this.listarProveedores();
          } else {
            this.listarProveedores();
            this.isBusy != this.isBusy;
            this.$refs["modal-agregar"].hide();
            this.proveedorNuevo = {}
          }
        })
    },
    modificarProveedor() {
      this.transformarDescuentos(false);
      api(
        "PUT",
        `proveedores/modificar-proveedor/${this.selected.proveedor_id}`,
        this.selected
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar el cliente",
              text: "ARGUMENTOS INVALIDOS O FALTANTES",
              type: "error",
            });
            this.listarProveedores();
          } else {
            this.$notify({
              group: "foo",
              title: "Cliente Modificado",
              text: "Cliente modificado exitosamente",
              type: "success",
            });
            this.$refs["modal-modificar"].hide();
            this.listarProveedores();
          }
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            title: "Error al modificar el cliente",
            text: "ERROR DEL SERVIDOR",
            type: "error",
          });
        })

      this.transformarDescuentos(true);
    },
    gotoTransporteProveedor() {
      this.$router.push(
        `/app/catalogos/transporte-proveedor/${this.selected.proveedor_id}`
      );
    },
    transformarDescuentos(nuevoFormato) {
      if (nuevoFormato) {
        this.selected.comision_1 = ((1 - this.selected.comision_1) * 100).toFixed(0);
        this.selected.comision_2 = ((1 - this.selected.comision_2) * 100).toFixed(0);
        this.selected.comision_3 = ((1 - this.selected.comision_3) * 100).toFixed(0);
        this.selected.comision_4 = ((1 - this.selected.comision_4) * 100).toFixed(0);
        this.selected.comision_5 = ((1 - this.selected.comision_5) * 100).toFixed(0);
      }
      else {
        this.selected.comision_1 = (1 - (parseFloat(this.selected.comision_1) / 100)).toFixed(2);
        this.selected.comision_2 = (1 - (parseFloat(this.selected.comision_2) / 100)).toFixed(2);
        this.selected.comision_3 = (1 - (parseFloat(this.selected.comision_3) / 100)).toFixed(2);
        this.selected.comision_4 = (1 - (parseFloat(this.selected.comision_4) / 100)).toFixed(2);
        this.selected.comision_5 = (1 - (parseFloat(this.selected.comision_5) / 100)).toFixed(2);
      }
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    async getCondiciones() {
      this.loading = true;
      try {
        const { data } = await axiosInstance.get(`proveedores/condiciones/${this.proveedor_id}`,
          {
            headers: {
              authorization: localStorage.getItem('token')
            }
          });
        this.condiciones = data;
      } catch (error) {
        this.makeToast('Condiciones del proveedor', 'Hubo un error al obtener las condiciones del proveedor', 'danger', 3000);
        throw '';
      } finally {
        this.loading = false;
      }
    },
    resetCondiciones() {
      this.nuevaCondicion = {
        Condicion: '',
        condiciones: [],
      };

      this.esNueva = true;
    },
    async abrirCondiciones() {
      this.getCondiciones()
        .then(() => {
          this.resetCondiciones();
          this.$bvModal.show('modal-condiciones');
        })
    },
    async guardarCondicion() {
      if (!this.nuevaCondicion.Condicion) {
        this.makeToast('Error', 'El título de la condición no puede estar vacío.', 'warning', 3000);
        return;
      }

      this.nuevaCondicion.condiciones = this.nuevaCondicion.condiciones.filter(c => c.Condicion);

      if (!this.nuevaCondicion.condiciones.length) {
        this.makeToast('Error', 'Debe de asignar al menos una condición al título.', 'warning', 3000);
        return;
      }

      this.loading = true;
      try {
        if (this.esNueva) {
          await axiosInstance.post(`proveedores/condiciones/${this.proveedor_id}`,
            {
              condicion: this.nuevaCondicion
            });

          this.makeToast('Condiciones', 'Condiciones creadas con éxito', 'success', 3000);
        }
        else {
          await axiosInstance.put(`proveedores/condiciones/${this.proveedor_id}`,
            {
              condicion: this.nuevaCondicion
            });
          this.makeToast('Condiciones', 'Condiciones actualizadas con éxito', 'success', 3000);
        }
      } catch (error) {
        this.makeToast('Error', 'Ocurrió un error al guardar la condición.', 'danger', 3000);
      } finally {
        await this.getCondiciones();
        this.resetCondiciones();
        this.loading = false;
      }
    },
    async eliminarCondicion(condicionID) {
      this.loading = true;
      try {
        await axiosInstance.delete(`proveedores/condiciones/${condicionID}`,
          {
            headers: {
              authorization: localStorage.getItem('token')
            }
          });

        this.makeToast('Eliminada', 'Condición eliminada con éxito.', 'success', 3000);
      } catch (error) {
        this.makeToast('Error', 'Hubo un error al eliminar la condición.', 'danger', 3000);
      } finally {
        await this.getCondiciones();
        this.resetCondiciones();
        this.loading = false;
      }
    },
    editarCondicion(condicion) {
      this.esNueva = false;
      this.nuevaCondicion = { ...condicion, condiciones: condicion.condiciones.map(c => ({ ...c })) };
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.listarProveedores();
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}

#sidebar-right {
  width: 450px;
}

.fill {
  color: #aaa;
}

.cabecera_roles {
  display: none;
}

.table_container {
  margin-top: 20px;
}

.table_container tr:hover {
  cursor: pointer;
}

#tabla_roles {
  margin-top: 10px;
}

.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.detalles_body {
  margin: 10px;
}

.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}

.txt_dato {
  font-weight: bold;
}

.txt_dato_resp {
  color: #424242;
}

.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.comisiones-form {
  margin-top: 10px;
}
</style>
