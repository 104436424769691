<template>
  <div id="pdf-cotizaciones">
    <h1>INFORMACION DEL CLIENTE</h1>
    <section class="pdf-header">
      <div>
        <div class="pdf-text-item">
          <p>NUMERO DE COTIZACIÓN:</p>
          <span>{{ cotizacion.codigo | text }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CLIENTE:</p>
          <span>{{ cotizacion.nombre_cliente | text }}</span>
        </div>
        <div class="pdf-text-item">
          <p>DOMICILIO:</p>
          <span>{{ cotizacion.domicilio | text }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CIUDAD:</p>
          <span>{{ cotizacion.ciudad || cotizacion.ciudadCliente }}</span>
        </div>
        <div class="pdf-text-item">
          <p>RFC:</p>
          <span>{{ cotizacion.rfc | text }}</span>
        </div>
        <div class="pdf-text-item">
          <p>TELEFONO:</p>
          <span>{{ cotizacion.tel || cotizacion.tel_2 || cotizacion.tel_3 }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CÓDIGO POSTAL:</p>
          <span>{{ cotizacion.codigo_postal }}</span>
        </div>
      </div>
      <div>
        <div class="pdf-text-item">
          <p>CONDICIONES DE VENTA:</p>
          <span>{{ cotizacion.condiciones_venta }}</span>
        </div>
        <div class="pdf-text-item">
          <p>PROVEEDOR:</p>
          <span>{{ cotizacion.nombre_proveedor | text }}</span>
        </div>
        <div class="pdf-text-item">
          <p>FECHA:</p>
          <span>{{ cotizacion.creacion | text }}</span>
        </div>
        <div class="pdf-text-item">
          <p>VIGENCIA:</p>
          <span>8 dias</span>
        </div>
        <div class="pdf-text-item">
          <p>TRANSPORTE:</p>
          <span>{{ cotizacion.transporte }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CONVENIO:</p>
          <span>{{ cotizacion.convenio }}</span>
        </div>
      </div>

    </section>
    <h1 class="text-center">PRODUCTOS COTIZADOS</h1>
    <section class="pdf-tabla px-2">
      <b-table-simple class="px-2" bordered small>
        <thead>
          <tr>
            <th class="text-center">Partida</th>
            <th class="text-center">Codigo</th>
            <th class="text-center">Descripcion</th>
            <th class="text-center">Cantidad</th>
            <th class="text-center">Precio</th>
            <th class="text-center">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(producto, i) in cotizacion.productos" :key="i">
            <td class="text-center">{{ producto.partida }}</td>
            <td class="text-center">{{ producto.codigo }}</td>
            <td>{{ producto.descripcion }}</td>
            <td class="text-center">{{ producto.cantidad }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <span>
                  $
                </span>
                <span>
                  {{ `${parseFloat(producto.precio).toFixed(2)}` }}
                </span>
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-between">
                <span>
                  $
                </span>
                <span>
                  {{ `${parseFloat(producto.subtotal).toFixed(2)}` }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </b-table-simple>
    </section>
    <footer class="mt-auto">
      <div class="observaciones">
        <h3>Observaciones:</h3>
        <p class="px-1">{{ cotizacion.comentarios || "" }}</p>
      </div>
      <div class="totales">
        <div class="total-row">
          <p>Venta Grabada.:</p>
          <p class="d-flex justify-space-between">
            <span>$</span>
            {{ cotizacion.total | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>Venta Exenta.:</p>
          <p>
            <span>$</span>
            {{ cotizacion.ieps | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>Subtotal:</p>
          <p>
            <span>$</span>
            {{ cotizacion.subtotal | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>I.V.A.:</p>
          <p>
            <span>$</span>
            {{ cotizacion.iva | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>I.E.P.S.:</p>
          <p>
            <span>$</span>
            {{ cotizacion.ieps | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>Total:</p>
          <p>
            <span>$</span>
            {{ cotizacion.total | currency }}
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PdfCotizaciones",
  props: ['cotizacion'],
  filters: {
    currency(value) {
      try {
        return value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch {
        return "0";
      }
    },
    text(value) {
      if (value === "" || value === null || value === undefined) {
        return " - ";
      }
      return value;
    },
  },
};
</script>