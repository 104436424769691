<template>
  <div>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Catálogos</h2>
        </div></b-col
      >
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <div class="catalogos_wrap">
            <b-card @click="goto('/clientes')">
              <b-card-text>
                <b-icon class="icon_card" icon="people-fill"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Clientes</small>
              </template>
            </b-card>
            <b-card @click="goto('/personal')">
              <b-card-text>
                <b-icon class="icon_card" icon="people"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Personal</small>
              </template>
            </b-card>
            <b-card @click="goto('/productos')">
              <b-card-text>
                <b-icon class="icon_card" icon="basket2-fill"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Productos </small>
              </template>
            </b-card>
            <b-card @click="goto('/proveedores')">
              <b-card-text>
                <b-icon class="icon_card" icon="cart3"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Proveedores</small>
              </template>
            </b-card>
            <b-card @click="goto('/formas-pago')">
              <b-card-text>
                <b-icon class="icon_card" icon="cash-stack"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Formas de Pago</small>
              </template>
            </b-card>
            <b-card @click="goto('/uso-cfdi')">
              <b-card-text>
                <b-icon class="icon_card" icon="file-earmark-binary"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Uso de CFDI</small>
              </template>
            </b-card>
            <b-card @click="goto('/marcas')">
              <b-card-text>
                <b-icon class="icon_card" icon="bag"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Marcas</small>
              </template>
            </b-card>
            <b-card @click="goto('/presentaciones')">
              <b-card-text>
                <b-icon class="icon_card" icon="inboxes"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Presentaciones</small>
              </template>
            </b-card>
            <b-card @click="goto('/transportes')">
              <b-card-text>
                <b-icon class="icon_card" icon="truck"></b-icon>
              </b-card-text>
              <template #footer>
                <small>Transportes</small>
              </template>
            </b-card>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  methods: {
    regresar() {
      this.$router.push("/app/");
    },
    goto(path) {
      this.$router.push(`/app/catalogos${path}`);
    },
  },
};
</script>
<style>
.catalogos_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card-footer {
  text-align: center;
}
.icon_card {
  font-size: 125px;
}
.card {
  margin-top: 15px;
  margin: 25px;
}
.card:hover {
  margin-top: 15px;
  cursor: pointer;
}
#back_icon:hover {
  cursor: pointer;
}
</style>
