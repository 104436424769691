<template>
  <b-sidebar
    id="DetallesUsuario"
    ref="DetallesUsuario"
    backdrop
    no-header
    title="Detalles"
    right
    bg-variant="white"
    shadow
    class="detalles-sidebar"
    :visible="mostrarDetalles"
    @hidden="onHidden"
  >
    <div class="detalles_header">
      <b-icon id="x-icon" v-b-toggle.sidebar-right icon="x"></b-icon>
      <h3>Detalles Usuario</h3>
    </div>
    <div class="detalles_body">
      <b-row>
        <b-col>
          <b-tabs style="margin-top: 5px; height: 100%">
            <b-tab title="Datos">
              <div class="dato_item">
                <p class="txt_dato">ID:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.usuario_id }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Nombre:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.nombre }}
                  {{ this.currentUsuario.apellido }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Correo:</p>
                <p class="txt_dato_resp">{{ this.currentUsuario.email }}</p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Teléfono:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.tel || "-" }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Creacion:</p>
                <p class="txt_dato_resp">
                  {{ this.parseDate(this.currentUsuario.created_at) || "-" }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Domicilio:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.domicilio || "-" }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Celular:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.celular || "-" }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">CURP:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.curp || "-" }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Seguro Social:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.no_seguro || "-" }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Cumpleaños:</p>
                <p class="txt_dato_resp">
                  {{ this.parseDate(this.currentUsuario.nacimiento) || "-" }}
                </p>
              </div>
              <div class="dato_item">
                <p class="txt_dato">Email Personal:</p>
                <p class="txt_dato_resp">
                  {{ this.currentUsuario.email_personal || "-" }}
                </p>
              </div>
              <template v-if="currentUsuario.esCliente">
                <div class="dato_item">
                  <p class="txt_dato">Razon Social:</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.razon_social || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">RFC:</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.rfc || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">ID Original:</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.id_original || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Ciudad:</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.ciudad || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Celular 2:</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.tel_2 || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Celular 3:</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.tel_3 || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Comprador :</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.comprador || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Cumpleaños Comprador:</p>
                  <p class="txt_dato_resp">
                    {{
                      this.parseDate(
                        this.currentUsuario.cumpleanos_comprador
                      ) || "-"
                    }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Condiciones :</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.condiciones || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Clave :</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.clave || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Email Cobro :</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.cobro_email || "-" }}
                  </p>
                </div>
                <div class="dato_item">
                  <p class="txt_dato">Cobro Dias :</p>
                  <p class="txt_dato_resp">
                    {{ this.currentUsuario.cobro_dias || "-" }}
                  </p>
                </div>
              </template>
              <b-button
                block
                style="background-color: #355070; margin-top: 10px"
                v-b-toggle.sidebar-right
                v-b-modal.modal-modificar
                >MODIFICAR</b-button
              >
            </b-tab>
            <b-tab
              style="margin-top: 5px; max-height: 100%"
              id=""
              title="Sesiones"
            >
              <div class="roles_container">
                <b-table
                  bordered
                  id="tabla_roles"
                  thead-class="cabecera_roles"
                  :items="itemsSesiones"
                  :empty-text="'Sin sesiones registradas'"
                  show-empty
                >
                </b-table>
              </div>
            </b-tab>
            <b-tab
              style="margin-top: 5px; max-height: 100%"
              id=""
              title="Proveedores"
              v-if="this.currentUsuario.esCliente"
            >
              <b-button
                style="
                  width: 100%;
                  background-color: #355070;
                  border-color: #355070;
                "
                @click="goToProveedoresAsignados"
                ><b-icon icon="plus" style="margin-right: 4px" />Administrar
                proveedores</b-button
              >
              <b-table
                bordered
                id="tabla_proveedores"
                :items="itemsProveedores"
                :fields="proveedoresFields"
                :empty-text="'Sin proveedores asignados'"
                show-empty
              ></b-table>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </b-sidebar>
</template>
<script>
import moment from "moment";
import api from "../../../../utils/api";
import Template from "../../../../components/base/template.vue";

export default {
  name: "DetallesUsuario",
  props: ["usuario_id", "mostrarDetalles"],
  components: { Template },
  data() {
    return {
      currentUsuario: {},
      itemsProveedores: [],
      itemsSesiones: [],
      proveedoresFields: [
        {
          key: "nombreProveedor",
          label: "Proveedores autorizados",
          sortable: true,
        },
      ],
    };
  },
  mounted() {},
  destroyed() {},
  methods: {
    parseDate(date) {
      return moment(date || "00").format("DD-MM-YYYY");
    },
    goToProveedoresAsignados() {},
    getProveedores(id) {
      api("GET", `cliente-proveedor/consultar/${id}`)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al consultar los proveedores del cliente",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.itemsProveedores = result.proveedoresAsignados;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSesiones(id) {
      api("GET", `sesiones/obtener-sesiones-usuario/${id}`)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al roles del personal",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            let itemArray = [];
            result.sesiones.map((sesion) =>
              itemArray.push({
                fecha: moment(sesion.fecha).format("DD/MM/YYYY hh:mm:ss"),
              })
            );
            this.itemsSesiones = itemArray.reverse();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    consultarUsuario(id) {
      api("GET", `usuarios/consultar${id}`)
        .then((result) => {
          this.currentUsuario = { ...result.usuario };
          if (this.currentUsuario.esCliente) {
            this.getProveedores(id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onHidden() {
      this.$emit("detalles-cerrado");
    },
  },
  computed: {},
  watch: {
    usuario_id: function (val) {
      if (val) {
        this.getSesiones(val);
        this.consultarUsuario(val);
      }
    },
  },
};
</script>
<style lang="scss">
#DetallesUsuario {
  width: 550px;
  h3 {
    padding-right: 20px;
  }
  .tab-content {
    padding-top: 10px;
  }
  .dato_item {
    margin-top: 0px;
  }
}
</style>