<template>
  <div class="AdministradorCms">
    <h1>Administrador CMS</h1>
    <section class="carousel">
      <h3>Carrusel</h3>
      <b-table
        head-variant="light"
        selectable
        select-mode="single"
        striped
        hover
        :items="componentesCarousel"
        @row-selected="onRowSelected"
      >
        <template #cell(imagen)="data">
          <img
            class="cms-carousel-images"
            :src="normalizeRoute(data.value)"
            alt=""
          />
        </template>
      </b-table>
    </section>
    <section class="product-cards">
      <h3>Lista Marcas</h3>
      <b-table
        head-variant="light"
        selectable
        select-mode="single"
        striped
        hover
        :items="componentesMarcasList"
        @row-selected="onRowSelected"
      >
        <template #cell(imagen)="data">
          <img
            class="cms-carousel-images"
            :src="normalizeRoute(data.value)"
            alt=""
          />
        </template>
      </b-table>
    </section>
    <section class="marcas-list">
      <h3>Lista Productos</h3>
      <b-table
        head-variant="light"
        selectable
        select-mode="single"
        striped
        hover
        :items="componentesProductoCard"
        @row-selected="onRowSelected"
      >
        <template #cell(imagen)="data">
          <img
            class="cms-carousel-images"
            :src="normalizeRoute(data.value)"
            alt=""
          />
        </template>
      </b-table>
    </section>
    <b-modal id="bv-modal-example" hide-footer>
      <img
        class="cms-carousel-images"
        :src="normalizeRoute(currentComponent.imagen)"
        alt=""
      />
      <div class="d-block">
        <span>Imagen</span>
        <b-form-file
          class="input-imagen"
          v-model="image"
          :state="Boolean(image)"
          placeholder="Eliga una nueva imagen o arrastre aqui..."
          drop-placeholder="Arrastre aqui..."
        ></b-form-file>
        <b-form-group
          id="input-titulo"
          label="Titulo:"
          label-for="input-titulo"
        >
          <b-form-input
            id="input-titulo"
            v-model="currentComponent.titulo"
            type="text"
            placeholder="Titulo..."
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-subtitulo"
          label="Subtitulo:"
          label-for="input-subtitulo"
        >
          <b-form-input
            id="input-subtitulo"
            v-model="currentComponent.subtitulo"
            type="text"
            placeholder="Subtitulo..."
            required
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button class="boton-guardar" block @click="guardar">Guardar</b-button>
      <b-button class="boton-cerrar" block @click="closeModal">Cerrar</b-button>
    </b-modal>
  </div>
</template>
<script>
import api from "../../../utils/api";

export default {
  data() {
    return {
      componentesCarousel: [],
      componentesProductoCard: [],
      componentesMarcasList: [],
      currentComponent: {},
      image: null,
    };
  },
  methods: {
    onRowSelected(item) {
      if (item.length !== 0) {
        this.currentComponent = item[0];
        this.openModal();
      }
    },
    normalizeRoute(img) {
      return `${process.env.VUE_APP_JPR_API_URL}cms-component/obtener-imagen/${img}`;
    },
    async inicializarCms() {
      try {
        let componentes = await api("GET", "cms-component/obtener-componentes");
        this.componentesCarousel = componentes.filter(
          (componente) => componente.seccion === "carousel"
        );
        this.componentesProductoCard = componentes.filter(
          (componente) => componente.seccion === "card-group"
        );
        this.componentesMarcasList = componentes.filter(
          (componente) => componente.seccion === "marcas-list"
        );
      } catch (error) {
        console.log(error);
      }
    },
    openModal() {
      this.$bvModal.show("bv-modal-example");
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-example");
    },
    async guardar() {
      await this.modificarComponente();
      if (this.image) {
        await this.modificarImagenComponente();
      }
      await this.inicializarCms();
      this.closeModal();
    },
    modificarImagenComponente() {
      const formData = new FormData();
      formData.append("image", this.image);
      api(
        "PUT",
        `cms-component/reemplazar-imagen/${this.currentComponent.componente_cms_id}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
        }
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al guardar la imagen",
              text: "No has subido ningun archivo o usaste un archivo incorrecto",
              type: "error",
            });
          } else if (result.affectedRows === 1) {
            this.$notify({
              group: "foo",
              title: "Imagen guardada",
              text: "",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            group: "foo",
            title: "Error al guardar la imagen",
            text: "Error del servidor",
            type: "error",
          });
        });
    },
    modificarComponente() {
      api(
        "PUT",
        `cms/modificar-componente/${this.currentComponent.componente_cms_id}`,
        this.currentComponent
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar el componente",
              text: "ARGUMENTOS INVALIDOS O FALTANTES",
              type: "error",
            });
            this.inicializarCms();
          } else {
            this.$notify({
              group: "foo",
              title: "Componente Modificado",
              text: "Componente modificado exitosamente",
              type: "success",
            });
            this.closeModal();
            this.inicializarCms();
          }
        })
        .catch((err) => {
          this.$notify({
            group: "foo",
            title: "Error al modificar el cliente",
            text: "ERROR DEL SERVIDOR",
            type: "error",
          });
          console.log(err);
          this.inicializarCms();
        });
    },
  },
  mounted() {
    this.inicializarCms();
  },
};
</script>
<style>
.AdministradorCms {
  padding: 20px;
}
section {
  padding: 10px 0;
}
.cms-carousel-images {
  max-height: 100px;
}
.boton-guardar {
  background-color: #355070 !important;
  border-color: #355070 !important;
}
.input-imagen {
  margin-bottom: 10px;
}
</style>