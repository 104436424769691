//Lista de productos para ordenes.

<template>
  <div class="ListaProductos">
    <BaseVisualizadorFotos ref="visualizadorFotos" />

    <b-modal id="modal-historico" ref="modal-historico" size="xl" centered title="Histórico de compras" hide-footer>
      <b-table hover sticky-header head-variant="light" :items="itemsHistorico"></b-table>
    </b-modal>

    <div class="wrap">
      <section class="tabla-productos">
        <b-overlay :show="isBusy" rounded="sm">
          <b-table small hover style="font-size: 11px;" bordered sticky-header="400px" :fields="cabeceros"
            :items="items" tbody-tr-class="text-center align-middle" head-variant="light" ref="tabla-productos"
            selectable select-mode="single">
            <template #head(codigo)="data">
              <span style="display: block; cursor: pointer;" @click="buscarProductos(filtro ,'codigo')">{{ data.label }}</span>
            </template>

            <template #head(descripcion)="data">
              <span style="display: block; cursor: pointer;" @click="buscarProductos(filtro ,'descripcion')">{{ data.label }}</span>
            </template>

            <template #head(proveedor)="data">
              <span style="display: block; cursor: pointer;" @click="buscarProductos(filtro ,'proveedor')">{{ data.label }}</span>
            </template>

            <template #head(empaque)="data">
              <span style="display: block; cursor: pointer;" @click="buscarProductos(filtro ,'empaque')">{{ data.label }}</span>
            </template>

            <template #head(venta_min)="data">
              <span style="display: block; cursor: pointer;" @click="buscarProductos(filtro ,'ventaMin')">{{ data.label }}</span>
            </template>

            <template #head(precio)="data">
              <span style="display: block; cursor: pointer;" @click="buscarProductos(filtro ,'precio')">{{ data.label }}</span>
            </template>
            
            <template #cell(descripcion)="data">
              <div class="d-flex justify-content-between">
                <span>
                  {{ data.item.descripcion }}
                </span>
                <div class="colours-box">
                  <div class="colours-item" style="background-color: #ff474e;" v-if="data.item.tieneOferta">
                  </div>
                  <div class="colours-item" style="background-color: #b0d9ff;"
                    v-if="diffDays(data.item.fecha_alta) <= 30">
                  </div>
                  <div class="colours-item" style="background-color: #aad576;"
                    v-if="diffDays(data.item.precio.ultima_modificacion) <= 30">
                  </div>
                  <div class="colours-item" style="background-color: #ffd670;" v-if="data.item.sobrepedido">
                  </div>
                </div>
              </div>
            </template>

            <template #cell(descto)="data">
              {{ data.item.porcentajeOferta ? `${data.item.porcentajeOferta}%` : '' }}
            </template>

            <template #cell(fotos)="data">
              <b-icon icon="card-image" v-if="data.item.tieneFoto" @click="mostrarFotos(data.item)"
                style="cursor: pointer;"></b-icon>
            </template>

            <template #cell(proveedor)="data">
              {{ data.item.proveedor.nombre_corto }}
            </template>

            <template #cell(precio)="data">
              ${{ parseFloat(data.item.precioCliente).toFixed(2) }}
            </template>

            <template #cell(precioIVA)="data">
              ${{ (parseFloat(data.item.precioCliente) + (parseFloat(data.item.precioCliente)
                * parseFloat(data.item.iva))).toFixed(2) }}
            </template>

            <template #cell(cantidad)="data">
              <input placeholder="0" style="width: 5em;" v-model.number="data.item.cantidad"
                @focus="productoSeleccionado = data.item;" :ref="`item-tabla-${data.item.idx}`"
                @keyup.38="goUp(data.item)" @keyup.40="doDown(data.item)" />
            </template>

            <template #cell(pe)="data">
              <input placeholder="0" style="width: 5em;" v-model.number="data.item.precioEspecial"
                @focus="productoSeleccionado = data.item;" @keyup.38="goUp(data.item)" @keyup.40="doDown(data.item)" />
            </template>

            <template #cell(historico)="data">
              <b-icon icon="clock-history" @click="mostrarHistorico(data.item)" style="cursor: pointer;"></b-icon>
            </template>
          </b-table>
        </b-overlay>
        <div class="pagination_container">
          <b-pagination v-model="page" :total-rows="rows" :per-page="numPerPage" first-text="Inicio"
            prev-text="Anterior" next-text="Siguiente" last-text="Ultimo" :disabled="isBusy" />
          <div>
            <span>Resultados por pagina: </span>
            <b-form-select v-model="numPerPage" :options="optionsPerPage" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from 'moment';
import BaseVisualizadorFotos from "../../../components/base/_BaseVisualizadorFotos.vue";
import { axiosInstance } from "../../../utils/axiosInstance";
export default {
  name: "ListaProductos",
  components: {
    BaseVisualizadorFotos
  },
  props: ["orden", "cliente"],
  data() {
    return {
      items: [],
      optionsPerPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 300, text: "300" },
        { value: 500, text: "500" },
        { value: 700, text: "700" },
        { value: 1000, text: "1000" },
      ],
      isBusy: false,
      productoSeleccionado: {},
      page: 1,
      numPerPage: 500,
      query: null,
      rows: 0,
      proveedorSelected: null,
      mostrarDetalles: false,
      currentProducto: null,
      reloadProductList: false,
      soyCliente: sessionStorage.getItem('admin') === 'false',
      itemsHistorico: [],
      filtro: '',
      ordenamiento: ''
    };
  },
  computed: {
    ...mapGetters([
      "listaProveedoresSelect",
      "listaProveedoresSelectMulti",
      "listaProveedoresPrecios",
    ]),
    ...mapState("productosCliente", [
      "cargando",
      "error",
      "listaProductos",
      "listaCarrito",
      "clienteSeleccionado",
      "proveedorSeleccionado",
      "queryCodigo",
      "queryInput",
    ]),
    clienteSelected() {
      return this.cliente;
    },
    cabeceros() {
      const array = [
        {
          key: "codigo",
          label: "Código",
          class: 'text-center',
        },
        {
          key: "descripcion",
          label: "Descripción",
          class: 'text-center',
        },
        {
          key: "descto",
          label: "Descto.",
          class: 'text-center',
        },
        {
          key: "fotos",
          label: "Fotos",
          class: 'text-center',
        },
        {
          key: "proveedor",
          label: "Proveedor",
          class: 'text-center',
        },
        {
          key: "precio",
          label: "Precio",
          class: 'text-center',
        },
        {
          key: "precioIVA",
          label: "Precio con I.V.A.",
          class: 'text-center',
        },
        {
          key: "empaque",
          label: "Empaque",
          class: 'text-center',
        },
        {
          key: "venta_min",
          label: "Venta min.",
          class: 'text-center',
        },
        {
          key: "cantidad",
          label: "Cantidad",
          class: 'text-center',
          thStyle: 'width: 7rem',
        },
        !this.soyCliente ? {
          key: "pe",
          label: "P.E.",
          class: 'text-center',
          thStyle: 'width: 4rem',
        } : {},
        {
          key: "historico",
          label: "Histórico",
          class: 'text-center',
        },
      ];

      return array;
    }
  },
  methods: {
    ...mapActions({
      obtenerProveedores: "ObtenerProveedores",
      obtenerProveedoresPrecios: "ObtenerProveedoresPrecio",
    }),
    async agregarProductos() {
      const productosSeleccionados = this.items.filter(
        (item) => parseInt(item.cantidad) > 0
      );

      const productos = [];
      if (productosSeleccionados.length > 0) {
        this.isBusy = true;

        productosSeleccionados.forEach((element) => {
          if (element.sobrepedido) {
            if (element.venta_min) {
              const minimo = parseInt(element.venta_min.split(' ')[0]);

              if (element.cantidad < minimo) {
                this.makeToast('Agregar productos', 'No puede agregar productos sobre pedido con menos de la venta mínima.', 'warning', 3000);
                this.makeToast('Agregar productos', `Producto con código ${element.codigo} no será agregado.`, 'warning', 3000);
                return;
              }
            }
          }

          element = {
            ...element,
            cantidad: parseInt(element.cantidad),
            iva: element.iva,
            comision: 1,
            orden_id: this.orden,
            precioEspecial: parseInt(element.precioEspecial),
          };

          if (
            element.precioEspecial &&
            (element.precioEspecial < 0 || element.precioEspecial > 6)
          ) {
            delete element.precioEspecial;
          }
          if (
            element.precioEspecial &&
            parseInt(element.precioEspecial) === 6
          ) {

            let value = prompt(`Precio especial ${element.descripcion}`);
            if (value == null || value == "") {
              delete element.precioEspecial;
            } else {
              element.precioEspecialCantidad = value;
            }

            let valueComision = prompt(`Comision: ${element.descripcion}`);
            if (valueComision == null || valueComision == "") {
              element.comision = 1;
            } else {
              element.comision = parseInt(valueComision);
            }
          }

          productos.push(element);
        });

        if (!productos.length) {
          this.isBusy = false;
          return;
        }

        await axiosInstance.post('orden/productos', { productos }, {
          headers: {
            authorization: localStorage.getItem('token')
          }
        }).then(() => this.makeToast('Agregar productos', 'Productos agregados', 'success', 3000))
          .catch(() => this.makeToast('Agregar productos', 'Hubo un error al agregar los productos', 'danger', 3000))
          .finally(() => this.isBusy = false)
        this.$emit("listar-productos");
        this.buscarProductos();
      } else {
        alert("Selccione algun producto");
      }
      this.$emit("listar-productos");
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    async buscarProductos(filtro = '', ordenamiento = '') {
      if (!this.cliente || !this.proveedorSeleccionado.length) {
        this.makeToast('Buscar productos', `Debe tener seleccionado un proveedor ${this.soyCliente ? '' : 'y un cliente'}.`, 'warning', 3000);
        this.items = [];
        this.rows = 0;
        return;
      }

      this.filtro = filtro;
      this.ordenamiento = ordenamiento;

      this.isBusy = true;

      const body = {
        page: this.page,
        numPerPage: this.numPerPage,
        codigo: this.queryCodigo,
        cliente: this.cliente,
        query: this.queryInput,
        proveedores: this.proveedorSeleccionado.map(proveedor => proveedor.value),
        filtro: this.filtro,
        ordenamiento: this.ordenamiento
      };

      try {
        const result = await axiosInstance.get('productos-paginados', {
          params: body
        })

        this.rows = result.data.count;
        this.items = result.data.productos.map((producto, index) => {
          return {
            ...producto,
            cantidad: '',
            idx: index
          }
        });
      } catch (error) {
        this.makeToast('Buscar Productos', 'Hubo un error al consultar los productos.', 'danger', 3000);
        this.rows = 0;
        this.items = [];
      } finally {
        this.isBusy = false
      }
    },
    mostrarFotos(item) {
      //abrir visualizador de fotos
      this.$refs.visualizadorFotos.getRutasImagenes(item);
    },
    mostrarHistorico(producto) {
      if (!this.cliente) {
        this.makeToast('Histórico', 'Debe seleccionar un cliente para obtener su histórico', 'error', 2000);
        return;
      }

      this.isBusy = true;

      axiosInstance.get('pedidos/historico', {
        params: { producto_id: producto.producto_id, cliente: this.cliente },
        headers: {
          authorization: localStorage.getItem('token')
        }
      })
        .then(({ data }) => {
          this.itemsHistorico = data.map(pedido => {
            return {
              fecha: moment(pedido.creacion).format('DD/MM/YYYY'),
              articulo: pedido.pedido_productos[0].producto.descripcion,
              cantidad: pedido.pedido_productos[0].cantidad,
              pedido: pedido.codigo,
              precio: pedido.pedido_productos[0].precio,
              factura: pedido.Factura?.NumFactura || '',
              facturadas: pedido.Factura?.factura_productos[0].CantidadSurtida || '',
              _rowVariant: pedido.pedido_productos[0].oferta ? 'danger' : '',
            }
          });

          this.$refs["modal-historico"].show();
        })
        .catch(() => {
          this.makeToast('Histórico', 'Hubo un error al obtener el histórico', 'error', 2000);
        })
        .finally(() => this.isBusy = false);
    },
    nextItem(e) {
      if (e.keyCode == "38" && parseInt(this.productoSeleccionado.idx) > 0) {
        this.$refs[`item-tabla-${parseInt(this.productoSeleccionado.idx) - 1}`].focus();
      }
      if (e.keyCode == "40") {
        this.$refs[`item-tabla-${parseInt(this.productoSeleccionado.idx) + 1}`].focus();
      }
    },
    diffDays(date) {
      return moment().diff(moment(date), 'days');
    },
    goUp(producto) {
      if (producto.idx <= 0) {
        return;
      }

      this.$refs['tabla-productos'].selectRow(producto.idx - 1)
      this.$refs[`item-tabla-${producto.idx - 1}`].focus()
    },
    doDown(producto) {
      if (producto.idx >= this.items.length) {
        return;
      }

      this.$refs['tabla-productos'].selectRow(producto.idx + 1)
      this.$refs[`item-tabla-${producto.idx + 1}`].focus()
    },
  },
  mounted() {
    if (!this.soyCliente) {
      this.obtenerProveedores();
    }
  },
  watch: {
    page() {
      this.buscarProductos(this.filtro, this.ordenamiento);
    },
    async reloadProductList() {
      await this.listarProductos();
    },
    clienteSelected() {
      this.obtenerProveedoresPrecios(this.clienteSelected);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.pagination_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  >div {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    align-items: center;
  }
}

.align-middle {
  vertical-align: middle;
}

.colours-box {
  align-items: center;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  flex-grow: 100;
}

.colours-item {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
</style>