import Vue from "vue";
import Vuelidate from "vuelidate";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Notifications from "vue-notification";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueHtmlToPaper from "vue-html-to-paper";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";


import './styles/index.scss'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaseButton from "./components/base/_BaseButton.vue";
import BaseSelectCliente from "./components/base/_BaseSelectCliente.vue";
import BaseBackBar from "./components/base/_BaseBackBar.vue";
import BaseTabla from "./components/base/_BaseTabla.vue";
import BaseCargando from "./components/base/_BaseCargando.vue";
import Notificacion from "./utils/notification";
import BaseSelect from "./components/base/_BaseSelect.vue";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

library.add(faUserSecret);
process.env.BOOTSTRAP_VUE_NO_WARN = true;
Vue.config.productionTip = false;
Vue.component("BaseButton", BaseButton);
Vue.component("BaseSelectCliente", BaseSelectCliente);
Vue.component("BaseBackBar", BaseBackBar);
Vue.component("BaseTabla", BaseTabla);
Vue.component("BaseSelect", BaseSelect);
Vue.component("BaseCargando", BaseCargando);
Vue.component("Notificacion", Notificacion);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("vswatches", VSwatches);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueSidebarMenu);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);
Vue.use(Vuelidate);
require("dotenv").config();

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
