<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <div class="back-header">
            <b-icon id="back_icon" icon="chevron-left" @click="regresar" />
            <h3>Asignacion de proveedores para: </h3>
            <h2 class="ml-2">{{ this.nombreCliente }}</h2>
          </div>
        </b-col>
      </b-row>
      <b-row class="contenido-row">
        <b-col>
          <div class="select-proveedor">
            <b-form-select id="select-proveedores" v-model="proveedorSelected" :options="listaProveedores" />
            <b-button id="btn-agregar-proveedor" @click="asignarProveedor">Asignar</b-button>
            <b-button id="btn-agregar-proveedor" class="ml-2" v-if="proveedorPreciosSelected.proveedor_id" @click="desasignarProveedor">Desasignar</b-button>
          </div>
          <b-table id="tabla-proveedores-asignados" empty-text="SIN PROVEEDORES ASIGNADOS"
            :items="listaProveedoresAsignados" :busy="isBusyProveedoresAsignados" :fields="camaposProveedoresAsignados"
            outlined :show-empty="listaProveedoresAsignados.length == 0" selectable select-mode="single"
            @row-selected="onProveedorRowSelected">
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Cargando...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="8">
          <b-tabs>
            <b-tab title="Precios">
              <b-container class="tab-container" fluid>
                <b-row>
                  <b-col>
                    <h3>Proveedor:</h3>
                  </b-col>
                  <b-col cols="9">
                    <p class="p-data">
                      {{
                        this.proveedorPreciosSelected.nombreProveedor ||
                        "Seleccione un proveedor"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h3>Precio:</h3>
                  </b-col>
                  <b-col cols="9">
                    <b-form-select id="select-tipo-precio" class="p-data" :options="listaTiposPrecioProveedor"
                      v-model="proveedorPreciosSelected.tipo" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="footer-button">
                      <b-button class="button-guardar" :disabled="loading"
                        @click="guardarPrecioProveedor">Guardar</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Marcas">
              <b-container>
                <b-row>
                  <b-col>
                    <h3>Proveedor:</h3>
                  </b-col>
                  <b-col cols="9">
                    <p class="p-data">
                      {{
                        this.proveedorPreciosSelected.nombreProveedor ||
                        "Seleccione un proveedor"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <b-row id="row-select-marca">
                  <b-col>
                    <h3>Marca:</h3>
                  </b-col>
                  <b-col cols="9">
                    <b-form-select id="select-marca" class="p-data" :options="listaMarcas" v-model="marcaSelected" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h3>Tipo:</h3>
                  </b-col>
                  <b-col cols="9">
                    <b-form-select id="select-tipo-precio" class="p-data" :disabled="marcasFormDisabled"
                      :options="listaTiposPrecio" v-model="marcaPreeciosSlected.tipo" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h3>Precio:</h3>
                  </b-col>
                  <b-col cols="9">
                    <b-form-input id="input-precio-proveedor" class="p-data" :disabled="marcasFormDisabled" required
                      type="number" v-model="marcaPreeciosSlected.precio"></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="footer-button">
                      <b-button class="button-guardar" :disabled="guardarPrecioMarcaDisabled"
                        @click="guardarPrecioMarca">Guardar</b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Productos">
              <b-container>
                <b-row>
                  <b-col>
                    <h3>Proveedor:</h3>
                  </b-col>
                  <b-col cols="9">
                    <p class="p-data">
                      {{
                        this.proveedorPreciosSelected.nombreProveedor ||
                        "Seleccione un proveedor"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="buscar-producto-form">
                      <b-form-group id="input-buscar-producto-form" label="Buscar producto:"
                        label-for="input-buscar-producto"
                        description="Parametros de busuqeda: codigo, subcodigo, codigo fabrica, descripcion.">
                        <b-form-input id="input-buscar-producto" v-model="searchProductoInput" type="text" required />
                      </b-form-group>
                      <b-button id="btn-buscar-producto" :disabled="searchProductoInput.length === 0"
                        @click="buscarProducto">Buscar</b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h3>Tipo:</h3>
                  </b-col>
                  <b-col cols="9">
                    <b-form-select id="select-tipo-precio" class="p-data" :disabled="productoFormDisabled"
                      :options="listaTiposPrecio" v-model="productoPreeciosSlected.tipo" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h3>Precio:</h3>
                  </b-col>
                  <b-col cols="9">
                    <b-form-input id="input-precio-proveedor" class="p-data" :disabled="productoFormDisabled" required
                      type="number" v-model="productoPreeciosSlected.precio"></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="footer-button">
                      <b-button class="button-guardar" @click="guardarProducto">Guardar</b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="tabla-productos-row" style="justify-content: center">
                  <b-table id="tabla-productos-encontrados" :items="listaProductosEncontrados"
                    :busy="isBusyProductosEncontrados" :fields="camposProductosEncontrados"
                    :show-empty="this.listaProductosEncontrados.length === 0" bordered
                    empty-text="SIN PRODUCTOS ENCONTRADOS" outlined stripedclass="button-guardar" selectable
                    select-mode="single" @row-selected="onProductoRowSelected">
                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                  </b-table>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Transportes">
              <!---Select transportes --->
              <!---Checkbox pagan el cliente? -->
              <!---Guardar -->
              <!---Agregar Proveedor (si es necesario)-->
              <b-container>
                <b-row>
                  <b-col>
                    <h3>Proveedor:</h3>
                  </b-col>
                  <b-col cols="9">
                    <p class="p-data">
                      {{
                        this.proveedorPreciosSelected.nombreProveedor ||
                        "Seleccione un proveedor"
                      }}
                    </p>
                  </b-col>
                </b-row>
                <label for="select-tipo-precio">Transporte:</label>
                <b-form-select id="select-tipo-precio" class="p-data" :disabled="false" :options="listaTransportes"
                  v-model="transporteSelected" />

                <label for="convenioTransporte">Convenio:</label>
                <b-form-input type="text" v-model="convenioTransporte" id="convenioTransporte" class="w-50" />
                <b-row>
                  <b-col cols="2">
                    <b-button class="button-guardar" @click="asignarTransporte"
                      v-if="listaTransportesAsignados.length === 0">Asignar</b-button>
                  </b-col>
                  <b-col>
                    <b-button class="button-guardar" @click="desasignarTransporte"
                      v-if="transporteRowSelected.length > 0">Desasignar transporte</b-button>
                  </b-col>
                  <b-col>
                    <b-button class="button-guardar" @click="crearTransporte">
                      Transportista nuevo
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="tabla-productos-row" style="justify-content: center">
                  <b-table id="tabla-productos-encontrados" :items="listaTransportesAsignados"
                    :fields="camposTransportesAsignados" :show-empty="this.listaTransportesAsignados.length === 0"
                    bordered empty-text="SIN TRANSPORTES ASIGNADOS ENCONTRADOS" outlined striped selectable
                    select-mode="single" @row-selected="onSelectTransporte">
                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                  </b-table>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import api from "../../utils/api";
import { axiosInstance } from '../../utils/axiosInstance';

export default {
  data() {
    return {
      clienteId: null,
      convenioTransporte: '',
      camaposProveedoresAsignados: [
        {
          key: "nombreProveedor",
          label: "Proveedores autorizados",
          sortable: true,
        },
      ],
      camposProductosEncontrados: [
        {
          key: "codigo",
          label: "Codigo",
          sortable: true,
        },
        {
          key: "codigo_fab",
          label: "Codigo Fabrica",
          sortable: true,
        },
        {
          key: "subcodigo",
          label: "Subcodigo",
          sortable: true,
        },
        {
          key: "descripcion",
          label: "Descripcion",
          sortable: true,
        },
      ],
      camposTransportesAsignados: [
        {
          key: "nombreTransporte",
          label: "Transportes Asignados",
          sortable: true,
        },
      ],
      guardarPrecioProveedorDisabled: true,
      guardarPrecioMarcaDisabled: true,
      guardarPrecioProductoDisabled: true,
      listaTiposPrecio: [
        { value: 0, text: "Sin precio especial" },
        { value: 1, text: "Descuento" },
        { value: 2, text: "Porcentaje" },
        { value: 3, text: "Pecio Total" },
      ],
      listaTiposPrecioProveedor: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
      ],
      isBusyProveedoresAsignados: true,
      isBusyProductosEncontrados: false,
      listaMarcas: [],
      listaProveedoresAsignados: [],
      listaProveedores: [],
      listaTransportesAsignados: [],
      listaTransportes: [],
      listaProductosEncontrados: [],
      nombreCliente: null,
      precioProveedor: null,
      proveedorSelected: null,
      proveedorPreciosSelected: {},
      productoFormDisabled: true,
      productoPreeciosSlected: {},
      marcasFormDisabled: true,
      marcaSelected: null,
      marcaPreeciosSlected: {},
      searchProductoInput: "",
      tipoPrecioSelected: null,
      transporteSelected: null,
      transporteRowSelected: false,
      usuario_id: null,
      loading: false,
    };
  },
  methods: {
    asignarProveedor() {
      if (this.proveedorSelected) {
        this.isBusyProveedoresAsignados = true;
        api("POST", "cliente-proveedor/asignar", {
          proveedor_id: this.proveedorSelected,
          usuario_id: this.usuario_id,
        })
          .then(async (result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Seleccione otro proveedor.",
                text: "El proveedor ya se encuentra asignado.",
                type: "error",
              });
              this.isBusyProveedoresAsignados = false;
            } else {
              await this.consultarProveedoresAsignados();
              this.isBusyProveedoresAsignados = false;
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              title: "Error.",
              text: "Errror del servidor.",
              type: "error",
            });
            this.isBusyProveedoresAsignados = false;
          });
      } else {
        this.$notify({
          group: "foo",
          title: "Seleccione un proveedor.",
          text: "Selceccione un proveedor.",
          type: "error",
        });
      }
    },
    async buscarProducto() {
      this.isBusyProductosEncontrados = true;
      try {
        let { result } = await api(
          "GET",
          `productos/consultar-productos-input/${this.searchProductoInput}`
        );
        this.listaProductosEncontrados = [...result];
        this.isBusyProductosEncontrados = false;
      } catch (error) {
        this.$notify({
          group: "foo",
          title: "Error.",
          text: "Error al consultar los productos.",
          type: "error",
        });
        this.isBusyProductosEncontrados = false;
      }
    },
    async consultarMarcas() {
      let { marcas } = await api("GET", `productos/obtener-marcas`);
      this.listaMarcas = marcas.map((result) => {
        return { value: result.marca_id, text: result.nombre };
      });
    },
    async consultarPrecioMarca() {
      this.marcasFormDisabled = true;
      let data = {
        cliente_id: this.clienteId,
        marca_id: this.marcaSelected,
      };
      api("POST", "cliente-marca/consultar-precio", data)
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error.",
              text: "Error al consultar la marca.",
              type: "error",
            });
          } else {
            this.marcaPreeciosSlected.marca_id = this.marcaSelected;
            if (result.precio_cliente_marca.length === 0) {
              this.marcaPreeciosSlected.tipo = 0;
              this.marcaPreeciosSlected.precio = "";
              this.marcaPreeciosSlected.nuevo = true;
            } else {
              this.marcaPreeciosSlected = { ...result.precio_cliente_marca[0] };
              this.marcaPreeciosSlected.nuevo = false;
            }
            this.marcasFormDisabled = false;
            this.guardarPrecioMarcaDisabled = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            group: "foo",
            title: "Seleccione otra marca.",
            text: "La marca ya se encuentra asignado.",
            type: "error",
          });
        });
    },
    async consultarProveedores() {
      let proveedores = await api("GET", `proveedores/obtener-proveedores`);

      this.listaProveedores = proveedores.proveedores.map((proveedor) => {
        return { value: proveedor.proveedor_id, text: proveedor.nombre };
      });
    },
    async consultarTransportesSelect() {
      let transportes = await api("GET", `transporte/consultar-transportes`);
      this.listaTransportes = transportes.transportes.map((transporte) => {
        return { value: transporte.transporte_id, text: transporte.nombre };
      });
    },
    async consultarProveedorAsignado(item) {
      const { usuario_id, proveedor_id } = item[0];
      return await api("POST", "cliente-proveedor/consultar-precio", {
        cliente_id: usuario_id,
        proveedor_id,
      });
    },
    async consultarPrecioProducto(item) {
      const { producto_id } = item[0];
      const cliente_id = this.clienteId;
      return await api("POST", "cliente-producto/consultar-precio", {
        cliente_id,
        producto_id,
      });
    },
    async consultarProveedoresAsignados() {
      const proveedoreAsignados = await api(
        "GET",
        `cliente-proveedor/consultar/${this.usuario_id}`
      );
      this.listaProveedoresAsignados = [
        ...proveedoreAsignados.proveedoresAsignados,
      ];
      this.isBusyProveedoresAsignados = false;
    },
    async consultarTransportesAsignados({ usuario_id, proveedor_id }) {
      const transportesAsignados = await api(
        "GET",
        `cliente-transporte/consultar-transportes/${usuario_id}/${proveedor_id}`
      );
      this.listaTransportesAsignados = [
        ...transportesAsignados.transportesAsignados,
      ];
      this.isBusyTransportesAsignados = false;
    },
    async comprobarParametros() {
      this.usuario_id = this.$route.params.id;
      this.clienteId = this.$route.params.id;
      this.nombreCliente = this.$route.params.nombre;
      await this.consultarProveedoresAsignados();
    },
    desasignarTransporte() {
      axiosInstance.delete('cliente-transporte/desasignar-transporte', {
        data: {
          cliente: this.transporteRowSelected[0].cliente,
          transporte: this.transporteRowSelected[0].transporte,
          proveedor: this.proveedorPreciosSelected.proveedor_id
        },
        headers: {
          authorization: localStorage.getItem('token')
        }
      })
        .then(async () => {
          const data = {
            cliente: this.clienteId,
            proveedor: this.proveedorPreciosSelected.proveedor_id,
            paga_flete: 0,
            convenio: this.convenioTransporte
          }
          await this.consultarTransportesAsignados(data);
          this.makeToast('Asignación', 'Se ha desasignado el transporte', 'success', 2000);
        })
        .catch(() => {
          this.makeToast('Desasignar transporte', 'Hubo un error al eliminar la asignacion', 'danger', 2000);
        });
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    guardarPrecioProveedor() {
      let data = { ...this.proveedorPreciosSelected };
      if (!data.tipo) {
        this.makeToast('Asignar Precio', 'Debe seleccionar un precio a asignar', 'warning', 3000);
        return;
      }
      data.cliente_id = this.clienteId;
      data.precio = "1";
      delete data.pcp_id;
      delete data.nombreProveedor;
      delete data.nombreCliente;
      delete data.usuario_id;
      delete data.apellidoCliente;
      this.loading = true;
      if (data.esNuevo) {
        delete data.esNuevo;
        api("POST", "cliente-proveedor/guardar-precio", data)
          .then((result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Error.",
                text: "Errror del servidor.",
                type: "error",
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Exito.",
                text: "Precio asignado.",
                type: "success",
              });
              this.proveedorPreciosSelected.esNuevo = false;
            }
          })
          .finally(() => this.loading = false);
      } else {
        delete data.esNuevo;
        api("PUT", "cliente-proveedor/modificar-precio", data)
          .then(
            (result) => {
              if (result.errorCode) {
                this.$notify({
                  group: "foo",
                  title: "Error.",
                  text: "Errror del servidor.",
                  type: "error",
                });
              } else {
                this.$notify({
                  group: "foo",
                  title: "Exito.",
                  text: "Precio asignado.",
                  type: "success",
                });
              }
            }
          )
          .finally(() => this.loading = false);
      }
    },
    guardarPrecioMarca() {
      let data = {
        ...this.marcaPreeciosSlected,
      };
      data.cliente_id = this.clienteId;
      data.proveedor_id = this.proveedorPreciosSelected.proveedor_id;
      delete data.nombreMarca;
      if (data.nuevo) {
        delete data.nuevo;
        api("POST", "cliente-marca/guardar-precio", data)
          .then((result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Error.",
                text: "Ingrese todos los datos.",
                type: "error",
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Exito!",
                text: "Precio asignado",
                type: "success",
              });
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              title: "Error!",
              text: "Precio no asignado",
              type: "error",
            });
          });
      } else {
        delete data.nuevo;
        api("PUT", "cliente-marca/modificar-precio", data)
          .then((result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Error.",
                text: "Ingrese todos los datos.",
                type: "error",
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Exito!",
                text: "Precio modificado",
                type: "success",
              });
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              title: "Error!",
              text: "Precio no asignado",
              type: "error",
            });
          });
      }
    },
    guardarProducto() {
      let data = {
        producto_id: this.productoPreeciosSlected.producto_id,
        tipo: this.productoPreeciosSlected.tipo,
        precio: this.productoPreeciosSlected.precio,
        esNuevo: this.productoPreeciosSlected.esNuevo,
        cliente_id: this.clienteId,
        proveedor_id: this.proveedorPreciosSelected.proveedor_id,
      };

      if (data.esNuevo) {
        delete data.esNuevo;
        api("POST", "cliente-producto/guardar-precio", data)
          .then((result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Error.",
                text: "Ingrese todos los datos.",
                type: "error",
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Exito!",
                text: "Precio asignado",
                type: "success",
              });
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              title: "Error!",
              text: "Precio no asignado",
              type: "error",
            });
          });
      } else {
        delete data.esNuevo;
        api("PUT", "cliente-producto/modificar-precio", data)
          .then((result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Error.",
                text: "Ingrese todos los datos.",
                type: "error",
              });
            } else {
              this.$notify({
                group: "foo",
                title: "Exito!",
                text: "Precio modificado",
                type: "success",
              });
            }
          })
          .catch(() => {
            this.$notify({
              group: "foo",
              title: "Error!",
              text: "Precio no asignado",
              type: "error",
            });
          });
      }
    },
    asignarTransporte() {
      let data = {
        cliente: this.clienteId,
        transporte: this.transporteSelected,
        proveedor: this.proveedorPreciosSelected.proveedor_id,
        paga_flete: 0,
        convenio: this.convenioTransporte
      };
      api("POST", "cliente-transporte/asignar-transporte", data)
        .then(async (result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error.",
              text: "Ingrese todos los datos.",
              type: "error",
            });
          } else {
            this.$notify({
              group: "foo",
              title: "Exito!",
              text: "Transporte asignado",
              type: "success",
            });
          }
          await this.consultarTransportesAsignados({ usuario_id: data.cliente, proveedor_id: data.proveedor });
        })
        .catch(() => {
          this.$notify({
            group: "foo",
            title: "Error!",
            text: "Transporte no asignado",
            type: "error",
          });
        });
    },
    async onProveedorRowSelected(item) {
      if (item.length > 0) {
        this.loading = true;
        this.guardarPrecioProveedorDisabled = false;
        this.proveedorPreciosSelected = {};
        this.isBusyProveedoresAsignados = true;
        const { precio_cliente_proveedor } = await this.consultarProveedorAsignado(item);
        await this.consultarTransportesAsignados(item[0]);
        if (precio_cliente_proveedor.length === 0) {
          this.proveedorPreciosSelected = { ...item[0] };
          this.proveedorPreciosSelected.tipo = 0;
          this.proveedorPreciosSelected.precio = "0";
          this.proveedorPreciosSelected.esNuevo = true;
          this.consultarProveedoresAsignados();
          this.isBusyProveedoresAsignados = false;
        } else {
          this.proveedorPreciosSelected = { ...precio_cliente_proveedor[0] };
          this.proveedorPreciosSelected.esNuevo = false;
          this.isBusyProveedoresAsignados = false;
        }
        this.loading = false;
      } else {
        this.proveedorPreciosSelected = {};
        this.guardarPrecioProveedorDisabled = true;
      }
    },
    async onProductoRowSelected(item) {
      if (item.length > 0) {
        this.guardarPrecioProducto = false;
        this.productoFormDisabled = false;
        this.isBusyProductosEncontrados = true;
        try {
          const { precio_cliente_producto } =
            await this.consultarPrecioProducto(item);
          if (precio_cliente_producto.length === 0) {
            this.productoPreeciosSlected = { ...item[0] };
            this.productoPreeciosSlected.tipo = 0;
            this.productoPreeciosSlected.precio = "0";
            this.productoPreeciosSlected.esNuevo = true;
            this.isBusyProductosEncontrados = false;
          } else {
            this.productoPreeciosSlected = { ...precio_cliente_producto[0] };
            this.productoPreeciosSlected.esNuevo = false;
            this.isBusyProductosEncontrados = false;
          }
          this.guardarPrecioProductoDisabled = false;
        } catch (error) {
          console.log(error);
          this.$notify({
            group: "foo",
            title: "Error!",
            text: "Error al consultar precios",
            type: "error",
          });
          this.productoPreeciosSlected = {};
          this.isBusyProductosEncontrados = false;
        }
      } else {
        this.productoPreeciosSlected = {};
        this.guardarPrecioProveedorDisabled = false;
      }
    },
    regresar() {
      this.$router.push("/app/catalogos/usuarios");
    },
    crearTransporte() {
      this.$router.push("/app/catalogos/transportes");
    },
    onSelectTransporte(selected) {
      this.transporteRowSelected = selected;
    },
    async desasignarProveedor() {
      try {
        await axiosInstance.delete('cliente-proveedor/eliminar', {
          data: {
            usuario_id: this.usuario_id,
            proveedor_id: this.proveedorPreciosSelected.proveedor_id
          },
          headers: {
            authorization: localStorage.getItem('token')
          }
        });

        this.consultarProveedoresAsignados();
        this.makeToast('Desasignado', 'Se ha desasignado el proveedor', 'success', 3000);
      } catch (error) {
        this.makeToast('Error', error.response.data.msg || 'Hubo un error al desasignar al proveedor', 'danger', 3000);
      }
    }
  },
  watch: {
    marcaSelected() {
      this.consultarPrecioMarca();
    },
  },
  mounted() {
    this.clienteId = this.consultarMarcas();
    this.consultarProveedores();
    this.consultarTransportesSelect();
    this.comprobarParametros();
  },
};
</script>

<style lang="scss">
h3 {
  margin-left: 10px;
}

#btn-agregar-proveedor {
  background-color: #355070;
  border-color: #355070;
}

#input-buscar-producto-form {
  width: 100%;
}

#tabla-proveedores-asignados {
  margin-top: 20px;
}

#tabla-productos-row {
  justify-content: center;
}

#tabla-productos-encontrados {
  margin-top: 20px;
  width: 95%;
}

#row-select-marca {
  justify-content: center;
}

#select-marca {
  margin: 10px 0;
  width: 96%;
}

#select-proveedores {
  margin-right: 10px;
}

.b-icon:nth-child(2) {
  margin-bottom: 5px;
}

.back-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buscar-producto-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.button-guardar {
  background-color: #355070 !important;
  border-color: #355070 !important;
  margin-left: 10px;
  margin-top: 20px;
}

#btn-buscar-producto {
  height: 38px;
  margin-left: 10px;
  margin-bottom: 8px;
}

.contenido-row {
  margin-top: 10px;
}

.footer-button {
  display: flex;
}

.p-data {
  font-size: 1.2rem;
  margin-top: 8px;
}

.select-proveedor {
  display: flex;
  flex-direction: row;
}

.tab-container {
  margin-top: 10px;
  padding-left: 0px !important;
}

#input-precio-proveedor {
  max-width: 77%;
}
</style>
