<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-left align-items-center mb-3">
          <h5 class="mr-4">Buscar por</h5>
          <b-form-radio name="buscarPor" v-model="buscarPor" value="codigo" class="mr-3">Código</b-form-radio>
          <b-form-radio name="buscarPor" v-model="buscarPor" value="marca" class="mr-3">Marca</b-form-radio>
          <b-form-radio name="buscarPor" v-model="buscarPor" value="descripcion">Descripción</b-form-radio>
        </div>
      </b-col>

      <b-col>
        <div class="d-flex justify-content-left mb-3" v-if="buscarPor === 'codigo'">
          <h5 class="mr-4">Codigo</h5>
          <b-form-input type="text" v-model="codigo"></b-form-input>
        </div>

        <div class="d-flex justify-content-left mb-3" v-if="buscarPor === 'marca'">
          <h5 class="mr-4">Marcas</h5>
          <b-form-select :options="marcas" v-model="marcaSelected"></b-form-select>
        </div>

        <div class="d-flex justify-content-left mb-3" v-if="buscarPor === 'descripcion'">
          <h5 class="mr-4">Descripción</h5>
          <b-form-input type="text" v-model="descripcion"></b-form-input>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="d-flex justify-content-left mb-3">
          <h5 class="mr-4">Proveedor</h5>
          <Multiselect multiple :options="proveedores" v-model="proveedorSelected"
            placeholder="Seleccione un proveedor." searchable allow-empty track-by="value" label="text" />
        </div>
      </b-col>

      <b-col>
        <b-button variant="primary" class="mb-3" @click="buscarProductos" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          Buscar
        </b-button>
      </b-col>
    </b-row>

    <section class="row">
      <article class="col">
        <article class="d-flex justify-content-left align-items-center mb-3">
          <h5 class="mr-4">Establecer oferta por</h5>
          <b-form-radio name="ofertaPor" v-model="ofertaPor" value="precio" class="mr-3">Precio
            específico</b-form-radio>
          <b-form-radio name="ofertaPor" v-model="ofertaPor" value="porcentaje">Porcentaje</b-form-radio>
        </article>
      </article>

      <article class="col">
        <b-form-checkbox v-model="aplicarFechaFin" :value="true" :unchecked-value="false">
          ¿Aplicar fecha fin?
        </b-form-checkbox>
        <b-form-input type="date" v-model="fechaFin" v-if="aplicarFechaFin" />
      </article>
    </section>

    <b-row v-if="ofertaPor === 'precio'">
      <b-col>
        <b-form-group label="Precio 1" label-for="precio1">
          <b-form-input id="precio1" type="number" v-model.number="precios.precio_1"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Precio 2" label-for="precio2">
          <b-form-input id="precio2" type="number" v-model.number="precios.precio_2"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Precio 3" label-for="precio3">
          <b-form-input id="precio3" type="number" v-model.number="precios.precio_3"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Precio 4" label-for="precio4">
          <b-form-input id="precio4" type="number" v-model.number="precios.precio_4"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Precio 5" label-for="precio5">
          <b-form-input id="precio5" type="number" v-model.number="precios.precio_5"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-left" v-if="ofertaPor === 'porcentaje'">
      <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="7" label="Porcentaje"
        label-for="porcentaje">
        <b-input-group append="%">
          <b-form-input id="porcentaje" v-model.number="porcentaje" type="number"></b-form-input>
        </b-input-group>
      </b-form-group>
    </div>

    <b-button variant="primary" @click="crearOferta" :disabled="loading">
      <b-spinner small v-if="loading"></b-spinner>
      Crear oferta
    </b-button>

    <b-table-simple hover small style="font-size: 14px;">
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th class="text-center">Código</b-th>
          <b-th class="text-center">Subcódigo</b-th>
          <b-th style="text-wrap: pretty;">Descripción</b-th>
          <b-th class="text-center">Precio 1</b-th>
          <b-th class="text-center">Precio 2</b-th>
          <b-th class="text-center">Precio 3</b-th>
          <b-th class="text-center">Precio 4</b-th>
          <b-th class="text-center">Precio 5</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(producto, index) of productos" :key="producto.producto_id">
          <b-td class="text-center">
            <b-button variant="danger" size="sm" @click="productos.splice(index, 1)">
              <b-icon-x></b-icon-x>
            </b-button>
          </b-td>
          <b-td class="text-center">{{ producto.codigo }}</b-td>
          <b-td class="text-center">{{ producto.subcodigo }}</b-td>
          <b-td>{{ producto.descripcion }}</b-td>
          <b-td class="text-right">${{ producto.precio.precio_1 }}</b-td>
          <b-td class="text-right">${{ producto.precio.precio_2 }}</b-td>
          <b-td class="text-right">${{ producto.precio.precio_3 }}</b-td>
          <b-td class="text-right">${{ producto.precio.precio_4 }}</b-td>
          <b-td class="text-right">${{ producto.precio.precio_5 }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-container>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2';
import { axiosInstance } from '../../../utils/axiosInstance';
export default {
  name: "AgregarOfertas",
  data() {
    return {
      buscarPor: '',
      ofertaPor: '',
      proveedores: [{ value: null, text: 'Seleccione un proveedor' }],
      marcas: [{ value: null, text: 'Seleccione una marca' }],
      proveedorSelected: null,
      marcaSelected: null,
      descripcion: '',
      codigo: '',
      productos: [],
      loading: false,
      porcentaje: 0,
      precios: {
        precio_1: 0,
        precio_2: 0,
        precio_3: 0,
        precio_4: 0,
        precio_5: 0,
      },
      aplicarFechaFin: false,
      fechaFin: '',
    };
  },
  async mounted() {
    axiosInstance.get('proveedores', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
      .then(proveedores => {
        proveedores.data.forEach(proveedor => {
          this.proveedores.push({ value: proveedor.proveedor_id, text: proveedor.nombre })
        })
      })
      .catch(() => {
        this.makeToast('Proveedores', 'Hubo un error al obtener los proveedores', 'danger', 3000);
      });

    axiosInstance.get('marcas', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
      .then(marcas => {
        marcas.data.forEach(marca => {
          this.marcas.push({ value: marca.marca_id, text: marca.nombre })
        })
      })
      .catch(() => {
        this.makeToast('Marcas', 'Hubo un error al obtener las marcas', 'danger', 3000);
      })
  },
  methods: {
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
    buscarProductos() {
      if (!this.proveedorSelected || !this.proveedorSelected.length) {
        this.makeToast('Productos', 'Debe seleccionar al menos un proveedor', 'warning', 3000);
        return;
      }

      if (!this.buscarPor) {
        this.makeToast('Productos', 'Debe seleccionar una opción de búsqueda.', 'warning', 3000);
        return;
      }

      this.loading = true;

      this.productos = [];

      let filtros = {
        suspendido: 0,
        oferta: false
      };

      filtros.proveedores = this.proveedorSelected.map(proveedor => proveedor.value);

      switch (this.buscarPor) {
        case 'codigo':
          if (!this.codigo) {
            this.makeToast('Productos', 'El código está vacío', 'warning', 3000);
            this.loading = false;
            return;
          }

          filtros.codigo = this.codigo;
          break;
        case 'marca':
          if (!this.marcaSelected) {
            this.makeToast('Productos', 'Seleccione al menos una marca.', 'warning', 3000);
            this.loading = false;
            return;
          }

          filtros.marca = this.marcaSelected;
          break;
        case 'descripcion':
          if (!this.descripcion) {
            this.makeToast('Productos', 'La descripción está vacía.', 'warning', 3000);
            this.loading = false;
            return;
          }

          filtros.descripcion = this.descripcion
          break;
      }

      axiosInstance.get('productos', {
        params: filtros,
        headers: {
          authorization: localStorage.getItem('token')
        }
      })
        .then(productos => {
          this.productos = productos.data.productos;
        })
        .catch(() => {
          this.makeToast('Productos', 'Hubo un error al obtener los productos', 'danger', 3000);
        })
        .finally(() => this.loading = false);
    },
    async crearOferta() {
      if (!this.productos.length) {
        this.makeToast('Crear oferta', 'No ha seleccionado ningún producto.', 'warning', 3000);
        return;
      }

      if (!this.ofertaPor) {
        this.makeToast('Crear oferta', 'No ha seleccionado una opción de oferta', 'warning', 3000);
        return;
      }

      switch (this.ofertaPor) {
        case 'precio':
          if (this.precios.precio_1 <= 0 ||
            this.precios.precio_2 <= 0 ||
            this.precios.precio_3 <= 0 ||
            this.precios.precio_4 <= 0 ||
            this.precios.precio_5 <= 0) {
            this.makeToast('Crear oferta', 'Los precios deben ser mayores a 0.', 'warning', 3000);
            return;
          }
          break;

        case 'porcentaje':
          if (this.porcentaje <= 0) {
            this.makeToast('Crear oferta', 'El porcentaje de descuento debe ser mayor a 0', 'warning', 3000);
            return;
          }
          break;
      }

      if (this.aplicarFechaFin && !this.fechaFin) {
        this.makeToast('Crear oferta', 'No ha seleccionado una fecha fin', 'warning', 3000);
        return;
      }

      let marca = '';

      if (this.buscarPor === 'marca') {
        const respuesta = await Swal.fire({
          showDenyButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          denyButtonText: 'No',
          confirmButtonText: 'Sí',
          icon: 'question',
          text: '¿Desea crear oferta para toda la marca?'
        });

        if (respuesta.isConfirmed) {
          marca = this.marcaSelected;
        }
      }

      this.loading = true;

      try {
        await axiosInstance.post('ofertas', {
          productos: this.productos,
          tipo: this.ofertaPor,
          precios: this.precios,
          porcentaje: this.porcentaje,
          fecha: this.aplicarFechaFin ? this.fechaFin : '',
          marca
        }, {
          headers: {
            authorization: localStorage.getItem('token')
          }
        })
        this.makeToast('Crear oferta', 'Oferta creada.', 'success', 3000);
      } catch (error) {
        this.makeToast('Crear oferta', error.response.data.msg || 'Hubo un error al crear las ofertas.', 'danger', 3000);
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    Multiselect
  }
};
</script>