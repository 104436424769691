<template>
  <div id="pdf-pedidos">
    <h1>INFORMACION DEL CLIENTE</h1>
    <section class="pdf-header">
      <div>
        <div class="pdf-text-item">
          <p>NUMERO DE PEDIDO:</p>
          <span>{{ pedido.codigo || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CLIENTE:</p>
          {{ pedido.cliente_usuario.razon_social || "" }}
        </div>
        <div class="pdf-text-item">
          <p>DOMICILIO:</p>
          <span>{{ pedido.domicilio_consig || pedido.cliente_usuario.domicilio || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CIUDAD:</p>
          <span>{{ pedido.cliente_usuario.perfil_cliente_perfil_cliente.ciudad || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>RFC:</p>
          <span>{{ pedido.cliente_usuario.rfc || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>TELEFONO:</p>
          <span>{{ pedido.cliente_usuario.tel_1 || pedido.cliente_usuario.tel_2 || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CÓDIGO POSTAL:</p>
          <span>{{ pedido.cliente_usuario.perfil_cliente_perfil_cliente.codigo_postal || "" }}</span>
        </div>
      </div>
      <div>
        <div class="pdf-text-item">
          <p>CONDICIONES DE VENTA:</p>
          <span>{{ pedido.cliente_usuario.perfil_cliente_perfil_cliente.condiciones_venta || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>PROVEEDOR:</p>
          <span>{{ pedido.proveedor_proveedore && pedido.proveedor_proveedore.nombre || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>FECHA:</p>
          <span>{{ pedido.creacion || "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>VIGENCIA:</p>
          <span>8 dias</span>
        </div>
        <div class="pdf-text-item">
          <p>TRANSPORTE:</p>
          <span>{{ pedido.transporte ? pedido.transporte.transporte_transporte.nombre : "" }}</span>
        </div>
        <div class="pdf-text-item">
          <p>CONVENIO:</p>
          <span>{{ pedido.transporte ? pedido.transporte.convenio : "" }}</span>
        </div>
      </div>

    </section>
    <h1 class="text-center">PRODUCTOS PEDIDOS</h1>
    <section class="pdf-tabla px-2">
      <b-table-simple class="px-2" bordered small>
        <thead>
          <tr>
            <th class="text-center">Partida</th>
            <th class="text-center">Codigo</th>
            <th class="text-center">Descripcion</th>
            <th class="text-center">Cantidad</th>
            <th class="text-center">Precio</th>
            <th class="text-center">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(producto, i) of pedido.pedido_productos" :key="i">
            <td class="text-center">{{ producto.partida }}</td>
            <td class="text-center">{{ producto.producto.codigo }}</td>
            <td>{{ producto.producto.descripcion }}</td>
            <td class="text-center">{{ producto.cantidad }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <span>
                  $
                </span>
                <span>
                  {{ `${parseFloat(producto.precio).toFixed(2)}` }}
                </span>
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-between">
                <span>
                  $
                </span>
                <span>
                  {{ `${parseFloat(producto.subtotal).toFixed(2)}` }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </b-table-simple>
    </section>
    <footer class="mt-auto">
      <div class="observaciones">
        <h3>Observaciones:</h3>
        <p class="px-1">{{ pedido.comentarios || "" }}</p>
      </div>
      <div class="totales">
        <div class="total-row">
          <p>Venta Grabada.:</p>
          <p>
            <span>$</span>
            {{ pedido.total | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>Venta Exenta.:</p>
          <p>
            <span>$</span>
            {{ pedido.ieps | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>Subtotal:</p>
          <p>
            <span>$</span>
            {{ pedido.subtotal | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>I.V.A.:</p>
          <p>
            <span>$</span>
            {{ pedido.iva | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>I.E.P.S.:</p>
          <p>
            <span>$</span>
            {{ pedido.ieps | currency }}
          </p>
        </div>
        <div class="total-row">
          <p>Total:</p>
          <p>
            <span>$</span>
            {{ pedido.total | currency }}
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PdfPedidos",
  props: ['pedido'],
  filters: {
    currency(value) {
      try {
        return value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch {
        return "0";
      }
    },
    text(value) {
      if (value === "" || value === null || value === undefined) {
        return " - ";
      }
      return value;
    },
  },
};
</script>