<template>
  <div>
    <b-carousel id="carousel-no-animation" style="text-shadow: 0px 0px 2px #000" no-animation indicators controls
      label-next label-prev>
      <a v-for="image in normalizedRutas" :key="image" :href="image" target="_blank">
        <b-carousel-slide class="product-details-image" :img-src="image" />
      </a>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Caroussell",
  props: ["rutasImagen"],
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  computed: {
    normalizedRutas: function () {
      return this.rutasImagen.map(({ path }) => {
        return `https://jprepresentaciones.com.mx/api/productos-imagen/obtener/${path}`;
      });
    },
  },
};
</script>