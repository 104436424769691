<template>
  <div>
    <b-modal
      id="modal-agregar"
      ref="modal-agregar"
      size="m"
      title="Agregar Transporte"
      scrollable
      hide-footer
    >
      <div class="agregar_cliente_container">
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="nombre">Nombre: </label>
              <b-form-input
                id="nombre"
                v-model="transporteNuevo.nombre"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="direccion"
                >Direccion:
              </label>
              <b-form-input
                id="razon_social"
                v-model="transporteNuevo.direccion"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="Correo">Correo: </label>
              <b-form-input
                id="Correo"
                v-model="transporteNuevo.correo"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="Telefono"
                >Telefono:
              </label>
              <b-form-input
                id="Telefono"
                v-model="transporteNuevo.tel"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top: 20px">
          <b-col>
            <b-button
              style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              "
              block
              @click="guardarTransporte"
              >GUARDAR</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      id="modal-modificar"
      ref="modal-modificar"
      size="m"
      title="Modificar Transporte"
      scrollable
      hide-footer
    >
      <div class="agregar_cliente_container">
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="nombre">Nombre: </label>
              <b-form-input
                id="nombre"
                v-model="selected.nombre"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="correo">Correo: </label>
              <b-form-input
                id="correo"
                v-model="selected.correo"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="tel">Telefono: </label>
              <b-form-input
                id="tel"
                v-model="selected.tel"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="input">
              <label style="margin-right: 10px" for="direccion"
                >Direccion:
              </label>
              <b-form-input
                id="direccion"
                v-model="selected.direccion"
                type="text"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              block
              style="background-color: #355070; margin-top: 10px"
              @click="modificarTransporte"
              >Guardar</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-right"
      ref="sidebar-right"
      backdrop
      no-header
      title="Detalles"
      right
      bg-variant="white"
      shadow
    >
      <div class="detalles_header">
        <b-icon id="x-icon" v-b-toggle.sidebar-right icon="x"></b-icon>
        <h3>Detalles Transporte</h3>
      </div>
      <div class="detalles_body">
        <b-row>
          <b-col>
            <h4 class="fill">
              {{ this.selected.nombre }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="dato_item">
              <p class="txt_dato">ID:</p>
              <p class="txt_dato_resp">{{ this.selected.transporte_id }}</p>
            </div>
            <div class="dato_item">
              <p class="txt_dato">Correo:</p>
              <p class="txt_dato_resp">{{ this.selected.correo }}</p>
            </div>
            <div class="dato_item">
              <p class="txt_dato">Teléfono:</p>
              <p class="txt_dato_resp">{{ this.selected.tel || "-" }}</p>
            </div>
            <div class="dato_item">
              <p class="txt_dato">Domicilio:</p>
              <p class="txt_dato_resp">{{ this.selected.direccion || "-" }}</p>
            </div>
            <b-button
              block
              style="background-color: #355070; margin-top: 10px"
              v-b-toggle.sidebar-right
              v-b-modal.modal-modificar
              >MODIFICAR</b-button
            >
            <b-button
              block
              style="background-color: #a5594e; border-color: #a5594e"
              @click="suspenderTransportes"
              >SUSPENDER</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Transportes</h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-right: 10px;
            width: 100%;
          "
        >
          <b-input-group style="width: 450px; padding-left: 10px">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            ></b-form-input>
          </b-input-group>
          <div>
            <b-button
              style="
                margin-right: 15px;
                background-color: #355070;
                border-color: #355070;
              "
              @click="abrirModal"
            >
              <b-icon icon="plus" style="margin-right: 4px" />AGREGAR</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container class="table_container" fluid>
          <b-table
            :items="items"
            :fields="fields"
            striped
            bordered
            hover
            head-variant="light"
            :per-page="perPage"
            :busy="isBusy"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            v-b-toggle.sidebar-right
          >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            :current-page="currentPage"
          ></b-pagination>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import api from "../../utils/api";

export default {
  data() {
    return {
      fields: [
        {
          key: "nombre",
          sortable: true,
          label: "Nombre",
        },
        {
          key: "tel",
          sortable: true,
          label: "Telefono",
        },
        {
          key: "correo",
          sortable: true,
          label: "Correo",
        },
        {
          key: "direccion",
          sortable: true,
          label: "Domicilio",
        },
      ],
      currentPage: 1,
      perPage: 10,
      items: [],
      itemsAux: [],
      isBusy: false,
      filter: null,
      filterOn: [],
      selected: {},
      itemsRoles: [],
      itemsSesiones: [],
      isBusyRoles: false,
      nuevoUsuario: {},
      transporteNuevo: {},
      clienteModificado: {},
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarTransportes() {
      this.isBusy = !this.isBusy;
      api("GET", "transporte/consultar-transportes").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener el personal",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          //   result.usuarios.map((usuario) => {
          //     usuario.created_at = moment(usuario.created_at).format("LLL");
          //   });
          this.items = result.transportes;
          this.itemsAux = result.transportes;
          this.isBusy = !this.isBusy;
        }
      });
    },
    onRowSelected(item) {
      this.selected = item[0];
    },
    parseDate(date) {
      return moment(date || "00").format("DD-MM-YYYY");
    },
    suspenderTransportes() {
      this.isBusy = !this.isBusy;
      api("DELETE", `transporte/suspender/${this.selected.transporte_id}`)
        .then(async (result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al suspender el transporte",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.$refs["sidebar-right"].hide();
            await this.listarTransportes();
            this.isBusy = !this.isBusy;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    abrirModal() {
      this.$refs["modal-agregar"].show();
    },
    cerrarModal() {
      this.$refs["modal-agregar"].hide();
    },
    abrirModalPreciosProveedores() {
      this.$refs["modal-precios-proveedores"].show();
    },
    cerrarModalPreciosProveedores() {
      this.$refs["modal-precios-proveedores"].hide();
    },
    guardarTransporte() {
      api("POST", "transporte/agregar", this.transporteNuevo)
        .then(async (result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al agregar el transporte",
              text: "Error del servidor",
              type: "error",
            });
            this.listarTransportes();
          } else {
            this.listarTransportes();
            this.isBusy != this.isBusy;
            this.$refs["modal-agregar"].hide();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    modificarTransporte() {
      api(
        "PUT",
        `transporte/modificar/${this.selected.transporte_id}`,
        this.selected
      )
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al modificar el transporte",
              text: "Error del servidor",
              type: "error",
            });
            this.listarTransportes();
          } else {
            this.$refs["modal-modificar"].hide();
            this.listarTransportes();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.listarTransportes();
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}
#sidebar-right {
  width: 450px;
}
.fill {
  color: #aaa;
}
.cabecera_roles {
  display: none;
}
.table_container {
  margin-top: 20px;
}
.table_container tr:hover {
  cursor: pointer;
}
#tabla_roles {
  margin-top: 10px;
}
.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}
.detalles_body {
  margin: 10px;
}
.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}
.txt_dato {
  font-weight: bold;
}
.txt_dato_resp {
  color: #424242;
}
.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.modal_form_personal {
}
</style>
