<template>
  <div id="Cargando">
    <h2>Cargando</h2>
    <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
  </div>
</template>

<script>
export default {
  name: "Cargando",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>
<style lang="scss">
#Cargando {
  position: absolute;
  z-index: 19999 !important;
  background-color: white;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>