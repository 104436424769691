<template>
  <b-container fluid id="PreciosEspeciales">
    <b-tabs content-class="mt-3">
      <b-tab title="Agregar Precio Especial" active>
        <AgregarPrecios />
      </b-tab>
      <!-- TODO Remover precios especiales -->
      <!-- <b-tab title="Remover Ofertas"><p>RemoverOfertas</p></b-tab> -->
    </b-tabs>
  </b-container>
</template>

<script>
import BaseTabla from "../../../components/base/_BaseTabla.vue";
import AgregarPrecios from "./Agregar.vue";

export default {
  name: "Ofertas",
  props: [],
  components: { BaseTabla, AgregarPrecios },
  data() {
    return {};
  },
  mounted() {},
  destroyed() {},
  methods: {},
  computed: {},
};
</script>
<style lang="scss" src="./precios_especiales.scss" />