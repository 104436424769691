<template>
  <div id="main_app">
    <!-- <SideMenu /> -->
    <SideBar id="sidebar" />
    <b-container id="router-container" fluid>
      <Navbar />
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar.vue";
export default {
  name: "main_app",
  components: {
    Navbar,
    SideBar,
  },
};
</script>

<style>
#main_app {
  display: grid;
  grid-template-columns: 50px auto;
}
#router-container {
  grid-column: 2/3;
  padding: 0px;
}
.navbar {
  max-height: 40px;
}
</style>
