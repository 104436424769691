<template>
  <b-modal
    id="ModalAgregarUsuario"
    ref="ModalAgregarUsuario"
    size="xl"
    title="Agregar Usuario"
    scrollable
    hide-footer
    fluid
  >
    <div class="wrap">
      <b-form class="form_agregar_usuario">
        <b-form-group id="intput-group-email" label="Email:">
          <b-form-input
            id="input-email"
            required
            type="email"
            placeholder="Email del Usuario"
            v-model="nuevoUsuario.email"
          />
        </b-form-group>
        <b-form-group id="intput-group-email-personal" label="Email Personal:">
          <b-form-input
            id="input-email-personal"
            required
            type="email"
            placeholder="Email Personal del Usuario"
            v-model="nuevoUsuario.email_personal"
          />
        </b-form-group>
        <b-form-group id="intput-group-password" label="Contraseña:">
          <b-form-input
            id="input-password"
            required
            placeholder="Contraseña del Usuario"
            type="password"
            v-model="nuevoUsuario.password"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-nombre" label="Nombre:">
          <b-form-input
            id="input-nombre"
            required
            placeholder="Nombre del Usuario"
            v-model="nuevoUsuario.nombre"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-apellido" label="Apellido:">
          <b-form-input
            id="input-apellido"
            required
            placeholder="Apellido del Usuario"
            v-model="nuevoUsuario.apellido"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-tel" label="Teléfono de trabajo:">
          <b-form-input
            id="input-tel"
            required
            placeholder="Teléfono de trabajo"
            type="number"
            v-model="nuevoUsuario.tel"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-cel" label="Teléfono Usuario:">
          <b-form-input
            id="input-celular"
            required
            placeholder="Celular Usuario"
            type="number"
            v-model="nuevoUsuario.celular"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-domicilio" label="Domicilio:">
          <b-form-input
            id="input-domicilio"
            required
            placeholder="Domicilio Usuario"
            v-model="nuevoUsuario.domicilio"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-curp" label="CURP:">
          <b-form-input
            id="input-curp"
            required
            placeholder="CURP"
            v-model="nuevoUsuario.curp"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-no_seguro" label="Seguro Social:">
          <b-form-input
            id="input-no_seguro"
            required
            placeholder="Seguro Social"
            v-model="nuevoUsuario.no_seguro"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-nacimiento" label="Cumpleaños:">
          <b-form-input
            id="input-nacimiento"
            required
            placeholder="Nacimiento"
            type="date"
            v-model="nuevoUsuario.nacimiento"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="intput-group-email_usuario" label="Correo Usuario:">
          <b-form-input
            id="input-email_usuario"
            required
            placeholder="Correo Usuario"
            v-model="nuevoUsuario.email_usuario"
          ></b-form-input>
        </b-form-group>
        <b-form-checkbox
          id="esCliente"
          v-model="nuevoUsuario.esCliente"
          name="esCliente"
          :value="true"
          :unchecked-value="false"
          style="margin-bottom: 10px"
        >
          ¿Es Cliente?
        </b-form-checkbox>
        <template v-if="nuevoUsuario.esCliente">
          <b-form-group id="intput-group-razon_social" label="Razon Social:">
            <b-form-input
              id="input-razon_social"
              required
              placeholder="Razon social"
              v-model="nuevoUsuario.razon_social"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-cobro_dias" label="Dias Cobro:">
            <b-form-input
              id="input-cobro_dias"
              required
              placeholder="Dias Cobro"
              type="number"
              v-model="nuevoUsuario.cobro_dias"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-cobro_email" label="Comprador:">
            <b-form-input
              id="input-cobro_email"
              required
              placeholder="Comprador: "
              v-model="nuevoUsuario.cobro_email"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-ciudad" label="Ciudad:">
            <b-form-input
              id="input-ciudad"
              required
              placeholder="Ciudad: "
              v-model="nuevoUsuario.ciudad"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-rfc" label="RFC:">
            <b-form-input
              id="input-rfc"
              required
              placeholder="RFC: "
              v-model="nuevoUsuario.rfc"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-tel_2" label="Telefono 2">
            <b-form-input
              id="input-tel_2"
              required
              placeholder="Telefono 2 "
              v-model="nuevoUsuario.tel_2"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-tel_3" label="Telefono 3">
            <b-form-input
              id="input-tel_3"
              required
              placeholder="Telefono 3 "
              v-model="nuevoUsuario.tel_3"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-comprador" label="Comparador:">
            <b-form-input
              id="input-comprador"
              required
              placeholder="Comparador: "
              v-model="nuevoUsuario.comprador"
            ></b-form-input>
          </b-form-group>
          <b-form-group id="intput-group-condiciones" label="Condiciones: ">
            <b-form-input
              id="input-condiciones"
              required
              placeholder="Condiciones:  "
              v-model="nuevoUsuario.condiciones"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="intput-group-compleanos_comprador"
            label="Compleanos comprador: "
          >
            <b-form-input
              id="input-compleanos_comprador"
              required
              type="date"
              placeholder="Compleanos comprador:  "
              v-model="nuevoUsuario.cumpleanos_comprador"
            ></b-form-input>
          </b-form-group>
        </template>
        <div style="display: flex; justify-content: space-between">
          <b-button
            @click="cerrarModal"
            style="background-color: #a5594e; border-color: #a5594e"
          >
            DESCARTAR
          </b-button>
          <b-button @click="guardarUsuario" style="background-color: #355070">
            GUARDAR
          </b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import api from "../../../../utils/api";
export default {
  name: "AgregarUsuario",
  props: [],
  components: {},
  data() {
    return {
      nuevoUsuario: {
        esCliente: false,
      },
    };
  },
  mounted() {},
  destroyed() {},
  methods: {
    guardarUsuario() {
      api("POST", "usuarios/crear-usuario", {
        ...this.nuevoUsuario,
        personal_id: sessionStorage.getItem("usuario_id"),
      })
        .then((result) => {
          if (result.errorCode) {
            this.$notify({
              group: "foo",
              title: "Error al crear el personal",
              text: "Error del servidor",
              type: "error",
            });
          } else {
            this.nuevoUsuario = {};
            this.$refs["ModalAgregarUsuario"].hide();
            this.$emit("listar-usuarios");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cerrarModal() {
      this.nuevoUsuario = {};
      this.$refs["ModalAgregarUsuario"].hide();
    },
  },
  computed: {},
};
</script>
<style lang="scss">
</style>