<template>
  <div id="VistaPrevia">
    <template v-if="cargando">
      <BaseCargando />
    </template>
    <template v-if="!cargando">
      <b-tabs>
        <template v-for="(productos, idx) in listaProductos">
          <b-tab :key="idx" :title="productos.productosLista[0].nombre_corto">
            <BaseTabla
              ref="baseTabla"
              :campos="campos"
              :elementos="productos.productosLista"
              :cargando="cargando"
              modo="multi"
              @row-selected="onRowSelected"
            />
          </b-tab>
        </template>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import BaseCargando from "./base/_BaseCargando.vue";
import BaseTabla from "./base/_BaseTabla.vue";
import api from "../utils/api";

export default {
  name: "VistaPrevia",
  props: {
    orden_id: {
      type: String,
    },
  },
  components: { BaseCargando, BaseTabla },
  data() {
    return {
      listaProductos: [],
      campos: [
        {
          key: "codigo",
          label: "Codigo",
          sortable: true,
        },
        {
          key: "descripcion",
          label: "Descripcion",
          sortable: true,
        },
        {
          key: "precio",
          label: "Precio",
          sortable: true,
        },
        {
          key: "precio",
          label: "Precio Con IVA",
          sortable: true,
        },
        {
          key: "cantidad",
          label: "cantidad",
          sortable: true,
        },
      ],
      cargando: true,
    };
  },
  beforeMount() {
    if (this.orden_id) {
      api("GET", `orden/vista-previa/${this.orden_id}`).then((result) => {
        this.listaProductos = result;
        this.cargando = false;
      });
    }
    this.cargando = false;
  },
  destroyed() {},
  methods: {
    onRowSelected() {},
  },
  computed: {},
};
</script>
<style lang="scss">
</style>