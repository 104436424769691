<template>
  <b-overlay :show="loading || cargando" rounded="sm">
    <div class="Orden">
      <div class="wrap">
        <b-table id="tabla-carrito" small bordered :fields="fields" :items="productos" :busy="cargando" show-empty
          sticky-header>
          <template #empty>
            <div class="container">
              <h4>Sin productos seleccionados</h4>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="spinner"></b-spinner>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(nombre)="data">
            <div class="cell-nombre">
              <span>{{ data.item.nombre }}</span>
            </div>
          </template>
          <template #cell(subtotal)="data">
            {{ calcularSubtotal(data.item).toFixed(2) | currency }}
          </template>
          <template #cell(cantidad)="data">
            <div @click="modificarCantidad(data.item, data.index, $event.target)">
              {{ data.item.cantidad }}
            </div>
          </template>
          <template #cell(precio)="data">
            {{ data.item.precio | currency }}
          </template>
          <template #cell(actions)="row">
            <b-button size="sm" variant=" light" @click="info(row.item, row.index, $event.target)" class="mr-1">
              <b-icon class="icon_card" variant="danger" icon="x"></b-icon>
            </b-button>
          </template>
        </b-table>
        <Totales :proveedoresTotales="proveedoresTotales" />
      </div>
      <Confirmacion @remover-producto="removerProducto" :producto="currentItem" />
    </div>
  </b-overlay>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from 'sweetalert2';
import _ from "lodash";
import Notification from "../../../utils/notification";
import Confirmacion from "../../../components/Confirmacion.vue";
import Totales from "./Totales.vue";
import api from "../../../utils/api.js";

export default {
  name: "Orden",
  props: ["productos", "cliente"],
  components: {
    Confirmacion,
    Totales,
  },
  data() {
    return {
      currentItem: "",
      fields: [
        {
          key: "codigo",
          sortable: true,
          label: "Código",
        },
        {
          key: "subcodigo",
          sortable: true,
          label: "Subcódigo",
        },
        {
          key: "nombre",
          sortable: true,
          label: "Descripcion del Producto",
        },
        {
          key: "proveedor",
          sortable: true,
          label: "Proveedor",
        },
        {
          key: "cantidad",
          sortable: true,
          label: "Cantidad",
        },
        {
          key: "precio",
          sortable: true,
          label: "Precio",
        },
        {
          key: "subtotal",
          sortable: true,
          label: "Subtotal",
        },
        { key: "actions", label: "Eliminar" },
      ],
      totals: [
        { items_text: "Venta Grabada", total: 0 },
        { items_text: "Venta Exenta", total: 0 },
        { items_text: "Sub Total", total: 0 },
        { items_text: "I.V.A 16%", total: 0 },
        { items_text: "I.E.P.S", total: 0 },
        { items_text: "Total", total: 0 },
      ],
      totalesProveedoresFields: [
        { key: "nombre", sortable: true, label: "proveedor" },
        { key: "total", sortable: true, label: "total" },
      ],
      proveedoresIncluidos: [],
      proveedoresTotales: [],
      loading: false,
    };
  },
  computed: {
    ...mapState("productosCliente", ["cargando", "orden"]),
  },
  watch: {
    productos: function () {
      this.proveedoresTotales = [];
      let keys = Object.keys(_.keyBy(this.productos, "proveedor_id"));
      keys.forEach((key) => {
        let proveedorTotal = {
          proveedor_id: key,
          nombre: "",
          total: 0,
        };
        this.productos.forEach((producto) => {
          if (key === producto.proveedor_id) {
            proveedorTotal.nombre = producto.proveedor;
            proveedorTotal.total =
              proveedorTotal.total + producto.cantidad * producto.precio;
          }
        });
        this.proveedoresTotales.push(proveedorTotal);
      });
    },
  },
  filters: {
    currency(value) {
      try {
        return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch {
        return "-";
      }
    },
    currencyEspecial(value) {
      try {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch {
        return "-";
      }
    },
  },
  methods: {
    ...mapActions("productosCliente", {
      activarModalConfirmacion: "activarModalConfirmacion",
    }),
    info(item) {
      if (item) {
        this.currentItem = item;
      }
      this.activarModalConfirmacion();
    },
    calcularSubtotal({ precio, cantidad }) {
      return precio * cantidad;
    },
    calcularTotales({ items_text }) {
      let subtotales, ivas, tIvas, tSubtotales;
      switch (items_text) {
        case "Sub Total":
          subtotales = 0;
          this.productos.forEach(({ precio, cantidad }) => {
            subtotales += precio * cantidad;
          });
          return subtotales.toFixed(2);
        case "I.V.A 16%":
          ivas = 0;
          this.productos.forEach(({ precio, cantidad, iva }) => {
            ivas += precio * cantidad * iva;
          });
          return ivas.toFixed(2);
        case "Total":
          tIvas = 0;
          tSubtotales = 0;
          this.productos.forEach(({ precio, iva, cantidad }) => {
            tIvas += precio * cantidad * iva;
            tSubtotales += precio * cantidad;
          });
          return (tIvas + tSubtotales).toFixed(2);
        case "empty":
          return;
        default:
          return (0).toFixed(2);
      }
    },
    async modificarCantidad(element) {
      element.orden_id = this.orden.orden_id;
      let value = prompt(`Modificar Cantidad`);
      if (value !== null && value !== "" && !isNaN(value)) {
        element.cantidad = parseInt(value);
        element.cantidadAbsoluta = true;
        await api("POST", "orden/agregar-producto", element);
        await this.$emit("listar-productos");
      }
    },
    removerProducto(producto) {
      this.loading = true;
      api(
        "DELETE",
        `orden/remover-producto/${this.orden.orden_id}/${producto.producto_id}`
      ).then(() => this.$emit('listar-productos'))
        .catch(() => this.makeToast('Remover producto', 'Hubo un error al eliminar un producto de la orden.', 'danger', 3000))
        .finally(() => this.loading = false);
    },
    borrarOrden() {
      api("DELETE", `orden/remover-productos/${this.orden.orden_id}`);
      this.$emit("listar-productos");
    },
    guardarCotizacion() {
      api("POST", `cotizaciones/crear-cotizacion`, {
        usuario_id: this.cliente,
        orden_id: this.orden.orden_id,
        vendedor: localStorage.getItem("usuario_id"),
      }).then((response) => {
        this.$emit("listar-productos");
        if (response.errorCode) {
          return this.$notify({
            group: "foo",
            title: "Error crear la cotizacion",
            type: "error",
          });
        }
        return this.$notify({
          group: "foo",
          title: "Cotizacion creada",
          type: "success",
        });
      });
      this.$emit("listar-productos");
    },
    async crearPedido() {
      const comentarios = [];
      let proveedoresNombre = [
        ...new Set(
          this.productos.map(({ proveedor }) => {
            return proveedor;
          })
        ),
      ];
      let proveedoresId = [
        ...new Set(
          this.productos.map(({ proveedor_id }) => {
            return proveedor_id;
          })
        ),
      ];
      proveedoresId.forEach((proveedor_id, idx) => {
        let value = prompt(
          `Comentarios para pedido: ${proveedoresNombre[idx]}`
        );
        if (value !== null && value !== "") {
          comentarios.push({
            proveedor: proveedor_id,
            comentarios: value,
          });
          return;
        }
        comentarios.push({
          proveedor: proveedor_id,
          comentarios: null,
        });
      });

      const result = await Swal.fire({
        showDenyButton: true,
        showConfirmButton: true,
        showCancelButton: false,
        denyButtonText: 'Sin consignación',
        confirmButtonText: 'Usar consignación',
        inputLabel: 'Domicilio de consignación',
        input: 'textarea',
        confirmButtonColor: '#355070',
      });

      let domicilioConsignacion = '';
      if (result.isConfirmed) {
        domicilioConsignacion = result.value;
      }

      if (domicilioConsignacion.length > 400) {
        Notification('warn', 'Domicilio consignación', 'El domicilio no debe ser mayor a 400 caracteres', 3000);
        return;
      }

      api("POST", "pedidos/crear", {
        orden_id: this.orden.orden_id,
        comentarios,
        domicilioConsignacion
      }).then((response) => {
        this.$emit("listar-productos");
        if (response.errorCode) {
          return this.$notify({
            group: "foo",
            title: "Error crear el pedido",
            type: "error",
          });
        }
        return this.$notify({
          group: "foo",
          title: "Pedido creado",
          type: "success",
        });
      });
      this.$emit("listar-productos");
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || 'Toast body content';
      this.$bvToast.toast(body, {
        title: title || 'default',
        toaster: 'b-toaster-top-center',
        variant: variant || 'default',
        autoHideDelay: duration,
        solid: true
      });
    },
  },
};
</script>