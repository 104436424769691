<template>
  <div>
    <b-modal
      id="modal-modificar"
      ref="modal-modificar"
      title="Modificar Marca"
      hide-footer
    >
      <div>
        <h3>Desea reactivar el producto: {{ productoSeleccionado.codigo }}</h3>
        <br />
        <h4>Descripcion: {{ productoSeleccionado.descripcion }}</h4>
      </div>
      <b-button
        block
        style="
          margin-top: 15px;
          background-color: #355070;
          border-color: #355070;
        "
        @click="activarProducto"
      >
        Reactivar</b-button
      >
    </b-modal>
    <b-row>
      <b-col>
        <div style="display: flex; flex-direction: row; align-items: center">
          <b-icon
            id="back_icon"
            icon="chevron-left"
            style="margin-bottom: 5px"
            @click="regresar"
          ></b-icon>
          <h2 style="margin-left: 10px">Productos Suspendidos</h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-right: 10px;
          "
        >
          <b-input-group style="width: 450px; padding-left: 10px">
            <template #prepend>
              <b-input-group-text>
                <b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container class="table_container" fluid>
          <b-table
            :items="listaProductos"
            :fields="camposProductos"
            striped
            bordered
            hover
            head-variant="light"
            :per-page="perPage"
            :busy="isBusy"
            :filter="filter"
            :filter-included-fields="filterOn"
            :current-page="currentPage"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
          ></b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            :current-page="currentPage"
          ></b-pagination>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "../../../../utils/api";
export default {
  data() {
    return {
      listaProductos: [],
      camposProductos: [
        {
          key: "producto_id",
          label: "ID",
        },
        {
          key: "codigo",
          label: "Codigo",
          sortable: true,
        },
        {
          key: "descripcion",
          label: "Nombre",
          sortable: true,
        },
      ],
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      currentId: "",
      marcaNueva: {
        nombre: "",
        oferta: 0,
      },
      productoSeleccionado: {},
    };
  },
  methods: {
    regresar() {
      this.$router.push("/app/catalogos");
    },
    listarMarcas() {
      api("GET", "productos-suspendidos/consultar").then((result) => {
        if (result.errorCode) {
          this.$notify({
            group: "foo",
            title: "Error al obtener los productos suspendidos",
            text: "Error del servidor",
            type: "error",
          });
        } else {
          this.listaProductos = result;
        }
      });
    },
    activarProducto() {
      if (this.productoSeleccionado.producto_id) {
        api(
          "PUT",
          `productos-suspendidos/reactivar/${
            this.productoSeleccionado.codigo
          }/${sessionStorage.getItem("usuario_id")}/${
            this.productoSeleccionado.producto_id
          }`
        )
          .then((result) => {
            if (result.errorCode) {
              this.$notify({
                group: "foo",
                title: "Error",
                text: "Error del servidor",
                type: "error",
              });
            } else {
              this.$refs["modal-modificar"].hide();
              this.listarMarcas();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onRowSelected(item) {
      if (item.length) {
        this.productoSeleccionado = item[0];
        this.$refs["modal-modificar"].show();
      }
    },
  },
  mounted() {
    this.listarMarcas();
  },
  computed: {
    rows() {
      return this.listaProductos.length;
    },
  },
};
</script>

<style>
#back_icon:hover {
  cursor: pointer;
}
#sidebar-right {
  width: 450px;
}

.fill {
  color: #aaa;
}
.cabecera_roles {
  display: none;
}
.table_container {
  margin-top: 20px;
}
.table_container tr:hover {
  cursor: pointer;
}
#tabla_roles {
  margin-top: 10px;
}
.detalles_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}
.detalles_body {
  margin: 10px;
}
.dato_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}
.txt_dato {
  font-weight: bold;
}
.txt_dato_resp {
  color: #424242;
}
.roles_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.modal_form_personal {
}
</style>
