<template>
  <div id="template">
    {{ defaultProp }}
  </div>
</template>

<script>
export default {
  name: "template",
  props: {
    defaultProp: {
      type: String,
      default: "Default Prop",
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  destroyed() {},
  methods: {},
  computed: {},
  filters: {
    currency(value) {
      try {
        return value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch {
        return "-";
      }
    },
  },
};
</script>
<style lang="scss">
</style>