import api from "../utils/api";

const productosCliente = {
  namespaced: true,
  state: {
    cargando: false,
    error: false,
    listaProductos: [],
    listaCarrito: [],
    orden: {},
    mostrarModalConfirmacion: false,
    clienteSeleccionado: "",
    proveedorSeleccionado: [],
    queryCodigo: "",
    queryInput: "",
  },
  getters: {
    cargando: (state) => state.cargando,
    error: (state) => state.error,
    listaProductos: (state) => state.listaProductos,
    listaCarrito: (state) => state.listaCarrito,
    orden: (state) => state.orden,
    mostrarModalConfirmacion: (state) => state.mostrarModalConfirmacion,
    clienteSeleccionado: (state) => state.clienteSeleccionado,
    proveedorSeleccionado: (state) => state.proveedorSeleccionado,
    queryCodigo: (state) => state.queryCodigo,
    queryInput: (state) => state.queryInput,
  },
  mutations: {
    SET_CARGANDO(state, newValue) {
      state.cargando = newValue;
    },
    SET_ERROR(state, newValue) {
      state.error = newValue;
    },
    SET_LISTAR_PRODUCTOS(state, newValue) {
      state.listaProductos = newValue;
    },
    SET_LISTAR_CARRITO(state, newValue) {
      state.listaCarrito = newValue;
    },
    SET_ORDEN(state, newValue) {
      state.orden = newValue;
    },
    SET_MOSTRAR_MODAL_CONFIRMACION(state, newValue) {
      state.mostrarModalConfirmacion = newValue;
    },
    SET_CLIENTE(state, newValue) {
      state.clienteSeleccionado = newValue;
    },
    SET_PROVEEDOR(state, newValue) {
      state.proveedorSeleccionado = newValue;
    },
    SET_QUERY_CODIGO(state, newValue) {
      state.queryCodigo = newValue;
    },
    SET_QUERY_INPUT(state, newValue) {
      state.queryInput = newValue;
    },
  },
  actions: {
    async inicializarOrden({ commit }, payload) {
      commit("SET_CARGANDO", true);

      try {
        const result = await api("POST", "orden/crear-orden", payload)
        commit("SET_CLIENTE", payload.cliente_id);
        commit("SET_ORDEN", result.orden);
      } catch (error) {
        commit("SET_ERROR", true);
      } finally {
        commit("SET_CARGANDO", false);
      }
    },
    listarCarrito({ commit }, orden_id) {
      commit("SET_CARGANDO", true);

      api("GET", `orden/consultar-productos/${orden_id}`)
        .then((result) => {
          commit("SET_LISTAR_CARRITO", result);
        })
        .catch(() => {
          commit("SET_ERROR", true);
        })
        .finally(() => {
          commit("SET_CARGANDO", false);
        });
    },
    setProveedor({ commit }, payload) {
      commit("SET_PROVEEDOR", payload);
    },
    setQuery({ commit }, payload) {
      commit("SET_QUERY_CODIGO", payload.queryCodigo);
      commit("SET_QUERY_INPUT", payload.queryCodigoInput);
    },
    activarModalConfirmacion({ commit }) {
      commit("SET_CARGANDO", true);
      commit(
        "SET_MOSTRAR_MODAL_CONFIRMACION",
        !this.state.productosCliente.mostrarModalConfirmacion
      );
      commit("SET_CARGANDO", false);
    },
    limpiar({commit}) {
      commit('SET_ORDEN', {})
      commit('SET_LISTAR_CARRITO', []);
    },
  },
};

export default productosCliente;
