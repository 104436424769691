<template>
  <div>
    <b-modal v-model="mostrarModalConfirmacion" hide-footer hide-header>
      <div id="ModalConfirmacion">
        <h3>¿Desea remover el articulo?</h3>
        <div>
          <b-button @click="cancelar">CANCELAR</b-button>
          <b-button @click="confirmar">CONFIRMAR</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["producto"],
  data() {
    return {};
  },
  methods: {
    ...mapActions("productosCliente", {
      activarModalConfirmacion: "activarModalConfirmacion",
    }),
    cancelar() {
      this.activarModalConfirmacion();
    },
    confirmar() {
      this.$emit('remover-producto', this.producto);
      this.activarModalConfirmacion();
    },
  },
  computed: {
    ...mapState("productosCliente", ["mostrarModalConfirmacion"]),
  },
};
</script>
<style lang="scss">
#ModalConfirmacion {
  text-align: center;
  > h3 {
    margin-bottom: 40px;
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
</style>